import { useCallback, useState, useEffect, useRef, forwardRef } from 'react';
import { SiPowerapps } from 'react-icons/si';

import { AiOutlinePlusCircle } from "react-icons/ai";
import { AiOutlineMinusCircle } from "react-icons/ai";

export function PrimaryButton(props: { style?: React.CSSProperties, onClick: () => void, children: any, disabled?: boolean }) {
  let onClick = useCallback(props.onClick, [props.onClick]);
  let [disabled, setDisabled] = useState(props.disabled ? true : false);
  useEffect(() => {
    setDisabled(props.disabled ? true : false);
  }, [props.disabled]);

  return <button
    style={{
      padding: '8px',
      cursor: 'pointer',
      border: 'none',
      // width: '120px',
      backgroundColor: disabled ? 'darkgrey' : '#5664F5',
      color: disabled ? 'grey' : 'white',
      // borderRadius: '5px',
      fontWeight: 'bold',
      ...(props.style ? props.style : {})
    }}
    onClick={onClick}
  >
    {props.children}
  </button>
}

export function SecondaryButton(props: {
  style?: React.CSSProperties,
  onClick: (event?: any) => void,
  children: any,
  disabled?: boolean,
  title?: string,
}) {
  let onClick = useCallback(props.onClick, [props.onClick]);
  let [disabled, setDisabled] = useState(props.disabled ? true : false);
  useEffect(() => {
    setDisabled(props.disabled ? true : false);
  }, [props.disabled]);
  return <button
    title={props.title}
    style={{
      padding: '8px',
      cursor: 'pointer',
      backgroundColor: disabled ? 'lightgrey' : 'white',
      border: 'solid 1px #5664F5',
      // borderRadius: '5px',

      color: disabled ? 'grey' : '#5664F5',
      ...(props.style ? props.style : {})
    }}
    onClick={onClick}
  >
    {props.children}
  </button>
}

export function TextButton(props: { style: React.CSSProperties, onClick: () => void, children: any, disabled?: boolean }) {
  let onClick = useCallback(props.onClick, [props.onClick]);
  let [disabled, setDisabled] = useState(props.disabled ? true : false);
  useEffect(() => {
    setDisabled(props.disabled ? true : false);
  }, [props.disabled]);

  return <button
    style={{
      padding: '0px',
      cursor: 'pointer',
      backgroundColor: 'rgba(0,0,0,0)',
      border: 'none',
      color: disabled ? 'grey' : '#5664F5',
      ...(props.style ? props.style : {})
    }}
    onClick={onClick}
  >
    {props.children}
  </button>
}


export const ExpandingTextArea = forwardRef((props: {
  style?: React.CSSProperties,
  onChange: (e?: React.ChangeEvent<HTMLTextAreaElement>) => void,
  value?: string,
  placeholder?: string,
  defaultValue?: string
},
  ref?: React.MutableRefObject<HTMLTextAreaElement>) => {
  // let [value, setValue] = useState(props.value);
  let localRef = useRef<HTMLTextAreaElement>(null);
  // let childRef = ref ? ref : localRef;
  let onChange = useCallback((e?: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e) {
      // setValue(e.target.value);
      props.onChange(e);
    }
    if (localRef && localRef.current) {
      if (localRef.current.value.length === 0 && props.style && props.style.height) {
        localRef.current.style.height = props.style.height + "px";
      } else {
        localRef.current.style.height = 'auto';
        localRef.current.style.height = (localRef.current.scrollHeight + 10) + 'px';
      }
    }
  }, [props.onChange, localRef.current]);
  // useEffect(() => {
  //   if (props.value !== undefined) {
  //     setValue(props.value);
  //   } 
  // }, [props.value]);
  useEffect(() => {
    onChange();
  }, [localRef.current])
  return <textarea
    ref={localRef}
    style={{
      width: '100%',
      height: 'auto',
      resize: 'none',
      ...(props.style ? props.style : {})
    }}
    onChange={onChange}
    value={props.value}
    defaultValue={props.defaultValue}
    placeholder={props.placeholder}
  />
})

export function TextInput(props: {
  style?: React.CSSProperties,
  onChange: (e?: React.ChangeEvent<HTMLInputElement>) => void,
  value?: string,
  placeholder?: string,
  defaultValue?: string,
  disabled?: boolean
}) {
  return <input
    type='text'
    style={{ ...props.style }}
    onChange={props.onChange}
    value={props.value}
    defaultValue={props.defaultValue}
    placeholder={props.placeholder}
    disabled={props.disabled}
  />
}

export function MultiInput(props:
  {
    label?: string,
    placeholder?: string,
    values: string[],
    onChange: (values: string[]) => void,
    style?: React.CSSProperties,
    labelStyle?: React.CSSProperties,
    inputContainerStyle?: React.CSSProperties,
    inputStyle?: React.CSSProperties,
    removeButtonStyle?: React.CSSProperties,
    addButtonStyle?: React.CSSProperties,
    title?: string,
  }) {
  let [values, setValues] = useState(props.values);
  return <div style={{ display: 'flex', flexDirection: 'column', width: '100%', ...props.style }}>
    {props.label && <label style={{ fontWeight: 'bold', ...props.labelStyle }}>{props.label}</label>}
    {!values ? "" : values.map((value, index) => {
      return <div
        style={{ width: '100%', display: 'flex', flexDirection: 'row', margin: "3px 0", ...props.inputContainerStyle }}
        title={props.title}
      >
        <TextInput
          placeholder={props.placeholder}
          key={index}
          style={{ display: 'flex', flexGrow: 1, ...props.inputStyle }}
          // ref={questionsRef[index]}
          value={value}
          onChange={(e) => {
            const newValues = [...values];
            newValues[index] = e.target.value;
            setValues(newValues);
            props.onChange(newValues)
          }}
        />
        <TextButton
          style={{
            marginLeft: '5px',
            padding: '2px',
            // cursor: 'pointer',
            // padding: '5px 15px',
            // border: 'solid 1px #5664F5', borderRadius: '5px', color: '#5664F5', backgroundColor: 'white'
            ...props.removeButtonStyle
          }}
          onClick={() => {
            const newValues = [...values];
            newValues.splice(index, 1);
            setValues(newValues);
            props.onChange(newValues);
          }}><AiOutlineMinusCircle style={{ marginBottom: "-2px", fontSize: '15px' }} /></TextButton>
      </div>
    })}
    <div>
      <TextButton
        style={{
          padding: '2px',
          // cursor: 'pointer',
          // padding: '5px 15px',
          // border: 'solid 1px #5664F5',
          // borderRadius: '5px',
          // color: '#5664F5',
          // backgroundColor: 'white'
          ...props.addButtonStyle
        }}
        onClick={() => {
          setValues([...values, ""]);
        }}><AiOutlinePlusCircle style={{ marginBottom: "-2px", fontSize: '15px' }} /> add</TextButton>
    </div>
  </div>;
}

export function LabelInput(props: {
  label?: string,
  placeholder?: string,
  value?: string,
  onChange: (e?: React.ChangeEvent<HTMLInputElement>) => void,
  style?: React.CSSProperties,
  inputStyle?: React.CSSProperties,
  labelStyle?: React.CSSProperties
}) {
  let [value, setValue] = useState(props.value);
  return <div
    style={{ display: 'flex', flexDirection: 'column', width: '100%', ...props.style }}>
    {props.label && <label style={{ fontWeight: 'bold', fontSize: '0.95rem', marginBottom: '5px', ...props.labelStyle }}>{props.label}</label>}
    <TextInput
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        props.onChange(e);
      }}
      placeholder={props.placeholder}
      style={{ ...props.inputStyle }} />
  </div>
}

export function LabelTextArea(props: {
  label?: string,
  placeholder?: string,
  value?: string,
  onChange: (e?: React.ChangeEvent<HTMLTextAreaElement>) => void,
  style?: React.CSSProperties,
  inputStyle?: React.CSSProperties,
  labelStyle?: React.CSSProperties
}) {
  let [value, setValue] = useState(props.value);
  return <div
    style={{ display: 'flex', flexDirection: 'column', width: '100%', ...props.style }}>
    {props.label && <label style={{ fontWeight: 'bold', fontSize: '0.95rem', marginBottom: '5px', ...props.labelStyle }}>{props.label}</label>}
    <ExpandingTextArea
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        props.onChange(e);
      }}
      placeholder={props.placeholder}
      style={{ width: '100%', boxSizing: 'border-box', ...props.inputStyle }} />
  </div>
}


export function LabelSelect(props: {
  label?: string,
  defaultValue?: string,
  options: { value: string, label: string }[],
  onChange: (e?: React.ChangeEvent<HTMLSelectElement>) => void,
  style?: React.CSSProperties,
  labelStyle?: React.CSSProperties
  selectStyle?: React.CSSProperties
}) {
  let [value, setValue] = useState(props.defaultValue);
  return <div
    style={{ display: 'flex', flexDirection: 'column', width: '100%', ...props.style }}>
    {props.label && <label style={{ fontWeight: 'bold', fontSize: '0.95rem', marginBottom: '5px', ...props.labelStyle }}>{props.label}</label>}
    <select
      style={props.selectStyle}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        props.onChange(e);
      }}
    >
      {props.options.map((option, index) => {
        return <option key={index} value={option.value}>{option.label}</option>;
      })}
    </select>

  </div>
}