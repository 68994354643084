import React from 'react';
import { useState, useEffect, useRef } from "react";
import { Campaign } from "../lib/common-types";
import { useAppContext } from "../lib/context-prod";
import { QuerySnapshot, DocumentSnapshot, QueryDocumentSnapshot } from '@firebase/firestore';
import { Loading } from "./common/Loading";
import { TimeAgo } from "../lib/datetime";

import { FaLessThan } from 'react-icons/fa';
import { FaGreaterThan } from 'react-icons/fa';
import { AiOutlineRobot } from 'react-icons/ai';
import { MdSupportAgent } from 'react-icons/md';
import { BsCardList } from 'react-icons/bs';
// import { MdLightbulb } from 'react-icons/md';
import { MdLightMode } from 'react-icons/md';
import { BsToggleOn } from 'react-icons/bs';
import { BsToggleOff } from 'react-icons/bs';
import { IoMdLink } from 'react-icons/io';
import { HiOutlineCode } from 'react-icons/hi';
import { IoMdDownload } from 'react-icons/io';
import { FiSettings } from 'react-icons/fi';
import { AiOutlineClose } from 'react-icons/ai';

function CampaignHeader() {
  let style = {
    // borderWidth: 1,
    // borderColor: 'grey',
    // borderStyle: 'solid',
    fontweight: 'bold',
    // width: '25%',
    padding: '8px',
    textAlign: 'left' as 'left'
  }

  // let style = {};
  return <thead style={{ position: 'sticky', /*display: 'flex', flexDirection: 'row', width: '100%' */ }}>
    <tr style={{ backgroundColor: '#ffffff' }}>
      <th style={{ ...style }}>Status</th>
      <th style={{ ...style, flex: 2 }}>Name</th>
      {/* <th style={{ ...style }}>Launched</th> */}
      <th style={{ ...style }}>Created</th>
      <th style={{ ...style, flex: 0.5 }}>Last Message</th>
      <th style={{ ...style }}>Default Mode</th>
      <th style={{ ...style, flex: 0.3 }}>Test Link</th>
      <th style={{ ...style, flex: 0.3 }}>Live Link</th>
      <th style={{ ...style, flex: 0.3 }}>Code</th>
      {/* <th style={{ ...style, flex: 0.3 }}>Metrics</th> */}
      <th style={{ ...style }}>{/* placeholder for 'more...' */}</th>
    </tr>
  </thead>
}

const settingsOpenColor = '#f9f9ff';
function CampaignRow(props:
  {
    rowNum: number,
    organizationId: string,
    campaignId: string,
    campaign: Campaign,
    save: (campaign: Campaign) => Promise<void>,
    delete: undefined | (() => Promise<void>),
  }) {
  let [hideDetails, setHideDetails] = useState<boolean>(true);
  let [campaign, setCampaign] = useState<Campaign>(props.campaign);
  let [lastMessageTimeAgo, setLastMessageTimeAgo] = useState<string>(TimeAgo(campaign.lastMessageTime));
  let [staticKnowledge, setStaticKnowledge] = useState<string>('');
  let [systemMessage, setSystemMessage] = useState<string>('');
  let [changeDetected, setChangeDetected] = useState<boolean>(false);
  let iframeRef = useRef<HTMLIFrameElement>(null);
  useEffect(() => {
    if (campaign.bots[0].promptSettings.modules['static-knowledge']) {
      setStaticKnowledge(campaign.bots[0].promptSettings.modules['static-knowledge'].staticKnowledge);
    }
    if (campaign.bots[0].promptSettings.modules['system-message']) {
      setSystemMessage(campaign.bots[0].promptSettings.modules['system-message'].instructions);
    }
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setLastMessageTimeAgo(TimeAgo(campaign.lastMessageTime));
    }, 10000);
  }, [lastMessageTimeAgo]);
  let style = {
    // display: 'flex',
    // flexGrow: 1,
    // borderWidth: 1,
    // borderColor: 'grey',
    // borderStyle: 'solid',
    padding: '8px',
  }
  // let style = {};
  let detailsBoxStyle = {
    padding: '5px',
    maxWidth: '100%',
    width: '500px'
  }
  let settingsTitleStyle = {
    fontWeight: 'bold',
    fontSize: '1.2em'
  }
  let settingsSubtitleStyle = {
    color: 'darkgrey',
    fontSize: '0.8em',
  }
  return <>
    <tr style={{ backgroundColor: props.rowNum % 2 === 0 ? '#ddddff' : 'white' }}>
      <td
        title={"active: new users can join\nno-new-users: new users can't join\ninactive: no new users can join"}
        style={{ ...style, cursor: 'pointer' }}
        onClick={() => {
          let newState: 'active' | 'no-new-users' | 'inactive';
          if (campaign.state === 'active') {
            newState = 'inactive';
          } else {
            newState = 'active';
          }
          setCampaign({ ...campaign, state: newState });
          props.save({ ...campaign, state: newState });
        }}
      >
        {campaign.state === 'active' ? <BsToggleOn style={{ color: '#00dd00' }} /> : campaign.state === 'no-new-users' ? <MdLightMode style={{ color: 'orange' }} /> : <BsToggleOff style={{ color: 'red' }} />}
        <span style={{ margin: '0.5rem' }}>{campaign.state}</span>
      </td>
      <td style={{ ...style }}>{campaign.name}</td>
      {/* <td style={{ ...style }}>{campaign.launched ? new Date(campaign.launched).toDateString().substring(0, 10) : 'never'}</td> */}
      <td style={{ ...style }}>{campaign.created ? new Date(campaign.created).toDateString().substring(0, 10) : 'error'}</td>
      <td style={{ ...style }}>{lastMessageTimeAgo ? lastMessageTimeAgo : 'never'}</td>
      <td
        title={"autopilot: bot will respond to all messages\ncopilot: bot will give suggested responses\nsemantic-search: bot will respond with search results"}
        style={{ ...style, cursor: 'pointer' }}
        onClick={() => {
          let newMode: 'autopilot' | 'copilot' | 'semantic-search';
          if (campaign.defaultMode === 'autopilot') {
            newMode = 'copilot';
          } else {
            newMode = 'autopilot';
          }
          // } else if (campaign.defaultMode === 'copilot') {
          //   newMode = 'semantic-search';
          setCampaign({ ...campaign, defaultMode: newMode });
          props.save({ ...campaign, defaultMode: newMode });
        }}
      >{campaign.defaultMode === 'autopilot'
        ? <AiOutlineRobot />
        : campaign.defaultMode === 'copilot'
          ? <MdSupportAgent />
          : <BsCardList />}<span style={{ padding: '0.5rem' }}>{campaign.defaultMode}</span></td>
      <td style={{ ...style, textAlign: 'center', cursor: 'pointer' }}
        onClick={
          () => {
            window.open(
              window.location.origin + `/test.html?organizationId=${props.organizationId}&campaignId=${props.campaignId}&newChat=true`,
              '_blank').focus();
          }
        }>
        <IoMdLink /> test
        {/* <input hidden={true} value={`https://ourbot.app/test.html?organizationId=${props.organizationId}&campaignId=${props.campaignId}`} /> */}
      </td>
      <td style={{ ...style, textAlign: 'center', cursor: 'pointer' }} onClick={
        () => {
          window.open(
            window.location.origin + `/chat.html?organizationId=${props.organizationId}&campaignId=${props.campaignId}`,
            '_blank').focus();
        }
      }>
        <IoMdLink /> live
        {/* <input hidden={true} value={`https://ourbot.app/test.html?organizationId=${props.organizationId}&campaignId=${props.campaignId}`} /> */}
      </td>
      <td style={{ ...style, textAlign: 'center', cursor: 'pointer' }} onClick={() => alert(
        "Embed the following in the <head>...</head> portion of your html.\n\n" +
        "<script src='https://ourbot.app/lib/webchat.js'></script>\n\n" +
        "Embed the following at the end of the <body>...</body> portion of your html.\n\n" +
        "<script>\n" +
        `ourbot.openChat({organizationId: '${props.organizationId}', campaignId: '${props.campaignId}'})` + "\n" +
        "</script>")}
      >{/* code */}<HiOutlineCode /></td>
      {/* <td style={{ ...style, textAlign: 'center' }}>metrics<IoMdDownload /></td> */}
      <td
        style={{ ...style, cursor: 'pointer', backgroundColor: hideDetails ? undefined : settingsOpenColor, textAlign: 'center' }}
        onClick={() => setHideDetails(!hideDetails)}
      >{hideDetails ? <FiSettings /> : <AiOutlineClose />}</td>
    </tr>
    <tr hidden={hideDetails} style={{ backgroundColor: settingsOpenColor }}>
      <td colSpan={10} style={{ ...style }}>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap', justifyContent: 'space-evenly' }}>
          <div style={{ width: '500px', maxWidth: '100%' }}>
            <div style={detailsBoxStyle}>
              <div>
                <div style={settingsTitleStyle}>Name</div><div style={settingsSubtitleStyle}>The internal name for the campaign, not seen by visitor.</div>
              </div>
              <input
                style={{ width: '100%' }}
                placeholder={"e.g. My Campaign"}
                value={campaign.name}
                onChange={(e) => {
                  setCampaign({ ...campaign, name: e.target.value })
                  setChangeDetected(true);
                }}
              />
            </div>
            <div style={detailsBoxStyle}>
              <div>
                <div style={settingsTitleStyle}>Header</div><div style={settingsSubtitleStyle}>what is shown on the top bar of the chat.</div>
              </div>
              <input
                style={{ width: '100%' }}
                placeholder={"e.g. Hi, I'm a bot."}
                value={campaign.bots[0].chatHeader}
                onChange={(e) => {
                  let campaign_: Campaign = { ...props.campaign };
                  campaign_.bots[0].chatHeader = e.target.value;
                  setCampaign(campaign_);
                  setChangeDetected(true);
                }}
              />
            </div>
            <div style={detailsBoxStyle}>
              <div>
                <div style={settingsTitleStyle}>Introductory message</div>
                <div style={settingsSubtitleStyle}>this is the first message the bot will say.</div>
              </div>
              <textarea
                style={{ width: '100%' }}
                rows={4}
                placeholder={"e.g. Hi, how can I help you?"}
                value={campaign.bots[0].introMessage}
                onChange={(e) => {
                  let campaign_ = { ...props.campaign };
                  campaign_.bots[0].introMessage = e.target.value;
                  setCampaign(campaign_);
                  setChangeDetected(true);
                }
                }
              />
            </div>
            <div style={detailsBoxStyle}>
              <div>
                <div style={settingsTitleStyle}>Maximum number of exchanges</div>
                <div style={settingsSubtitleStyle}>e.g. if this is set to 10, then the user can say 10 things and the bot will give 10 replies.</div>
              </div>
              <div>
                <button
                  onClick={() => {
                    if (campaign.bots[0].maxExchangesCount > 4) {
                      let campaign_ = { ...props.campaign };
                      campaign_.bots[0].maxExchangesCount = campaign_.bots[0].maxExchangesCount - 1;
                      setCampaign(campaign_);
                      setChangeDetected(true);
                    }
                  }}
                >
                  <FaLessThan />
                </button>
                {campaign.bots[0].maxExchangesCount}
                <button
                  onClick={() => {
                    if (campaign.bots[0].maxExchangesCount < 20) {
                      let campaign_ = { ...props.campaign };
                      campaign_.bots[0].maxExchangesCount = campaign_.bots[0].maxExchangesCount + 1;
                      setCampaign(campaign_);
                      setChangeDetected(true);
                    }
                  }}
                >
                  <FaGreaterThan />
                </button>
              </div>
            </div>
            <div style={detailsBoxStyle}>
              <div>
                <div style={settingsTitleStyle}>Static Knowledge -- present in every exchange</div>
                <div style={settingsSubtitleStyle}>This is present in every exchange. Put a description of your company here.</div>
              </div>
              <textarea
                style={{ width: '100%' }}
                rows={4}
                placeholder={"e.g. Our Bot gives businesses the tools to launch AI Chat campaigns with no coding."}
                value={staticKnowledge}
                onChange={(e) => {
                  setStaticKnowledge(e.target.value);
                  setChangeDetected(true);
                }}
              />
            </div>
            <div style={detailsBoxStyle}>
              <div>
                <div style={settingsTitleStyle}>Bot Instructions</div>
                <div style={settingsSubtitleStyle}>Tell the bot how it should.</div>
              </div>
              <textarea
                style={{ width: '100%' }}
                rows={4}
                placeholder={"e.g. You are a helpful sales bot for the company Our Bot. When you don't have the answer in the previous text, you say, \"I don't know\""}
                value={systemMessage}
                onChange={(e) => {
                  setSystemMessage(e.target.value);
                  setChangeDetected(true);
                }}
              />
            </div>
            <div style={detailsBoxStyle}>
              <div>
                <div style={settingsTitleStyle}>Conversation Memory</div>
                <div style={settingsSubtitleStyle}>How many exchanges back should the bot keep in mind when responding?</div>
              </div>
              <div>
                <button
                  onClick={() => {
                    let campaign_ = { ...props.campaign };
                    if (campaign_.bots[0].promptSettings.modules['conversation-memory']) {
                      let count = campaign_.bots[0].promptSettings.modules['conversation-memory'].mostRecentExchangesCount;
                      campaign_.bots[0].promptSettings.modules['conversation-memory'].mostRecentExchangesCount = Math.max(0, count - 1);
                    } else {
                      campaign_.bots[0].promptSettings.modules['conversation-memory'] = { mostRecentExchangesCount: 0, extraExchangesCount: 0 };
                    }
                    setCampaign(campaign_);
                    setChangeDetected(true);
                  }}
                >
                  <FaLessThan />
                </button>
                {campaign.bots[0].promptSettings.modules['conversation-memory'] ? campaign.bots[0].promptSettings.modules['conversation-memory'].mostRecentExchangesCount : 0}
                <button
                  onClick={() => {
                    let campaign_ = { ...props.campaign };
                    if (campaign_.bots[0].promptSettings.modules['conversation-memory']) {
                      let count = campaign_.bots[0].promptSettings.modules['conversation-memory'] ? campaign_.bots[0].promptSettings.modules['conversation-memory'].mostRecentExchangesCount : 0;
                      campaign_.bots[0].promptSettings.modules['conversation-memory'].mostRecentExchangesCount = Math.min(4, count + 1);
                    } else {
                      campaign_.bots[0].promptSettings.modules['conversation-memory'] = { mostRecentExchangesCount: 1, extraExchangesCount: 0 };
                    }
                    setCampaign(campaign_);
                    setChangeDetected(true);
                  }
                  }
                >
                  <FaGreaterThan />
                </button>
              </div>
            </div>
            <div style={detailsBoxStyle}>
              <button
                disabled={!changeDetected}
                onClick={() => {
                  if (staticKnowledge) {
                    campaign.bots[0].promptSettings.modules['static-knowledge'] = { staticKnowledge: staticKnowledge };
                  }
                  if (systemMessage) {
                    campaign.bots[0].promptSettings.modules['system-message'] = { instructions: systemMessage };
                  }
                  props.save(campaign);
                }}
                style={{
                  width: '100%',
                  backgroundColor: JSON.stringify(props.campaign) == JSON.stringify(campaign) ? 'lightgrey' : 'lightgreen'
                }}
              >Save Changes</button>
            </div>
            {campaign.state === 'draft' && <div style={detailsBoxStyle}>
              <button
                onClick={() => {
                  if (window.confirm('Are you sure you want to delete this campaign?')) {
                    props.delete();
                  }
                }}
                style={{
                  width: '100%',
                  backgroundColor: 'lightcoral'
                }}
              >Delete Campaign</button>
            </div>}
          </div>

          <div style={{ width: '500px', maxWidth: '100%' }}>
            {/* <div style={{ width: '50px', height: '50px', position: 'absolute', top: 0, left: 0 }}
              onClick={() => {
                iframeRef.current?.contentWindow?.location.reload();
              }}
            >
              <GrRefresh />
            </div> */}
            <iframe
              ref={iframeRef}
              style={{ width: '400px', height: '550px' }}
              src={window.location.origin + `/test.html?organizationId=${props.organizationId}&campaignId=${props.campaignId}&newChat=true`} title={"Test the chatbot here."} />
          </div>
        </div>

        {/* <div style={detailsBoxStyle}>{JSON.stringify(campaign, null, 2)}</div> */}
      </td>
    </tr>
  </>
}

function CampaignsScreen() {
  const [campaigns, setCampaigns] = useState<QuerySnapshot<Campaign>>();
  const [loading, setLoading] = useState<boolean>(true);
  const context = useAppContext();
  const Load = async () => {
    if (!context.db.organizationId) return;
    setLoading(true);
    let campaigns: QuerySnapshot<Campaign> = await context.db.organizations.organizationId().campaigns.get();

    setCampaigns(campaigns);
    setLoading(false);
  };
  useEffect(() => {
    Load();
  }, [context.db.organizationId]);

  if (loading) {
    return <Loading />;
  }
  if (campaigns.docs.length === 0) {
    // ask them to wait and reload the page for the first campaign to be created
    return <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
      <h1>Campaigns</h1>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        Your first bot and campaign is being created. This should take less than 30 seconds.
        <br />
        <br />
        <button onClick={() => { Load(); }}>Reload Campaigns</button>
      </div>
    </div>;

  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column', height: '100%',
      overflowY: "scroll", width: '100%', padding: '40px', boxSizing: 'border-box'
    }}>
      <h1>Bots</h1>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%' }}>
        <div
          onClick={async () => {
            context.db.organizations.organizationId().campaigns.push({
              name: 'New Bot ' + (campaigns.docs.length + 1),
              created: new Date().getTime(),
              defaultMode: 'copilot',
              maxConcurrentConversations: 10,
              state: 'draft',
              bots: [{
                name: 'Default Bot',
                // knowledgeBaseId: string;
                inactiveMessage: "Sorry, the chat is currently unavailable. Please try again later.",

                // maximum number of exchanges in a conversation
                maxExchangesCount: 10,

                promptSettings: {
                  template: "",
                  modules: {
                    'conversation-memory': {
                      mostRecentExchangesCount: 4,
                      extraExchangesCount: 0
                    }
                  }
                },
                chatHeader: "Get answer now. Chat with our bot!",
                introMessage: "Hi, we at Our Bot put together this bot to help you get answers to your questions. How can I help you?",
                commonQuestions: ["What do you do?", "How does it work?", "How do I get started?"]
              }]
            }).then(() => {
              Load();
            });
          }}
          style={{
            width: '200px', backgroundColor: '#5555ff', cursor: 'pointer',
            padding: '10px', fontWeight: 'bold', color: 'white', textAlign: 'center'
          }}>
          New Bot
        </div>
      </div>
      {/* <div style={{ marginBottom: '20px', width: '100%' }}> */}
      <table style={{ width: '100%', border: "solid 2px lightgray", borderRadius: '8px', marginTop: '30px' }}>
        <CampaignHeader />
        {campaigns.docs.map((doc, index) => {
          let campaign: Campaign = doc.data();
          if (!campaign.bots || campaign.bots.length == 0) {
            // let result = 
            if (window.confirm("This campaign has no bots. Would you like to create one?")) {
              context.db.organizations.organizationId().campaigns.campaignId(doc.id).update({
                bots: [{
                  name: 'Default Bot',
                  // knowledgeBaseId: string;
                  inactiveMessage: "Sorry, the chat is currently unavailable. Please try again later.",

                  // maximum number of exchanges in a conversation
                  maxExchangesCount: 10,

                  promptSettings: {
                    template: "",
                    modules: {
                      'conversation-memory': {
                        mostRecentExchangesCount: 4,
                        extraExchangesCount: 0
                      }
                    }
                  },

                  // This appears on the public chat in the bar at the top.
                  chatHeader: "Get answer now. Chat with our bot!",
                  // The first thing that appears in the chat window.
                  introMessage: "Hi, we at Our Bot put together this bot to help you get answers to your questions. How can I help you?",
                  // The avatar of the person introducing the bot.
                  // introAvatarSrc?: string;
                  // Common questions to serve to the user as suggestions.
                  commonQuestions: []
                }]
              }).then(() => { Load(); });
            } else {
              return <></>;
            }
          }
          return <CampaignRow
            rowNum={index}
            organizationId={context.db.organizationId}
            campaignId={doc.id}
            key={doc.id}
            campaign={campaign}
            save={(campaign: Campaign) => {
              return context.db.organizations.organizationId().campaigns.campaignId(doc.id).update(campaign).then(() => {
                Load();
              });
            }}
            delete={campaign.launched ? undefined : async () => {
              return context.db.organizations.organizationId().campaigns.campaignId(doc.id).delete().then(() => {
                Load();
              });
            }}
          />
        })}
      </table>
    </div >
  );
};

export default CampaignsScreen;