import React from 'react';
import { useState, useEffect } from "react";
import { Organization, OrganizationInvite } from "../lib/common-types";
import { useAppContext } from "../lib/context-prod";
import { DocumentSnapshot, QueryDocumentSnapshot, QuerySnapshot } from '@firebase/firestore';
import { Loading } from "./common/Loading";

function AccountScreen() {
  const [activeOrganizationIndex, setActiveOrganizationIndex] = useState<number | string>(-1);
  const [organizations, setOrganizations] = useState<DocumentSnapshot<Organization>[]>([]);
  // const [invitations, setInvitations] = useState<{ orgSnapshot: DocumentSnapshot<Organization>, inviteSnapshot: QueryDocumentSnapshot<OrganizationInvite> }[]>([]);
  const [invitations, setInvitations] = useState<QuerySnapshot<OrganizationInvite>>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const [dev, setDev] = useState<boolean>(new URLSearchParams(window.location.search).has('dev'));
  const context = useAppContext();
  const Load = async () => {
    setLoading(true);
    try {
      // let getorgs = context.db.users.userId().getUserOrganizations().then((orgSnapshots: DocumentSnapshot<Organization>[]) => {
      //   let orgIndex: number = -1;
      //   for (let i = 0; i < orgSnapshots.length; i++) {
      //     if (context.appUser.defaultOrganizationId === orgSnapshots[i].id) {
      //       orgIndex = i;
      //       console.log("found: " + orgSnapshots[i].id + " at index: " + orgIndex);
      //     }
      //   }
      //   if (orgIndex === -1) {
      //     orgIndex = 0;
      //     context.appUser.defaultOrganizationId = orgSnapshots[0].id;
      //     context.db.users.userId().update({ defaultOrganizationId: orgSnapshots[0].id });
      //   }
      //   setActiveOrganizationIndex(orgIndex);
      //   setOrganizations(orgSnapshots);
      // });
      let user = await context.db.users.userId(context.user.uid).get();
      context.appUser = user.data();
      let orgSnapshots: QueryDocumentSnapshot<Organization>[] = [];
      let orgPromises: Promise<any>[] = [];
      context.appUser.organizationIds.forEach(async organizationId => {
        orgPromises.push(context.db.organizations.organizationId(organizationId).get().then((org) => {
          orgSnapshots.push(org);
        }));
      });
      await Promise.all(orgPromises);
      orgSnapshots = orgSnapshots.sort((a, b) => {
        return a.data().created - b.data().created;
      });
      orgSnapshots.forEach((org, index) => {
        if (org.id === context.appUser.defaultOrganizationId) {
          setActiveOrganizationIndex(index);
        }
      });
      console.log("loaded organizations: " + JSON.stringify(orgSnapshots.map(data => data.data()), null, 2));
      setOrganizations(orgSnapshots);
      // let getinvites = context.db.users.userId().getOrganizationInvites(context.user.email)
      //   .then((res: {
      //     orgSnapshot: DocumentSnapshot<Organization>,
      //     inviteSnapshot: QueryDocumentSnapshot<OrganizationInvite>
      //   }[]) => {
      //     console.log("invites: ")
      //     setInvitations(res);
      //   });
      let getInvites = context.db.invites.getUserInvites(context.user.email)
        .then((inviteSnapshot: QuerySnapshot<OrganizationInvite>) => {
          setInvitations(inviteSnapshot);
        });

      await getInvites;

      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    Load();
  }, []);

  const handleInvite = (inviteSnapshot: QueryDocumentSnapshot<OrganizationInvite>, status: "accepted" | "declined") => {
    // let deleteInvitePromise = context.db.organizations.organizationId(x.orgSnapshot.id).organizationInvites.delete(x.inviteSnapshot.id);
    // context.db.organizations.organizationId(x.orgSnapshot.id).organizationUsers.userId(context.user.uid).set({ role: "admin", userId: context.user.uid })
    //   .then(async () => {
    //     await deleteInvitePromise;
    //     await Load();
    //   })
    let payload = { status: status, userId: context.user.uid };
    console.log("Payload: " + JSON.stringify(payload));
    context.db.inviteResponses.inviteId(inviteSnapshot.id).set(payload).then(() => {
      setLoading(true);
      setTimeout(() => {
        Load();
      }, 2000);
    })
  };

  const handleInviteDismiss = async (x: {
    orgSnapshot: DocumentSnapshot<Organization>;
    inviteSnapshot: QueryDocumentSnapshot<OrganizationInvite>;
  }) => {
    // handle invite dismissal logic here
    await context.db.organizations.organizationId(x.orgSnapshot.id).organizationInvites.delete(x.inviteSnapshot.id);
    await Load();
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', overflowY: "scroll" }}>
      <h1>Account Screen</h1>
      <div style={{ marginBottom: '20px' }}>
        <h2>Invitations:</h2>
        {invitations.docs.map((res) => (
          <div key={res.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <p style={{ marginRight: '10px' }}>{res.data().organizationName}</p>
            <button style={{ marginRight: '10px' }} onClick={() => handleInvite(res, "accepted")}>
              Accept
            </button>
            <button onClick={() => handleInvite(res, "declined")}>Dismiss</button>
          </div>
        ))}
      </div>
      <div style={{ marginBottom: '20px' }}>
        <h2>Organizations:</h2>
        <div
        // onChange={(event) => {
        //   setActiveOrganizationIndex(event.target.value);
        //   console.log("org index before: " + context.appUser.defaultOrganizationId);
        //   context.appUser.defaultOrganizationId = organizations[event.target.value].id;
        //   console.log("org index after: " + context.appUser.defaultOrganizationId);
        //   context.db.users.userId().update({ defaultOrganizationId: organizations[event.target.value].id });
        // }}
        >
          {organizations.map((organization, index) => (
            <div key={index}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: '10px',
                padding: '10px',
                backgroundColor: activeOrganizationIndex === index ? 'lightblue' : 'white'
              }}
            >
              <button
                onClick={() => {
                  setActiveOrganizationIndex(index);
                  context.db.users.userId().update({ defaultOrganizationId: organizations[index].id });
                  context.appUser.defaultOrganizationId = organizations[index].id;
                  context.setOrganizationId(organizations[index].id);
                }}
              >choose</button>
              <button
                onClick={async () => {
                  let email = prompt("Enter email to invite. This person will get full access to the organization.");
                  if (email) {
                    let inviteSnapshots = await context.db.invites.getOrganizationInvites(organization.id);
                    if (inviteSnapshots.docs.filter((invite) => invite.data().userEmail === email).length) {
                      alert("User already invited");
                      return;
                    }
                    let orgName = organization.data().name;
                    if (!orgName) {
                      alert("Organization name not set");
                      orgName = prompt("Enter Organization Name");
                      if (orgName) {
                        await context.db.organizations.organizationId(organization.id).update({ name: orgName });
                      } else {
                        alert("Organization name not set. Cannot invite user.");
                        return;
                      }
                    }
                    await context.db.invites.push({
                      userEmail: email,
                      created: new Date().getTime(),
                      organizationId: organization.id,
                      role: 'owner',
                      organizationName: organization.data().name
                    });
                    alert("User invited");
                  }
                }}
              >invite</button>
              <div>{organization.data().name}</div>

            </div>
          ))}
        </div>
      </div>
      <div><button
        onClick={async () => {
          let name = prompt("Enter Organization Name");
          if (name) {
            await context.db.organizations.push({ name: name, created: new Date().getTime(), description: "", type: "personal", plan: "free", owners: [context.db.userId] })
              .then(async (orgSnapshot) => {
                await context.db.users.userId().update({ defaultOrganizationId: orgSnapshot.id, organizationIds: context.appUser.organizationIds.concat([orgSnapshot.id]) });
                context.appUser = (await context.db.users.userId().get()).data();
                context.setOrganizationId(orgSnapshot.id);
                Load();
              });
          }
        }}
      >Create New Organization</button></div>
      <div style={{ marginBottom: '20px' }}>
        <h2>Organization: {organizations[activeOrganizationIndex].data().name}</h2>
        <pre>{JSON.stringify(organizations[activeOrganizationIndex].data(), null, 2)}</pre>
      </div>
    </div >
  );
};

export default AccountScreen;