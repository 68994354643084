import React from 'react';
import { useState, useEffect, useRef } from "react";
import { Organization, OrganizationInvite, Campaign, KnowledgeBase, KnowledgeSnippet } from "../lib/common-types";
import { useAppContext } from "../lib/context-prod";
import { QuerySnapshot, DocumentSnapshot, QueryDocumentSnapshot } from '@firebase/firestore';
import { Loading } from "./common/Loading";

import { FiSettings } from 'react-icons/fi';

function BotTrainingScreen() {
  const [loading, setLoading] = useState<boolean>(true);
  const [knowledgeBases, setKnowledgeBases] = useState<QuerySnapshot<KnowledgeBase>>();
  const [knowledgeBaseId, setKnowledgeBaseId] = useState<string>("");
  const [knowledgeBase, setKnowledgeBase] = useState<KnowledgeBase>();
  const context = useAppContext();
  const Load = async (mode: 'no-loading-screen' | 'loading-screen' = 'loading-screen') => {
    if (!context.db.organizationId) return;
    if (mode === 'loading-screen') setLoading(true);
    let knowledgeBases = await context.db.organizations.organizationId().knowledgeBases.get();
    setKnowledgeBases(knowledgeBases);
    if (mode === 'loading-screen') setLoading(false);
  };
  useEffect(() => {
    Load();
  }, [context.db.organizationId]);
  useEffect(() => {
    Load('no-loading-screen')
  }, [knowledgeBase])


  if (loading) {
    return <Loading />;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', height: '100%', width: '100%' }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowY: "auto",
        width: '300px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#cccccc',
        borderRadius: '4px',
      }}>
        <div
          style={{
            width: '100%',
            cursor: 'pointer',
            padding: '10px',
            fontWeight: 'bold',
            textAlign: 'center',
            boxSizing: 'border-box',
            borderBottomWidth: '2px',
            borderBottomColor: 'darkgrey',
            borderBottomStyle: 'solid',
          }}
        >
          Select a Knowledge Base
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            flexGrow: 1
          }}
        >
          {knowledgeBases.docs.map((doc, index) => {
            let knowledgeBase: KnowledgeBase = doc.data();
            return <div
              onClick={() => {
                setKnowledgeBaseId(doc.id);
                setKnowledgeBase(doc.data());
              }}
              style={{
                width: '100%', backgroundColor: knowledgeBaseId === doc.id ? '#aaaaff' : 'white', cursor: 'pointer',
                padding: '2px', color: knowledgeBaseId === doc.id ? 'white' : 'black',
                boxSizing: 'border-box'
              }}>
              {knowledgeBase.name}
            </div>
          })}
        </div>
        <div
          onClick={async () => {
            let ref = await context.db.organizations.organizationId().knowledgeBases.push({
              name: 'New Knowledge Base',
              created: new Date().getTime()
            });
            setKnowledgeBaseId(ref.id);
          }}
          style={{
            width: '100%', backgroundColor: '#5555ff', cursor: 'pointer',
            padding: '10px', fontWeight: 'bold',
            // color: 'white', 
            color: 'darkgrey',
            textDecoration: 'underline',
            textAlign: 'center',
            // borderWidth: 1, borderStyle: 'solid', borderColor: '#5555ff',
            // borderRadius: '4px', 
            marginBottom: '10px',
            boxSizing: 'border-box'
          }}>
          New Knowledge Base
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
        {knowledgeBaseId && <KnowledgeBaseScreen knowledgeBaseId={knowledgeBaseId} knowledgeBase={knowledgeBase} setKnowledgeBase={setKnowledgeBase} />}
      </div>
    </div >
  );
};

function KnowledgeBaseScreen(props: { knowledgeBaseId: string, knowledgeBase: KnowledgeBase, setKnowledgeBase: (knowledgeBase: KnowledgeBase) => void }) {
  const [loading, setLoading] = useState<boolean>(true);
  const [candidateSnippets, setCandidateSnippets] = useState<QuerySnapshot<KnowledgeSnippet>>();
  const [snippets, setSnippets] = useState<QuerySnapshot<KnowledgeSnippet>>();
  const [removedSnippets, setRemovedSnippets] = useState<QuerySnapshot<KnowledgeSnippet>>();
  const [snippetTypeSelected, setSnippetTypeSelected] = useState<'candidate' | 'live' | 'removed'>('candidate');
  const [newSnippetText, setNewSnippetText] = useState<string>("");
  const [name, setName] = useState<string>(props.knowledgeBase.name);
  const context = useAppContext();
  const timeoutRef = useRef<NodeJS.Timeout>();
  let Load = async () => {
    setLoading(true);
    // let candidateSnippetsPromise = context.db.organizations.organizationId().knowledgeBases.knowledgeBaseId(props.knowledgeBaseId).candidates.get();
    let snippetsPromise = context.db.organizations.organizationId().knowledgeBases.knowledgeBaseId(props.knowledgeBaseId).snippets.get();
    let removedSnippetsPromise = context.db.organizations.organizationId().knowledgeBases.knowledgeBaseId(props.knowledgeBaseId).removedSnippets.get();
    // setCandidateSnippets(await candidateSnippetsPromise);
    setSnippets(await snippetsPromise);
    setRemovedSnippets(await removedSnippetsPromise);
    setName(props.knowledgeBase.name);
    setLoading(false);
  }
  useEffect(() => {
    Load();
  }, [props.knowledgeBaseId]);
  if (loading) {
    return <Loading />;
  }
  let tabStyle = {
    display: 'flex',
    flex: 1,
    padding: '5px',
    cursor: 'pointer',
    margin: '5px',
    borderRadius: '4px',
  }
  return (<div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
    <div style={{ display: 'flex', width: '100%' }}>
      <input type="text" style={{ width: '100%', fontSize: '1.5rem', boxSizing: 'border-box' }}
        placeholder={"Knowledge Base Name"}
        value={name}
        onChange={async (e) => {
          if (timeoutRef.current) clearTimeout(timeoutRef.current);
          timeoutRef.current = setTimeout(async () => {
            console.log("saving knowledge base name");
            await context.db.organizations.organizationId().knowledgeBases.knowledgeBaseId(props.knowledgeBaseId).update({
              name: e.target.value
            });
            props.setKnowledgeBase({ ...props.knowledgeBase, name: e.target.value });
          }, 2000);
          setName(e.target.value);
        }} />
    </div>
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
      <div style={{
        ...tabStyle,
        boxSizing: 'border-box',
        backgroundColor: snippetTypeSelected === 'candidate' ? '#5555ff' : 'white',
        color: snippetTypeSelected === 'candidate' ? 'white' : 'black',
      }}
        onClick={() => setSnippetTypeSelected('candidate')}
      >Candidates</div>
      <div style={{
        ...tabStyle,
        boxSizing: 'border-box',
        backgroundColor: snippetTypeSelected === 'live' ? '#5555ff' : 'white',
        color: snippetTypeSelected === 'live' ? 'white' : 'black',
      }}
        onClick={() => setSnippetTypeSelected('live')}
      >Live</div>
      <div style={{
        ...tabStyle,
        boxSizing: 'border-box',
        backgroundColor: snippetTypeSelected === 'removed' ? '#5555ff' : 'white',
        color: snippetTypeSelected === 'removed' ? 'white' : 'black'
      }}
        onClick={() => setSnippetTypeSelected('removed')}
      >Removed</div>
    </div>
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
      <textarea style={{ width: '100%', height: '50px', boxSizing: 'border-box', margin: '20px' }}
        value={newSnippetText}
        onChange={(e) => setNewSnippetText(e.target.value)}
      />
      <button
        onClick={async () => {
          // await context.db.organizations.organizationId().knowledgeBases.knowledgeBaseId(props.knowledgeBaseId).candidates.push({
          //   answer: {
          //     text: newSnippetText,
          //     created: new Date().getTime()
          //   }
          // });
          alert("unimplemented. This should create an issue to review.")
          setNewSnippetText("");
          await Load();
        }}>Add</button>
    </div>
    <div style={{ display: 'flex', flexGrow: 1, width: '100%', overflowY: 'auto' }}>
      <table style={{ width: '100%', borderWidth: '2px', borderColor: 'grey' }}>
        <thead>
          <tr>
            <th>Quote/Snippet</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {snippetTypeSelected === 'candidate' && candidateSnippets.docs.map((doc, index) => {
            let snippet: KnowledgeSnippet = doc.data();
            return <tr>
              <td>{snippet.answer.text}</td>
              <td><FiSettings /></td></tr>
          })}
        </tbody>
      </table>
    </div>
  </div>);
}
export default BotTrainingScreen;