import React from 'react';
import { useState, useEffect, useRef } from "react";
import { useAppContext } from "../lib/context-prod";
import { Loading } from "./common/Loading";
import { QueryDocumentSnapshot } from '@firebase/firestore';
import { StreamEvent } from "../lib/firebase-config";
import { Conversation, ConversationExchange, Message, Campaign, Organization, SystemMessage, BotCopilotMessage, KnowledgeSnippet, KnowledgeBaseModule } from "../lib/common-types";
import { TimeAgo } from '../lib/datetime';
import { calculateSnippetsEmbeddings } from '../lib/embeddings';

import { HiHandRaised, HiPause } from "react-icons/hi2";
import { BsPersonFill, BsFillPlayFill } from "react-icons/bs";
import { AiFillRobot } from "react-icons/ai";
import { MdSupportAgent } from "react-icons/md";
import { GrClose } from "react-icons/gr";
import { MdSystemUpdate } from "react-icons/md";
import { IoMdSend } from "react-icons/io";
import { MdPostAdd } from "react-icons/md";
import { IoMdFlag } from "react-icons/io";
import { FaEdit } from "react-icons/fa";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { TbBrain } from "react-icons/tb";
import { GoChecklist } from "react-icons/go";
import { PrimaryButton, SecondaryButton, TextButton } from './common/ComponentsLib';

const cardSelectedColor = "#eeeeff";
const cardUnselectedColor = "#ffffff";

const timeColor = "#545454";
const campaignColor = "#545454";

const hostBubbleBackgroundColor = "#e8e8e8"; // "#0072C6";
const guestBubbleBackgroundColor = "#ffffff";

const modeButtonColor = "#6663FE";

export default function ConversationManager() {
  // let [organization, setOrganization] = useState<Change<Organization>>();
  let campaignsRef = useRef<{ [campaignId: string]: QueryDocumentSnapshot<Campaign> }>({});
  let [campaigns, setCampaigns] = useState<{ [campaignId: string]: Campaign }>({});

  let conversationsRef = useRef<{ [conversationId: string]: QueryDocumentSnapshot<Conversation> }>({});
  let [conversations, setConversations] = useState<{ [conversationId: string]: Conversation }>({});
  let [conversation2CampaignId, setConversation2CampaignId] = useState<{ [conversationId: string]: string }>({});

  let exchangesRef = useRef<{ [conversationId: string]: { [exchangeId: string]: QueryDocumentSnapshot<ConversationExchange> } }>({});
  let [exchanges, setExchanges] = useState<{ [conversationId: string]: { [exchangeId: string]: ConversationExchange } }>({});

  let systemMessagesRef = useRef<{ [conversationId: string]: { [messageId: string]: QueryDocumentSnapshot<SystemMessage> } }>({});
  let [systemMessages, setSystemMessages] = useState<{ [conversationId: string]: { [messageId: string]: SystemMessage } }>({});

  let [conversationStatusToGet, setConversationStatusToGet] = useState<"active" | "old" | 'last-hour'>("active");

  let [selectedConversationId, setSelectedConversationId] = useState("");
  // let [campaignId, setCampaignId] = useState("");
  let [loading, setLoading] = useState(true);
  let context = useAppContext();
  let Load = async () => {
    setLoading(true);
    context.organizationListener.addSubscription("convo", (event: StreamEvent) => {
      console.log("convo event: ", JSON.stringify(event.change.doc.data(), null, 2))
      if (event.handlerType === "organization") {
        // no need for organization data
        // setOrganization(event.change);
      } else if (event.handlerType === "campaign") {
        console.log("campaign");
        if (["added", "modified"].indexOf(event.change.type) > -1) {
          campaignsRef.current[event.change.doc.id] = event.change.doc;
        } else if (event.change.type === "removed") {
          delete campaignsRef.current[event.change.doc.id];
        }
      } else if (event.handlerType === "conversation") {
        console.log("conversation");
        if (["added", "modified"].indexOf(event.change.type) > -1) {
          conversationsRef.current[event.change.doc.id] = event.change.doc;
        } else if (event.change.type === "removed") {
          delete conversationsRef.current[event.change.doc.id];
        }
      } else if (event.handlerType === "exchange") {
        console.log("exchange");
        let conversationId = event.change.doc.ref.parent?.parent?.id;
        if (["added", "modified"].indexOf(event.change.type) > -1) {
          if (conversationId) {
            if (!exchangesRef.current[conversationId]) {
              exchangesRef.current[conversationId] = {};
            }
            exchangesRef.current[conversationId][event.change.doc.id] = event.change.doc;

          }
        } else if (event.change.type === 'removed') {
          if (conversationId) {
            delete exchangesRef.current[conversationId][event.change.doc.id];
          }
        } else {
          console.log("Unknown exchange change type: " + event.change.type);
        }
      } else if (event.handlerType === "systemMessage") {
        console.log("systemMessage");
        let conversationId = event.change.doc.ref.parent?.parent?.id;
        if (["added", "modified"].indexOf(event.change.type) > -1) {
          if (conversationId) {
            if (!systemMessagesRef.current[conversationId]) {
              systemMessagesRef.current[conversationId] = {};
            }
            systemMessagesRef.current[conversationId][event.change.doc.id] = event.change.doc;
          }
        } else if (event.change.type === 'removed') {
          if (conversationId) {
            delete systemMessagesRef.current[conversationId][event.change.doc.id];
          }
        } else {
          console.log("Unknown systemMessage change type: " + event.change.type);
        }
      } else {
        console.log("Unknown event handler type: " + event);
      }
    })
    setLoading(false);
  }
  useEffect(() => {
    Load();
  }, []);

  useEffect(() => {
    // get data from snapshot
    if (conversationStatusToGet === 'old') {
      return;
    }
    let campaigns_: { [campaignId: string]: Campaign } = {};
    Object.keys(campaignsRef.current).forEach((campaignId) => {
      campaigns_[campaignId] = JSON.parse(JSON.stringify(campaignsRef.current[campaignId].data()));
    });
    setCampaigns(campaigns_);
  }, [Object.values(campaignsRef.current), conversationStatusToGet]);

  useEffect(() => {
    if (conversationStatusToGet === 'old') {
      return;
    }
    let conversations_ = {};
    let conversations2CampaignId_ = {};
    Object.keys(conversationsRef.current).forEach((conversationId) => {
      conversations_[conversationId] = JSON.parse(JSON.stringify(conversationsRef.current[conversationId].data()));
      conversations2CampaignId_[conversationId] = conversationsRef.current[conversationId].ref.parent.parent.id;
    });
    setConversations(conversations_);
    setConversation2CampaignId(conversations2CampaignId_);
  }, [Object.values(conversationsRef.current), conversationStatusToGet]);

  useEffect(() => {
    if (conversationStatusToGet === 'old') {
      return;
    }
    let exchanges_ = {};
    Object.keys(exchangesRef.current).forEach((conversationId) => {
      exchanges_[conversationId] = {};
      Object.keys(exchangesRef.current[conversationId]).forEach((exchangeId) => {
        exchanges_[conversationId][exchangeId] = JSON.parse(JSON.stringify(exchangesRef.current[conversationId][exchangeId].data()));
      })
    });
    setExchanges(exchanges_);
  }, [
    Object.values(exchangesRef.current),
    Object.values(exchangesRef.current).map((conversation) => Object.values(conversation)),
    conversationStatusToGet
  ]);

  useEffect(() => {
    if (conversationStatusToGet === 'old') {
      return;
    }
    let systemMessages_ = {};
    Object.keys(systemMessagesRef.current).forEach((conversationId) => {
      systemMessages_[conversationId] = {};
      Object.keys(systemMessagesRef.current[conversationId]).forEach((messageId) => {
        systemMessages_[conversationId][messageId] = JSON.parse(JSON.stringify(systemMessagesRef.current[conversationId][messageId].data()));
      })
    });
    setSystemMessages(systemMessages_);
  }, [
    Object.values(systemMessagesRef.current),
    Object.values(systemMessagesRef.current).map((conversation) => Object.values(conversation)),
    conversationStatusToGet
  ]);

  useEffect(() => {
    setSelectedConversationId("");
    if (conversationStatusToGet === 'old') {
      (async () => {
        let campaignPromises = [];
        let conversationPromises = [];
        let exchangePromises = [];

        let campaigns_: { [campaignId: string]: Campaign } = {};
        let conversations_: { [conversationId: string]: Conversation } = {};
        let exchanges_: { [conversationId: string]: { [exchangeId: string]: ConversationExchange } } = {};
        let systemMessages_: { [conversationId: string]: { [messageId: string]: SystemMessage } } = {};
        let conversation2CampaignId_ = {};
        setLoading(true);
        campaignPromises.push(context.db.organizations.organizationId().campaigns.get().then((snapshot) => {
          snapshot.forEach((doc) => {
            campaigns_[doc.id] = JSON.parse(JSON.stringify(doc.data()));
          });
          for (let campaignId in campaigns_) {
            conversationPromises.push(
              context.db
                .organizations.organizationId()
                .campaigns.campaignId(campaignId)
                .conversations.get().then((snapshot) => {
                  snapshot.forEach((doc) => {
                    let conversationId = doc.id;
                    conversation2CampaignId_[conversationId] = campaignId;
                    systemMessages_[conversationId] = {};
                    conversations_[conversationId] = JSON.parse(JSON.stringify(doc.data()));
                    exchangePromises.push(
                      context.db.organizations.organizationId()
                        .campaigns.campaignId(campaignId)
                        .conversations.conversationId(conversationId).exchanges.get().then((snapshot) => {
                          exchanges_[conversationId] = {};
                          snapshot.forEach((doc) => {
                            exchanges_[conversationId][doc.id] = JSON.parse(JSON.stringify(doc.data()));
                          });
                        })
                    );
                  });
                })
            );
          }
        }));
        await Promise.all(campaignPromises);
        await Promise.all(conversationPromises);
        await Promise.all(exchangePromises);
        setCampaigns(campaigns_);
        setConversations(conversations_);
        console.log(JSON.stringify(conversations_, null, 2));
        setExchanges(exchanges_);
        setSystemMessages(systemMessages_);
        setConversation2CampaignId(conversation2CampaignId_);
        setLoading(false);
      })();

    }
  }, [conversationStatusToGet])

  // useEffect(() => {
  //   console.log("conversations", JSON.stringify(Object.values(conversations), null, 2));
  // }, [conversations])

  if (loading) {
    return <Loading />;
  }
  return (<div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '10px', boxSizing: 'border-box' }}>
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '25px', margin: '10px' }}>
      <label
        style={{ marginRight: '5px' }}
      >Choose a view: </label>
      <select
        style={{ border: '1px solid grey', backgroundColor: 'white', marginBottom: '0px' }}
        onChange={(e) => {
          if (e.target.value === 'old') {
            setConversationStatusToGet('old');
          } else if (e.target.value === 'active') {
            setConversationStatusToGet('active');
          } else {
            setConversationStatusToGet('last-hour');
          }
        }}
      >
        <option value='last-hour' selected={conversationStatusToGet === 'last-hour'}>Conversations in last hour</option>
        <option value='active' selected={conversationStatusToGet === 'active'}>Ongoing Conversations</option>
        <option value='old' selected={conversationStatusToGet === 'old'}>All Conversations</option>
      </select>

    </div>
    <div
      style={{ display: 'flex', flexDirection: "row", flexGrow: 1, backgroundColor: 'white', maxHeight: 'calc(100% - 35px)' }}>

      <div style={{
        display: 'flex', backgroundColor: 'white', /*margin: '20px',*/ flexWrap: 'wrap',
        overflowY: "scroll",
        width: selectedConversationId ? '350px' : '100%',
        alignContent: 'flex-start'
      }}>

        {Object.keys(conversations).sort((a, b) => {
          if (conversations[a].lastMessageTime && conversations[b].lastMessageTime) {
            return conversations[b].lastMessageTime - conversations[a].lastMessageTime;
          }
          return conversations[b].created - conversations[a].created;
        }).map((conversationId, index) => {
          // if (!conversationsRef.current || !conversationsRef.current[conversationId]) {
          //   return;
          // }
          // let conversationSnapshot = conversationsRef.current[conversationId];
          if (conversationStatusToGet === 'last-hour'
            && (!conversations[conversationId].lastMessageTime ||
              (
                conversations[conversationId].lastMessageTime
                && conversations[conversationId].lastMessageTime < Date.now() - 60 * 60 * 1000
              )
            )
          ) {
            return <></>;
          }
          let campaignId = conversation2CampaignId[conversationId];
          // let campaignId = conversationSnapshot.ref?.parent?.parent?.id;
          let campaign = campaignId
            && campaigns[campaignId] ?
            campaigns[campaignId]
            : null;
          return <Card
            key={index}
            campaign={campaign}
            conversation={conversations[conversationId]}
            exchanges={exchanges[conversationId] ? exchanges[conversationId] : {}}
            systemMessages={systemMessages[conversationId] ? systemMessages[conversationId] : {}}
            selected={selectedConversationId === conversationId}
            onClick={() => {
              setSelectedConversationId(conversationId);
            }}
            minimized={!!selectedConversationId}
            onModeChange={async (mode) => {
              await context.db.organizations.organizationId().campaigns.campaignId(campaignId).conversations.conversationId(conversationId).update({ mode: mode });
            }}
          />
        })}
      </div>
      {
        selectedConversationId && <ChatBox
          campaign={
            conversation2CampaignId[selectedConversationId]
              && campaigns[conversation2CampaignId[selectedConversationId]]
              ? campaigns[conversation2CampaignId[selectedConversationId]]
              : null
          }
          campaignId={conversation2CampaignId[selectedConversationId]}
          conversation={conversations[selectedConversationId]}
          conversationId={selectedConversationId}
          exchanges={exchanges[selectedConversationId] ? exchanges[selectedConversationId] : {}}
          systemMessages={systemMessages[selectedConversationId] ? systemMessages[selectedConversationId] : {}}
          onExit={() => { setSelectedConversationId(null); }}
          setMonitoring={(value: boolean) => {
            setSelectedConversationId(null);
            context.db.organizations.organizationId()
              .campaigns.campaignId(conversation2CampaignId[selectedConversationId])
              .conversations.conversationId(selectedConversationId).update({ active: value })
          }} />
      }
    </div></div>);
}

const styles = {
  bubbleStyle: {
    display: 'flex',
    flexGrow: 1,
    // borderRadius: '5px',
    // borderColor: '#E0E1EE',
    // borderWidth: 1,
    // borderStyle: 'solid',
    padding: '20px 15px', // '5px',
    // margin: '5px',
  },
  botBubbleStyle: {
    color: 'black', // 'white',
    // backgroundColor: hostBubbleBackgroundColor
  },
  guestBubbleStyle: {
    color: 'black',
    // backgroundColor: guestBubbleBackgroundColor
  },
}

namespace ExchangeActions {
  export function EditExchange(props: {
    text: string,
    update: (text: string) => void,
    cancel: () => void,
  }) {
    let [text, setText] = useState<string>(props.text);
    let textareaRef = useRef<HTMLTextAreaElement>(null);
    useEffect(() => {
      if (textareaRef.current) {
        textareaRef.current.focus();
      }
    }, [textareaRef.current]);
    return <div style={{
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      margin: '5px',
      padding: '5px',
    }}>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <textarea
          ref={textareaRef}
          style={{ ...styles.bubbleStyle, ...styles.botBubbleStyle, resize: 'none' }}
          value={text}
          onChange={(e) => { setText(e.target.value) }}
        />
        <PrimaryButton
          style={{ margin: '6px' }}
          onClick={() => {
            props.update(text);
          }}>update</PrimaryButton>
        <SecondaryButton
          style={{ margin: '6px' }}
          onClick={() => {
            props.cancel();
          }}>cancel</SecondaryButton>
      </div>
    </div>
  }

  // flagging bot behavior
  export function FlagExchange(props: {
    flag: (reason: string) => void,
  }) {
    let buttonStyle = {
      padding: '5px', border: 'solid 1px #af4a01', borderRadius: '3px', color: '#af4a01'
    }
    let inputRef = useRef<HTMLInputElement>(null);
    return <div style={{
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      margin: '5px',
      padding: '5px',
    }}>
      <div
        style={{ fontWeight: 'bold', marginBottom: '5px' }}
      >
        Why are you flagging this response?
      </div>
      <div style={{ marginBottom: '-2px', color: 'grey' }}>
        suggestions
      </div>
      <div
        style={{ marginTop: '5px', marginBottom: '5px' }}
      >
        {["It gets the facts wrong", "It's off topic", "It's not helpful", "Too Repetitive"].map((reason) => {
          return <button
            style={{ marginRight: '5px', ...buttonStyle }}
            onClick={() => {
              props.flag(reason);
            }}
          >
            {reason}
          </button>
        })}

      </div>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <div style={{
          display: 'flex',
          flexShrink: 1,
          alignSelf: 'center',
          marginRight: '8px',
          marginBottom: '2px'
        }}>Other: </div>
        <input
          style={{ display: 'flex', flexGrow: 1 }}
          type='text'
          placeholder="E.g. The bot said something wrong."
          maxLength={100}
        />
        <button
          style={{ ...buttonStyle, margin: '5px', flexShrink: 1 }}
          onClick={() => {
            props.flag(inputRef.current.value);
          }}>flag</button>
      </div>
    </div >
  }

  // adding to knowledge base
  export function AddExchangeToKnowledge(props: {
    addToKnowledge: (status: "pending" | "approved") => Promise<void>,
    cancel: () => void,
  }) {
    let inputRef = useRef<HTMLInputElement>(null);
    return <div style={{
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      // margin: '5px',
      // padding: '5px',
    }}>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column', padding: '10px', boxSizing: 'border-box' }}>
        <div
          style={{ fontWeight: 'bold', marginBottom: '8px' }}
        >
          Add to the Knowledge Base
        </div>
        <div style={{ display: 'flex', flexShrink: 1, paddingBottom: '8px', color: '#333' }}>
          Adding this will affect the bot's responses. To ensure the best performance, consider reviewing and testing it before adding it to the knowledge base.
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          {/* <PrimaryButton
            style={{
              // padding: '3px',
              // marginBottom: '4px',
              margin: '10px',
              width: '170px',
              // fontSize: '15px', border: 'solid 2px rgb(19, 181, 19)', borderRadius: '5px'
            }}
            onClick={() => {
              props.addToKnowledge("pending");
            }}>Add it pending review</PrimaryButton> */}
          <PrimaryButton
            style={{
              // padding: '3px', 
              width: '120px',
              margin: '10px'
              // fontSize: '15px', border: 'solid 1px #fa6b34', borderRadius: '5px', color: '#555'
            }}
            onClick={() => {
              props.addToKnowledge("approved");
            }}>Add</PrimaryButton>
          <SecondaryButton
            style={{
              margin: '10px',
              width: '2',
            }}
            onClick={() => {
              props.cancel();
            }}
          >
            Cancel
          </SecondaryButton>

        </div>
      </div >
    </div>
  }

  export function ReviewKnowledge(props: {
    knowledge: KnowledgeBaseModule,
  }) {
    let containerRef = useRef<HTMLDivElement>(null);
    let [position, setPosition] = useState<number>(0);
    let [clientWidth, setClientWidth] = useState<number>(0);
    let [snippets, setSnippets] = useState<(null | QueryDocumentSnapshot<KnowledgeSnippet>)[]>([]);
    let context = useAppContext();
    let [loading, setLoading] = useState<boolean>(true);
    const Load = () => {
      setLoading(true)
      let promises = props.knowledge.snippets.map(async (snippet) => {
        return context.db.organizations.organizationId().knowledgeBases.knowledgeBaseId(props.knowledge.knowledgeBaseId).snippets.snippetId(snippet.snippetId).get();
      });
      Promise.all(promises).then((values) => {
        setSnippets([null].concat(values).concat([null]));
        setLoading(false);
      });
    }
    useEffect(() => {
      Load();
    }, []);
    const leftMargin = 40;
    const boxMargin = 5;
    const borderSize = 1;
    useEffect(() => {
      if (containerRef.current) {
        let itemWidth = clientWidth - 2 * leftMargin + 2 * boxMargin + 2 * borderSize;
        containerRef.current.scrollTo({ left: 2 + borderSize + boxMargin - leftMargin + (position + 1) * (itemWidth), behavior: 'smooth' });
      }
    }, [position, containerRef.current, clientWidth]);
    useEffect(() => {
      if (containerRef.current) {
        setClientWidth(containerRef.current.clientWidth);
      }
    }, [containerRef.current, containerRef.current?.clientWidth]);
    if (loading) {
      return <></>;
    }
    return <div style={{ display: 'flex', width: '100%', flexDirection: 'row' }}>
      <div style={{ display: 'flex', cursor: 'pointer', fontSize: '30px', alignItems: 'center' }}
        onClick={() => {
          // scroll left if possible
          if (containerRef.current) {
            setPosition(Math.max(0, position - 1));
          }
        }}
      ><BsChevronCompactLeft /></div>
      <div ref={containerRef}
        style={{ width: '100%', display: 'flex', flexDirection: 'row', overflowX: 'hidden', whiteSpace: 'nowrap', border: 'solid 2px black' }}
      >
        {snippets.map((snippet, index) => {
          return <div
            style={{
              flex: `0 0 ${clientWidth - 2 * leftMargin}px`,
              whiteSpace: 'normal',
              borderStyle: 'solid',
              borderWidth: borderSize + 'px',
              borderColor: index === 0 || index === snippets.length - 1 ? 'white' : 'orange',
              margin: boxMargin + 'px'
            }}>
            {snippet && <div style={{ position: 'relative', width: '100%' }}>
              <div
                style={{ position: 'absolute', right: '5px', top: '0px', color: snippet && snippet.data().status === 'flagged' ? 'red' : 'grey' }}
                onClick={async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  // disable this button
                  if (!snippet) {
                    return;
                  }
                  await context.db.organizations.organizationId()
                    .knowledgeBases.knowledgeBaseId(snippet.ref.parent.parent.id)
                    .snippets.snippetId(snippet.id).update({ status: snippet.data().status === 'flagged' ? undefined : 'flagged' });
                  let snippets_ = [...snippets];
                  snippets_[index] = await context.db.organizations.organizationId()
                    .knowledgeBases.knowledgeBaseId(snippet.ref.parent.parent.id)
                    .snippets.snippetId(snippet.id).get();
                  setSnippets(snippets_);
                }}
              >
                <IoMdFlag />
              </div>
            </div>}
            {snippet && snippet.data().answer.text}
          </div>
        })}
      </div>
      <div style={{ display: 'flex', cursor: 'pointer', fontSize: '30px', alignItems: 'center' }}
        onClick={() => {
          // scroll left if possible
          if (containerRef.current) {
            setPosition(Math.min(props.knowledge.snippets.length - 1, position + 1));
          }
        }}
      ><BsChevronCompactRight /></div>
    </div >
  }
}

function Bubble(props:
  {
    // agent: 'bot' | 'guest' | 'system' | 'host',
    exchange: ConversationExchange,
    mode: 'card' | 'chat'
    minimized?: boolean
    id?: { exchangeId: string, conversationId: string, campaignId: string }
  }
) {
  let [hideOptions, setHideOptions] = useState(true);
  // let [editMode, setEditMode] = useState(false);
  let [actionMode, setActionMode] = useState<'none' | 'edit' | 'flag' | 'add-knowledge' | 'review-knowledge'>('none');
  // let [text, setText] = useState<string>(props.text);
  let context = useAppContext();

  let actionButtonStyle = {
    padding: "2px 10px 2px 10px",
    borderRadius: '5px',
    border: "solid 1px lightgrey",
    backgroundColor: 'white',
    // justifyContent: 'center',
    // alignItems: 'center',
    cursor: 'pointer'
  };
  return <div style={{ display: 'flex', flexDirection: 'column', height: (props.mode === 'card' ? '100%' : 'auto') }}>
    {(!props.minimized || (props.minimized && !props.exchange.hostMessage)) &&
      <div style={{
        width: '100%', backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', flex: (props.mode === 'card' ? '1' : '0 0 auto')
      }}>
        <div style={{
          margin: '0px', //'2px 5px 2px 2px',
          display: 'flex',
          maxWidth: '600px',
          // backgroundColor: 'white',
          width: '100%'
        }}>
          <div style={{ padding: '15px 0px 10px 15px', fontSize: '20px' }}><BsPersonFill color={"#555"} /></div>
          <div style={{ ...styles.bubbleStyle, ...styles.guestBubbleStyle }}>
            {props.exchange.guestMessage.text}
          </div>
        </div>
      </div>
    }
    {
      props.exchange.hostMessage &&
      <div
        style={{
          width: '100%', backgroundColor: hostBubbleBackgroundColor, display: 'flex', flexDirection: 'column', alignItems: 'center', flex: (props.mode === 'card' ? '1' : '0 0 auto')
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            // margin: '2px 5px 2px 2px',
            border: actionMode !== 'none' ? 'solid 2px grey' : 'none',
            borderRadius: actionMode !== 'none' ? '5px' : 'none',
            maxWidth: '600px',
            // backgroundColor: '#e8e8e8',
            width: '100%'
          }}
          onMouseEnter={() => { setHideOptions(props.mode === 'chat' && props.exchange.hostMessage ? false : true); }}
          onMouseLeave={() => { setHideOptions(true); }}
        >
          {
            actionMode === 'review-knowledge'
            && props.exchange.hostMessage
            && props.exchange.hostMessage.agent === 'bot'
            && props.exchange.hostMessage.botContext
            && props.exchange.hostMessage.botContext.moduleData['knowledge-base']
            && <div style={{ width: '100%' }}>go
              <ExchangeActions.ReviewKnowledge
                knowledge={props.exchange.hostMessage.botContext.moduleData['knowledge-base']}
              />
            </div>
          }
          {(!hideOptions
            && props.exchange?.hostMessage?.agent === 'bot'
            && props.exchange.hostMessage?.botContext?.moduleData['knowledge-base']?.snippets
            && props.exchange.hostMessage.botContext.moduleData['knowledge-base'].snippets.length > 0)
            && <div style={{ position: 'relative', width: '100%' }}>
              <div style={{
                ...actionButtonStyle,
                position: 'absolute', right: '2px', top: '-2px',
                // border: "solid 1px black",
                // backgroundColor: 'white',
                // width: '25px',
                // height: '25px', 
                // borderRadius: '5px', padding: '5px',
                // display: 'flex', justifyContent: 'center', alignItems: 'center',
                // cursor: 'pointer'
              }}
                onClick={() => {
                  setActionMode(actionMode === 'review-knowledge' ? 'none' : 'review-knowledge');
                }}
                title={"Knowledge base snippets used by the bot to make the response."}
              >
                <TbBrain />
                <span style={{ marginLeft: '5px' }}>see bot knowledge</span></div>
            </div>
          }
          <div style={{ /* marginBottom: '5px', */ display: 'flex', flexDirection: 'row' }}>
            <div style={{ padding: '15px 0px 10px 15px', fontSize: '20px' }}>
              {props.exchange.hostMessage.agent === 'bot'
                ? <AiFillRobot color={"#555"} />
                : props.exchange.hostMessage.agent === 'human'
                  ? <MdSupportAgent color={"#444"} /> : "?"}</div>

            <div style={{ ...styles.bubbleStyle, ...styles.botBubbleStyle }}>
              {props.exchange.hostMessage.text}
            </div>

          </div>
          <div style={{ display: hideOptions ? 'none' : 'block', position: 'relative', alignSelf: 'center', width: '100%' }}>
            <div style={{
              position: 'absolute',
              top: '-25px',
              width: '100%'
            }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <div style={{
                  ...actionButtonStyle,
                }}
                  title="Edit and send."
                  onClick={() => {
                    setActionMode(actionMode === 'edit' ? 'none' : 'edit');
                  }}
                  hidden={!props.id || !props.exchange.hostMessage}>
                  <FaEdit />
                  <span style={{ marginLeft: '5px' }}>edit</span>
                </div>
                <div style={{
                  ...actionButtonStyle
                }}
                  title={props.exchange.addedToKnowledgeBase ? "Added to the knowledge base" : "Add to knowledge base review queue."}
                  onClick={() => {
                    setActionMode(
                      actionMode === 'add-knowledge' || props.exchange.addedToKnowledgeBase
                        ? 'none'
                        : 'add-knowledge');
                  }}
                  hidden={!props.id || !props.exchange.hostMessage || props.exchange.hostMessage.agent === 'bot'}
                >
                  {props.exchange.addedToKnowledgeBase ? <GoChecklist /> : <MdPostAdd />}
                  <span style={{ marginLeft: '5px' }}>{props.exchange.addedToKnowledgeBase ? "added to knowledge" : "add to knowledge"}</span>
                </div>
                {/* <div style={{
                ...actionButtonStyle
              }}
                title="Flag for review"
                onClick={() => {
                  setActionMode(actionMode === 'flag' ? 'none' : 'flag');
                }}
              >
                <IoMdFlag />
              </div> */}
              </div>
            </div>
          </div>
          <div style={{ width: '100%', backgroundColor: 'white' }}>
            {actionMode === 'edit' && <ExchangeActions.EditExchange text={props.exchange.hostMessage.text}
              cancel={() => setActionMode('none')}
              update={(text) => {
                let exchangeDB = context.db.organizations
                  .organizationId().campaigns
                  .campaignId(props.id.campaignId).conversations
                  .conversationId(props.id.conversationId).exchanges.exchangeId(props.id.exchangeId);
                if (!text) {
                  alert("Please enter a message.")
                }
                exchangeDB.hostMessageVersionHistory.push(props.exchange.hostMessage);
                // TODO: when the edit happens, the bot knowledge goes away, which is confusing for the user.
                exchangeDB.update({
                  hostMessage: {
                    agent: 'human',
                    text: text,
                    created: new Date().getTime(),
                    action: 'create',
                    sent: true
                  }
                }).then(() => { setActionMode("none"); });
              }} />}
            {actionMode === 'flag' && <ExchangeActions.FlagExchange flag={(reason: string) => { throw "Unimplemented" }} />}
            {actionMode === 'add-knowledge'
              && <ExchangeActions.AddExchangeToKnowledge
                cancel={() => setActionMode('none')}
                addToKnowledge={async (status: "pending" | "approved") => {
                  if (!props.id) {
                    throw "No id";
                  }
                  // get the 
                  let campaignSnapshot = await context.db.organizations.organizationId().campaigns.campaignId(props.id.campaignId).get();
                  let bot = campaignSnapshot.data().bots[0];
                  if (!bot) {
                    throw "No bot";
                  }
                  if (!bot.promptSettings.modules['knowledge-base']) {
                    console.log(JSON.stringify(bot.promptSettings));
                    alert("There is no knowledge base associated with this campaign. Please go to the campaigns tab and add one in order to add a message to it.");
                    throw "No knowledge base";
                  }
                  let knowledgeBaseId = bot.promptSettings.modules['knowledge-base'].knowledgeBaseId;
                  let value: KnowledgeSnippet = {
                    topicQuestion: props.exchange.guestMessage.text,
                    answer: {
                      text: props.exchange.hostMessage.text,
                      created: new Date().getTime(),
                    },
                    sourceInfo: {
                      source: 'exchange',
                      exchangeId: props.id.exchangeId,
                      conversationId: props.id.conversationId,
                      campaignId: props.id.campaignId
                    },
                    // milliseconds since the unix epoch
                    created: new Date().getTime(),
                    status: status
                  };
                  value = await calculateSnippetsEmbeddings([value], context)[0];
                  await context.db.organizations.organizationId().knowledgeBases.knowledgeBaseId(knowledgeBaseId).snippets.push(value);
                  await context.db.organizations
                    .organizationId().campaigns
                    .campaignId(props.id.campaignId).conversations
                    .conversationId(props.id.conversationId).exchanges.exchangeId(props.id.exchangeId)
                    .update({
                      ...props.exchange,
                      addedToKnowledgeBase: true
                    });
                  setActionMode("none");
                }}
              />}
          </div>
        </div >
      </div>
    }
  </div >;

}

function SystemMessageBubble(props: { text: string }) {
  return <div style={{ marginBottom: '5px', display: 'flex', flexDirection: 'row' }}>
    <div style={{ paddingTop: "10px", paddingBottom: "10px", fontSize: '20px' }}>
      <MdSystemUpdate /></div>
    <div style={{ textAlign: 'center', margin: '5px' }}>
      --{props.text}--
    </div>
  </div>;
}

function BotModeButton(props:
  {
    onModeChange: (mode: 'autopilot' | 'copilot') => Promise<void>,
    conversation: Conversation,
    style: React.CSSProperties,
    verbose?: boolean
  }) {
  const [disabled, setDisabled] = useState(false);
  return <SecondaryButton
    title={"Pause or resume the bot. Pausing the bot will allow you to jump in and send your own messages."}
    disabled={disabled}
    onClick={(event) => {
      event.stopPropagation();
      setDisabled(true);
      props.onModeChange(props.conversation.mode === 'autopilot' ? 'copilot' : 'autopilot').then(() => {
        setDisabled(false);
      }).catch(() => {
        setDisabled(false);
      });
    }}
    style={props.style}>
    {props.conversation.mode === 'autopilot'
      ? <div><HiPause />{props.verbose ? "Pause bot and enter conversation" : "Pause bot"}</div>
      : <div><BsFillPlayFill />{props.verbose ? "Run bot in automatic mode" : "Run bot"}</div>}
  </SecondaryButton>
}

function Card(props:
  {
    campaign: Campaign,
    conversation: Conversation;
    exchanges: { [exchangeId: string]: ConversationExchange },
    systemMessages: { [messageId: string]: SystemMessage },
    onClick: () => void,
    selected: boolean,
    minimized: boolean,
    onModeChange: (mode: "copilot" | "autopilot") => Promise<void>
  }) {
  let [exchange, setExchange] = useState<ConversationExchange>(null);
  let [systemMessage, setSystemMessage] = useState<SystemMessage | null>(null);
  let [timeAgo, setTimeAgo] = useState<string>(null);
  let [userName, setUserName] = useState<string>("");
  useEffect(() => {
    if (!props.conversation) {
      return;
    }
    setUserName(props.conversation.userName.toLowerCase() // upper case first letters
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" "))
  }, [props.conversation]);
  useEffect(() => {
    // sort in ascending order
    let exchanges = Object.values(props.exchanges).sort((a, b) => a.created - b.created);
    if (exchanges.length > 0) {
      setExchange(exchanges[exchanges.length - 1]);
      setTimeAgo(TimeAgo(exchanges[exchanges.length - 1].created));
    }
  }, [props.exchanges]);

  useEffect(() => {
    // sort in ascending order
    let systemMessages = Object.values(props.systemMessages).sort((a, b) => a.created - b.created);
    if (systemMessages.length > 0) {
      setSystemMessage(systemMessages[systemMessages.length - 1]);
    }
  }, [props.systemMessages]);

  let timeAgoTimeout = useRef<NodeJS.Timeout>(null);
  useEffect(() => {
    if (timeAgoTimeout.current) {
      clearTimeout(timeAgoTimeout.current);
    }
    timeAgoTimeout.current = setTimeout(() => {
      if (exchange) {
        setTimeAgo(TimeAgo(exchange.created));
      }
    }, 10 * 1000);
  }, [timeAgo]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: props.minimized ? "0px 10px" : "10px",
        padding: "10px",
        backgroundColor: props.selected ? cardSelectedColor : cardUnselectedColor,
        border: "1px solid " + (props.selected ? 'black' : 'lightgrey'),
        // borderRadius: "10px",
        cursor: "pointer",
        width: "300px",
        minWidth: '300px',
        // height: props.minimized ? undefined : "412px",
        height: props.minimized ? "206px" : "312px", // "412px",
        // shadow
        // boxShadow: "0 0 8px rgba(0,0,0,0.2)"
      }}
      onClick={props.onClick}
    >
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div
          title={"The alias of the contact the bot is chatting with."}
          style={{ fontSize: '1.2rem' }}>
          {userName && userName.length > 18 ? userName.slice(0, 15) + "..." : userName}
        </div>
        <div title={"Time since the last message."} style={{ fontSize: '0.8rem', color: timeColor }}>{timeAgo}</div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderBottom: 'solid 1px lightgray' }}>
        <div title={"Campaign name."} style={{ color: campaignColor }}>
          {props.campaign.name}
        </div>
        <div style={{ fontSize: '0.8rem', color: campaignColor }}>
          {/* {
            (systemMessage
              && systemMessage.action === 'contact-asked-for-assistance'
              && systemMessage.created > exchange.created)
              ? <HiHandRaised /> : ""
          } */}
        </div>
      </div>
      {Object.values(props.exchanges ? props.exchanges : {}).sort((a, b) => a.created - b.created).slice(-1).map((exchange, index) => {
        return <div key={index} style={{ height: '100%', marginBottom: '10px', marginTop: '10px' }}>
          <Bubble mode={'card'} minimized={props.minimized} exchange={
            (() => {
              let exchange_ = JSON.parse(JSON.stringify(exchange));
              if (exchange_.guestMessage && exchange_.guestMessage.text && exchange_.guestMessage.text.length > 100) {
                exchange_.guestMessage.text = exchange_.guestMessage.text.substring(0, 80) + "...";
              }
              if (exchange_.hostMessage && exchange_.hostMessage.text && exchange_.hostMessage.text.length > 100) {
                exchange_.hostMessage.text = exchange_.hostMessage.text.substring(0, 80) + "...";
              }
              return exchange_;
            })()
          } />
        </div>

      })}
      <div style={{ display: 'flex', flexGrow: 1 }}></div>
      <div>
        <BotModeButton onModeChange={props.onModeChange} conversation={props.conversation} style={{ width: '100%' }} />
      </div>
    </div >
  );
}

function ChatBox(props: {
  campaign: Campaign,
  campaignId: string,
  conversation: Conversation;
  conversationId: string,
  exchanges: { [exchangeId: string]: ConversationExchange },
  systemMessages: { [systemMessageId: string]: SystemMessage },
  onExit: () => void
  setMonitoring: (value: boolean) => void
}) {
  let [messages, setMessages] = useState<
    Array<
      { type: 'exchange', id: string, value: ConversationExchange }
      | { type: 'system', id: string, value: SystemMessage }
    >
  >([]);
  let emptyChatMessage: Message = { agent: 'human', text: '', created: new Date().getTime(), action: 'create', sent: false };
  let [chatMessage, setChatMessage] = useState<Message>(emptyChatMessage);
  let [mostRecentExchangeId, setMostRecentExchangeId] = useState<string>("");
  let [disableTextarea, setDisableTextarea] = useState<boolean>(false);
  let [userName, setUserName] = useState<string>("");
  let context = useAppContext();
  useEffect(() => {
    let text: string;
    if (props.exchanges[mostRecentExchangeId]
      && !props.exchanges[mostRecentExchangeId].hostMessage
      && props.exchanges[mostRecentExchangeId].botSuggestions
      && props.exchanges[mostRecentExchangeId].botSuggestions.length > 0) {
      text = props.exchanges[mostRecentExchangeId].botSuggestions[0].text;
    } else {
      text = "";
    }
    setChatMessage({ ...chatMessage, text: text });
  }, [mostRecentExchangeId])
  useEffect(() => {
    setUserName(props.conversation.userName.toLowerCase() // upper case first letters
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" "))
  }, [props.conversation.userName])
  useEffect(() => {
    if (Object.keys(props.exchanges).length > 0) {
      setMostRecentExchangeId(Object.keys(props.exchanges).sort((a, b) => props.exchanges[a].created - props.exchanges[b].created).slice(-1)[0]);
    }
    // combine
    let combined: Array<
      { type: 'exchange', id: string, value: ConversationExchange }
      | { type: 'system', id: string, value: SystemMessage }
    > = [];
    Object.keys(props.exchanges).forEach((key) => {
      combined.push({ type: 'exchange', id: key, value: props.exchanges[key] });
    });
    Object.keys(props.systemMessages).forEach((key) => {
      if (props.systemMessages[key]) {
        combined.push({ type: 'system', id: key, value: props.systemMessages[key] });
      } else {
        throw "System message is undefined";
      }
    });
    combined.sort((a, b) => {
      return a.value.created - b.value.created;
    });
    setDisableTextarea(props.conversation.mode !== 'copilot'
      || Object.keys(props.exchanges).length === 0
      || Object.values(props.exchanges).sort((a, b) => a.created - b.created).slice(-1)[0].hostMessage !== undefined)
    setMessages(combined);
  }, [props.exchanges, props.systemMessages]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        borderWidth: '1px',
        borderStyle: 'solid',
        width: 'calc(100% - 360px)',
        // maxWidth: '750px',
      }}
    >
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginLeft: '15px',
        marginTop: '4px',
        marginRight: '4px',

      }}>
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '15px', marginTop: '10px', marginLeft: '5px' }}>
          <div style={{ display: 'flex', fontSize: "20px", marginBottom: '3px' }} title={"The alias of the contact that the bot is chatting with."}>
            {userName}
          </div>
          <div style={{ display: 'flex', fontSize: "14px", marginBottom: '3px' }} title={"The campaign name."}>
            {props.campaign.name}
          </div>
          <div
            title="Keep checked if the conversation is ongoing, otherwise, uncheck it to remove it from the ongoing conversations list."
            style={{ display: 'flex', flexDirection: 'row' }}>
            <input type='checkbox'
              checked={props.conversation.active}
              onChange={(e) => {
                e.stopPropagation();
                e.target.disabled = true;
                if (props.conversation.active) {
                  props.setMonitoring(false);
                } else {
                  props.setMonitoring(true);
                }
                e.target.disabled = false;
              }}
            />
            <span>Monitor</span>
            {props.conversation.lastSystemMessageAction === 'contact-asked-for-assistance'
              && <span style={{ color: 'grey' }}><HiHandRaised /></span>}
          </div>
        </div>
        <div onClick={props.onExit} style={{ textAlign: 'right', cursor: 'pointer' }} title={"Close the chat but keep monitoring it as an ongoing conversation."}><GrClose /></div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflowY: "scroll",
          // marginLeft: '15px',
          // marginRight: '15px',
          marginBottom: '15px',
        }}
      >
        {messages.map((value, index) => {
          if (value.type === 'system') {
            return <div key={index}>--{
              value.value.action === 'contact-asked-for-assistance'
                ? "guest asked for assistance"
                : value.value.action === 'host-activated-bot'
                  ? 'bot turned on' : value.value.action === 'host-took-over'
                    ? 'bot paused'
                    : 'unknown system message' + value.value.text}--</div>
          }
          let exchange = value.value;
          return <div key={index}>
            <Bubble exchange={exchange} mode={'chat'} id={{
              conversationId: props.conversationId,
              campaignId: props.campaignId,
              exchangeId: value.id,
            }} />
          </div>
        })}
      </div>
      <div style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: props.conversation.mode === 'autopilot' ? '20px' : '0px'
      }}><BotModeButton
          conversation={props.conversation}
          verbose={true}
          style={{ width: '300px' }}
          onModeChange={async (mode: 'autopilot' | 'copilot') => {
            context.db.organizations.organizationId().campaigns.campaignId(props.campaignId).conversations.conversationId(props.conversationId).update({ mode: mode });
          }} /></div>
      {props.conversation.mode === 'copilot' && props.conversation.active && <div>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          backgroundColor: 'white',
        }}>
          <textarea
            value={chatMessage.text}
            disabled={disableTextarea}
            onChange={(e) => {
              // setting the value does not trigger a change event on the textarea
              setChatMessage({ ...chatMessage, text: e.target.value })
            }}
            placeholder="Type your message here..."
            cols={2}
            style={{
              resize: 'none',
              borderColor: '#D9F0FB',
              backgroundColor: 'white',
              display: 'flex',
              flexGrow: 1,
              paddingRight: '50px',
              borderRadius: '5px',
              borderStyle: 'solid',
              borderWidth: '2px',
              margin: '15px'
            }} />
          <button type="submit"
            style={{
              border: 'none',
              backgroundColor: 'transparent',
              cursor: 'pointer',
              transition: "color 0.2s ease-in-out",
              color: chatMessage.text ? 'rgb(85, 85, 255)' : 'lightgrey',
              fontSize: '25px',
              marginLeft: '-50px',
              marginRight: '20px',

            }} // remove border and background color and make icon change color on hover and press
            onMouseEnter={(e) => {
              e.currentTarget.style.color = 'green';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.color = chatMessage.text ? 'black' : 'lightgrey';
            }}
            onMouseDown={(e) => {
              e.currentTarget.style.color = 'lightgreen';
            }}
            onMouseUp={(e) => {
              e.currentTarget.style.color = chatMessage.text ? 'black' : 'lightgrey';
            }}
            disabled={disableTextarea || !chatMessage.text}
            onClick={async (e) => {
              e.preventDefault();
              if (chatMessage.agent === 'bot') {
                // add it to the version history
                throw new Error("chatMessage should never be bot here, since it must be in copilot mode for this to be an option.")
              }
              e.currentTarget.disabled = true;
              // send it
              await context.db.organizations.organizationId()
                .campaigns.campaignId(props.campaignId)
                .conversations.conversationId(props.conversationId)
                .exchanges.exchangeId(mostRecentExchangeId)
                .update({
                  hostMessage: { ...chatMessage, agent: 'human', sent: true, created: new Date().getTime() }
                }).then(() => {
                  setChatMessage(emptyChatMessage);
                });

              e.currentTarget.disabled = false;
            }}
          ><IoMdSend /></button>
        </div>
      </div>
      }
    </div>
  );
}