export function yearMonthDayPST(date = new Date()) {
  return new Date(date.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })).toISOString().substring(0, 10);
}
export function TimeAgo(timestamp?: number) {
  if (!timestamp) {
    return "";
  }
  let now = new Date().getTime();
  let diff = now - timestamp;
  if (diff > 1000 * 60 * 60 * 24 * 365) {
    return Math.floor(diff / (1000 * 60 * 60 * 24 * 365)) + "y";
  } else if (diff > 1000 * 60 * 60 * 24 * 30) {
    return Math.floor(diff / (1000 * 60 * 60 * 24 * 30)) + "m";
  } else if (diff > 1000 * 60 * 60 * 24) {
    return Math.floor(diff / (1000 * 60 * 60 * 24)) + "d";
  } else if (diff > 1000 * 60 * 60) {
    return Math.floor(diff / (1000 * 60 * 60)) + "h";
  } else if (diff > 1000 * 60) {
    return Math.floor(diff / (1000 * 60)) + "m";
  } else {
    return Math.floor(diff / (1000)) + "s";
  }
}