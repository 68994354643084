// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import {
  AppUser, OrganizationUser, OrganizationInvite, Organization, Message,
  ConversationExchange, SystemMessageAction, SystemMessage, ConversationMode,
  Conversation, BotConfig, Campaign, LogEvent, KnowledgeBase, KnowledgeSnippet, OrganizationInviteResponse, OnboardingChecklist, MultiPageKnowledgeUploadProcess, ScrapedLink, ScrapedContent, FAQ, MultiPageKnowledgeUploadProgress, StartMultiPageBulkUploadInput, StartMultiPageBulkUploadOutput
} from "./common-types";


import {
  getFirestore, Firestore, collection, doc,
  addDoc, setDoc, getDoc, CollectionReference,
  deleteDoc, updateDoc, query, where, limit,
  getDocs, orderBy, startAfter, startAt,
  onSnapshot,
  DocumentSnapshot,
  DocumentReference,
  Unsubscribe,
  DocumentData,
  QueryDocumentSnapshot,
  QuerySnapshot,
  DocumentChange,
  collectionGroup,
  arrayUnion,
  WhereFilterOp,
  runTransaction
} from "firebase/firestore";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
  User
} from "firebase/auth";
import { TextEmbeddingModel } from "./common-types";
import { getFunctions, httpsCallable, } from 'firebase/functions';

import { getAnalytics } from "firebase/analytics";

// import * as cryptojs from 'crypto-js';
// import crypto from 'crypto';
// const crypto = require('crypto');

// var hash = require('hash.js');
import * as hash from 'hash.js';

function hashString(text: string) {
  // const hash = crypto.createHash('sha256');
  // hash.update(str);
  return hash.sha256().update(text).digest('hex');
}


// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {

  apiKey: "AIzaSyBwauqs86ZfjKv7Igt9e8LEohgbqffZaGw",

  authDomain: "ourbot-5b8a8.firebaseapp.com",

  projectId: "ourbot-5b8a8",

  storageBucket: "ourbot-5b8a8.appspot.com",

  messagingSenderId: "394323980711",

  appId: "1:394323980711:web:2f703dfb589e3ff2e54682",

  measurementId: "G-2W4FLKZ8MB"

};


// Initialize Firebase

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const functions = getFunctions(app);
export const firestore: Firestore = getFirestore(app);
const analytics = getAnalytics(app);

const googleProvider = new GoogleAuthProvider();

// from the openai api docs: https://platform.openai.com/docs/api-reference/chat/create
export interface ChatCompletionMessage {
  role: 'user' | 'assistant' | 'system',
  content: string,
  name?: string
}

export interface CreateEmbeddingRequest {
  'model': TextEmbeddingModel;
  'input': Array<string>;
  'user'?: string;
}
export interface CreateChatCompletionRequest {
  'model': 'gpt-3.5-turbo-0301';
  'messages': ChatCompletionMessage[];
  'temperature'?: number | null;
  'top_p'?: number | null;
  'n'?: number | null;
  // 'stream'?: boolean | null;
  'stop'?: Array<string> | string;
  'max_tokens'?: number;
  'presence_penalty'?: number | null;
  'frequency_penalty'?: number | null;
  'logit_bias'?: object | null;
  'user'?: string;
};
export const createChatCompletion = httpsCallable<CreateChatCompletionRequest, ChatCompletionMessage>(functions, "createChatCompletion");
export const createEmbedding = httpsCallable<CreateEmbeddingRequest, number[][]>(functions, "createEmbedding");
// export const scrapePage = httpsCallable<{ url: string }, { content: string }>(functions, "scrapePage");
export const retrieveSnippets = httpsCallable<
  ({ url: string, text?: string } | { text: string, url?: string }),
  ({ ok: false } | { ok: true, snippets: { question: string, answer: string }[] })
>(functions, "retrieveSnippets");
export const startMultiPageBulkUpload = httpsCallable<StartMultiPageBulkUploadInput, StartMultiPageBulkUploadOutput>(functions, "startMultiPageBulkUpload");

export async function signInWithGoogle(): Promise<User> {
  return signInWithPopup(auth, googleProvider)
    .then((credential) => credential.user);
}

export async function signOutWithGoogle() {
  return signOut(getAuth());
}

export function initFirebaseAuth(callback: (user: User | null) => void) {
  onAuthStateChanged(getAuth(), callback);
}

export interface FBAuth {
  initFirebaseAuth: (callback: (user: User | null) => void) => void;
  signInWithGoogle: () => Promise<User>;
  signOutWithGoogle: () => Promise<void>;
}


export class DB {
  userId: string | undefined;
  organizationId: string | undefined;
  constructor(defaultUserId?: string, defaultOrganizationId?: string) {
    this.userId = defaultUserId;
    this.organizationId = defaultOrganizationId;
  }
  // internal
  logs = {
    console: {
      yearMonthDay: (yearMonthDay: string /*'YYYY-mm-dd' in PST timezone*/) => {
        let logsCollection = collection(firestore, 'logs');
        let consoleCollection = collection(logsCollection, 'console', yearMonthDay);
        return {
          push: async (log: LogEvent) => {
            return addDoc(consoleCollection, log);
          },
        };
      }
    }
  }
  invites = {
    push: async (invite: OrganizationInvite) => {
      return addDoc(collection(firestore, 'invites'), invite);
    },
    inviteId: (inviteId: string) => {
      let inviteDoc = doc(collection(firestore, 'invites'), inviteId);
      return {
        get: async (): Promise<QueryDocumentSnapshot<OrganizationInvite>> => {
          return getDoc(inviteDoc) as Promise<QueryDocumentSnapshot<OrganizationInvite>>;
        },
        delete: async () => {
          return deleteDoc(inviteDoc);
        }
      };
    },
    getUserInvites: async (userEmail: string): Promise<QuerySnapshot<OrganizationInvite>> => {
      return getDocs(query(collection(firestore, 'invites'), where('userEmail', "==", userEmail))) as Promise<QuerySnapshot<OrganizationInvite>>;
    },
    getOrganizationInvites: async (organizationId: string): Promise<QuerySnapshot<OrganizationInvite>> => {
      return getDocs(query(collection(firestore, 'invites'), where('organizationId', "==", organizationId))) as Promise<QuerySnapshot<OrganizationInvite>>;
    }
  }
  inviteResponses = {
    inviteId: (inviteId: string) => {
      let inviteResponseCollection = collection(firestore, 'inviteResponses');
      return {
        set: async (response: OrganizationInviteResponse) => {
          return setDoc(doc(inviteResponseCollection, inviteId), response);
        }
      };
    }
  }
  users = {
    userId: (userId: string | undefined = this.userId) => {
      if (!userId) {
        throw new Error('userId is not set');
      }
      let userDoc = doc(collection(firestore, 'users'), userId);
      return {
        get: async (): Promise<QueryDocumentSnapshot<AppUser>> => {
          return getDoc(userDoc) as Promise<QueryDocumentSnapshot<AppUser>>;
        },
        set: async (user: AppUser) => {
          return setDoc(userDoc, user);
        },
        update: (partialUser: Partial<AppUser>) => {
          return setDoc(userDoc, partialUser, { merge: true });
        },
        getUserOrganizations: async (): Promise<DocumentSnapshot<Organization>[]> => {
          return getDocs(query(collectionGroup(firestore, 'organizationUsers'), where('userId', "==", userId)))
            .then((querySnapshot) => {
              let orgs: { [path: string]: DocumentReference<Organization> } = {};
              for (let doc of querySnapshot.docs) {
                if (!doc.ref?.parent?.parent) {
                  // should never happen
                  throw new Error('doc.ref.parent.parent is null');
                }
                orgs[doc.ref.parent.parent.path] = doc.ref.parent.parent as DocumentReference<Organization>;
              }
              return Promise.all(Object.values(orgs).map(org => getDoc(org)));
            });
        },
      };
    }
  };
  organizations = {
    push: async (organization: Organization): Promise<DocumentReference<Organization>> => {
      return addDoc(collection(firestore, 'organizations'), organization) as Promise<DocumentReference<Organization>>;
    },
    organizationId: (organizationId: string | undefined = this.organizationId) => {
      if (!organizationId) {
        throw new Error('organizationId is not set');
      }
      let organizationDoc = doc(collection(firestore, 'organizations'), organizationId);
      return {
        get: async (): Promise<QueryDocumentSnapshot<Organization>> => {
          return getDoc(organizationDoc) as Promise<QueryDocumentSnapshot<Organization>>;
        },
        onChange: (callback: (snapshot: QueryDocumentSnapshot<Organization>) => void) => {
          return onSnapshot(organizationDoc, (snapshot) => {
            callback(snapshot as QueryDocumentSnapshot<Organization>);
          });
        },
        set: async (organization: Organization) => {
          return setDoc(organizationDoc, organization);
        },
        update: (organization: Partial<Organization>) => {
          return setDoc(organizationDoc, organization, { merge: true });
        },
        embeddings: {
          text: (text: string) => {
            return {
              get: (): Promise<DocumentSnapshot<{ model: 'text-embedding-ada-002'; embedding: number[] }>> => {
                return getDoc(doc(collection(organizationDoc, 'embeddings'), hashString(text))) as Promise<DocumentSnapshot<{ model: 'text-embedding-ada-002'; embedding: number[] }>>;
              },
              set: (value: { model: 'text-embedding-ada-002'; embedding: number[] }) => {
                return setDoc(doc(collection(organizationDoc, 'embeddings'), hashString(text)), value);
              }
            }
          },
        },
        info: {
          onboarding: {
            get: (): Promise<QueryDocumentSnapshot<OnboardingChecklist>> => {
              return getDoc(doc(collection(organizationDoc, 'info'), 'onboarding')) as Promise<QueryDocumentSnapshot<OnboardingChecklist>>;
            },
            update: (onboarding: OnboardingChecklist) => {
              return setDoc(doc(collection(organizationDoc, 'info'), 'onboarding'), onboarding, { merge: true });
            }
          }
        },
        organizationUsers: {
          userId: (userId: string) => {
            let organizationUserDoc = doc(collection(organizationDoc, 'organizationUsers'), userId);
            return {
              get: async (): Promise<QueryDocumentSnapshot<OrganizationUser>> => {
                return getDoc(organizationUserDoc) as Promise<QueryDocumentSnapshot<OrganizationUser>>;
              },
              set: async (organizationUser: OrganizationUser) => {
                return setDoc(organizationUserDoc, organizationUser);
              },
            }
          },
          get: (): Promise<QuerySnapshot<OrganizationUser>> => {
            return getDocs(collection(organizationDoc, 'organizationUsers')) as Promise<QuerySnapshot<OrganizationUser>>;
          }
        },
        organizationInvites: {
          get: (): Promise<QuerySnapshot<OrganizationInvite>> => {
            return getDocs(collection(organizationDoc, 'organizationInvites')) as Promise<QuerySnapshot<OrganizationInvite>>;
          },
          push: (organizationInvite: OrganizationInvite): Promise<DocumentReference<OrganizationInvite>> => {
            return addDoc(collection(organizationDoc, 'organizationInvites'), organizationInvite) as Promise<DocumentReference<OrganizationInvite>>;
          },
          delete: (organizationInviteId: string): Promise<void> => {
            return deleteDoc(doc(collection(organizationDoc, 'organizationInvites'), organizationInviteId));
          }
        },
        campaigns: {
          get: (): Promise<QuerySnapshot<Campaign>> => {
            return getDocs(collection(organizationDoc, 'campaigns')) as Promise<QuerySnapshot<Campaign>>;
          },
          push: (campaign: Campaign): Promise<DocumentReference<Campaign>> => {
            return addDoc(collection(organizationDoc, 'campaigns'), campaign) as Promise<DocumentReference<Campaign>>;
          },
          onChange: (callback: (campaignsSnapshot: QuerySnapshot<Campaign>) => void) => {
            return onSnapshot(query(collection(organizationDoc, 'campaigns') as CollectionReference<Campaign>), callback);
          },
          campaignId: (campaignId: string) => {
            let campaignDoc = doc(collection(organizationDoc, 'campaigns'), campaignId);
            return {
              get: async (): Promise<QueryDocumentSnapshot<Campaign>> => {
                return getDoc(campaignDoc) as Promise<QueryDocumentSnapshot<Campaign>>;
              },
              set: async (campaign: Campaign) => {
                return setDoc(campaignDoc, campaign);
              },
              delete: async () => {
                return deleteDoc(campaignDoc);
              },
              update: async (campaign: Partial<Campaign>) => {
                return setDoc(campaignDoc, campaign, { merge: true });
              },
              onChange: (callback: (campaignSnapshot: QueryDocumentSnapshot<Campaign>) => void) => {
                return onSnapshot(campaignDoc, (snapshot) => {
                  callback(snapshot as QueryDocumentSnapshot<Campaign>);
                });
              },
              conversations: {
                get: (): Promise<QuerySnapshot<Conversation>> => {
                  return getDocs(collection(campaignDoc, 'conversations')) as Promise<QuerySnapshot<Conversation>>;
                },
                getActive: (): Promise<QuerySnapshot<Conversation>> => {
                  return getDocs(query(collection(campaignDoc, 'conversations'), where('active', '==', true))) as Promise<QuerySnapshot<Conversation>>;
                },
                onActiveChange: (callback: (conversationsSnapshot: QuerySnapshot<Conversation>) => void) => {
                  return onSnapshot(query(collection(campaignDoc, 'conversations'), where('active', '==', true)), (snapshot) => {
                    callback(snapshot as QuerySnapshot<Conversation>);
                  });
                },
                push: (conversation: Conversation): Promise<DocumentReference<Conversation>> => {
                  return addDoc(collection(campaignDoc, 'conversations'), conversation) as Promise<DocumentReference<Conversation>>;
                },
                conversationId: (conversationId: string) => {
                  let conversationDoc = doc(collection(campaignDoc, 'conversations'), conversationId);
                  return {
                    get: async (): Promise<QueryDocumentSnapshot<Conversation>> => {
                      return getDoc(conversationDoc) as Promise<QueryDocumentSnapshot<Conversation>>;
                    },
                    set: async (conversation: Conversation) => {
                      return setDoc(conversationDoc, conversation);
                    },
                    update: async (conversation: Partial<Conversation>) => {
                      return setDoc(conversationDoc, conversation, { merge: true });
                    },
                    onChange: (callback: (conversationSnapshot: QueryDocumentSnapshot<Conversation>) => void) => {
                      return onSnapshot(conversationDoc, (snapshot) => {
                        callback(snapshot as QueryDocumentSnapshot<Conversation>);
                      });
                    },
                    systemMessages: {
                      get: (): Promise<QuerySnapshot<SystemMessage>> => {
                        return getDocs(collection(conversationDoc, 'systemMessages')) as Promise<QuerySnapshot<SystemMessage>>;
                      },
                      push: (systemMessage: SystemMessage): Promise<DocumentReference<SystemMessage>> => {
                        return addDoc(collection(conversationDoc, 'systemMessages'), systemMessage) as Promise<DocumentReference<SystemMessage>>;
                      },
                      onChange: (callback: (changes: QuerySnapshot<SystemMessage>) => void) => {
                        return onSnapshot(query(collection(conversationDoc, 'systemMessages')), (snapshot) => {
                          callback(snapshot as QuerySnapshot<SystemMessage>);
                        });
                      }
                    },
                    exchanges: {
                      get: (): Promise<QuerySnapshot<ConversationExchange>> => {
                        return getDocs(collection(conversationDoc, 'exchanges')) as Promise<QuerySnapshot<ConversationExchange>>;
                      },
                      push: (exchange: ConversationExchange): Promise<DocumentReference<ConversationExchange>> => {
                        return addDoc(collection(conversationDoc, 'exchanges'), exchange) as Promise<DocumentReference<ConversationExchange>>;
                      },
                      onChange: (callback: (changes: QuerySnapshot<ConversationExchange>) => void) => {
                        return onSnapshot(query(collection(conversationDoc, 'exchanges')), (snapshot) => {
                          callback(snapshot as QuerySnapshot<ConversationExchange>);
                        });
                      },
                      exchangeId: (exchangeId: string) => {
                        let exchangeDoc = doc(collection(conversationDoc, 'exchanges'), exchangeId);
                        return {
                          get: async (): Promise<QueryDocumentSnapshot<ConversationExchange>> => {
                            return getDoc(exchangeDoc) as Promise<QueryDocumentSnapshot<ConversationExchange>>;
                          },
                          set: async (exchange: ConversationExchange) => {
                            return setDoc(exchangeDoc, exchange);
                          },
                          update: async (exchange: Partial<ConversationExchange>) => {
                            return setDoc(exchangeDoc, exchange, { merge: true });
                          },
                          hostMessageVersionHistory: {
                            get: (): Promise<QuerySnapshot<Message>> => {
                              return getDocs(collection(exchangeDoc, 'hostMessageVersionHistory')) as Promise<QuerySnapshot<Message>>;
                            },
                            push: (message: Message): Promise<DocumentReference<Message>> => {
                              return addDoc(collection(exchangeDoc, 'hostMessageVersionHistory'), message) as Promise<DocumentReference<Message>>;
                            }
                          }
                        }
                      },
                    }
                  }
                },
              }
            }
          }
        },
        knowledgeBases: {
          get: () => {
            return getDocs(collection(organizationDoc, 'knowledgeBases')) as Promise<QuerySnapshot<KnowledgeBase>>;
          },
          push: (knowledgeBase: KnowledgeBase) => {
            return addDoc(collection(organizationDoc, 'knowledgeBases'), knowledgeBase) as Promise<DocumentReference<KnowledgeBase>>;
          },
          knowledgeBaseId: (knowledgeBaseId: string) => {
            let knowledgeDoc = doc(collection(organizationDoc, 'knowledgeBases'), knowledgeBaseId);
            return {
              get: async (): Promise<QueryDocumentSnapshot<KnowledgeBase>> => {
                return getDoc(knowledgeDoc) as Promise<QueryDocumentSnapshot<KnowledgeBase>>;
              },
              update: async (knowledgeBase: Partial<KnowledgeBase>) => {
                return setDoc(knowledgeDoc, knowledgeBase, { merge: true });
              },
              info: {
                multiPageUploadProgress: {
                  onChange: (callback: (snapshot: QueryDocumentSnapshot<MultiPageKnowledgeUploadProgress>) => void) => {
                    return onSnapshot(doc(collection(knowledgeDoc, 'info'), 'multiPageUploadProgress'), (snapshot) => {
                      callback(snapshot as QueryDocumentSnapshot<MultiPageKnowledgeUploadProgress>);
                    });
                  }
                },
                multiPageUploadProcess: {
                  onChange: (callback: (snapshot: QueryDocumentSnapshot<MultiPageKnowledgeUploadProcess>) => void) => {
                    return onSnapshot(doc(collection(knowledgeDoc, 'info'), 'multiPageUploadProcess'), (snapshot) => {
                      callback(snapshot as QueryDocumentSnapshot<MultiPageKnowledgeUploadProcess>);
                    });
                  },
                  urls: {
                    onChange: (callback: (snapshot: QuerySnapshot<ScrapedLink>) => void) => {
                      return onSnapshot(collection(doc(collection(knowledgeDoc, 'info'), 'multiPageUploadProcess'), 'urls'), (snapshot) => {
                        callback(snapshot as QuerySnapshot<ScrapedLink>);
                      });
                    },
                    bulkUpdate: async (links: { link: Partial<ScrapedLink>, id: string }[]) => {
                      return runTransaction(firestore, async (transaction) => {
                        for (let link of links) {
                          transaction.update(doc(collection(doc(collection(knowledgeDoc, 'info'), 'multiPageUploadProcess'), 'urls'), link.id), link.link);
                        }
                      });
                    }
                  },
                  faqs: {
                    onChange: (callback: (snapshot: QuerySnapshot<FAQ>) => void) => {
                      return onSnapshot(collection(doc(collection(knowledgeDoc, 'info'), 'multiPageUploadProcess'), 'faqs'), (snapshot) => {
                        callback(snapshot as QuerySnapshot<FAQ>);
                      });
                    },

                  }
                }
              },
              snippets: {
                get: () => {
                  return getDocs(collection(knowledgeDoc, 'snippets')) as Promise<QuerySnapshot<KnowledgeSnippet>>;
                },
                getWhere: (field: string, operator: WhereFilterOp, value: any) => {
                  return getDocs(query(collection(knowledgeDoc, 'snippets'), where(field, operator, value))) as Promise<QuerySnapshot<KnowledgeSnippet>>;
                },
                push: (snippet: KnowledgeSnippet) => {
                  return addDoc(collection(knowledgeDoc, 'snippets'), snippet) as Promise<DocumentReference<KnowledgeSnippet>>;
                },
                snippetId: (snippetId: string) => {
                  let snippetDoc = doc(collection(knowledgeDoc, 'snippets'), snippetId);
                  return {
                    get: async (): Promise<QueryDocumentSnapshot<KnowledgeSnippet>> => {
                      return getDoc(snippetDoc) as Promise<QueryDocumentSnapshot<KnowledgeSnippet>>;
                    },
                    set: async (snippet: KnowledgeSnippet) => {
                      return setDoc(snippetDoc, snippet);
                    },
                    update: async (snippet: Partial<KnowledgeSnippet>) => {
                      return setDoc(snippetDoc, snippet, { merge: true });
                    },
                    delete: async () => {
                      return deleteDoc(snippetDoc);
                    }
                  }
                }
              },
              removedSnippets: {
                get: () => {
                  return getDocs(collection(knowledgeDoc, 'removedSnippets')) as Promise<QuerySnapshot<KnowledgeSnippet>>;
                },
                push: (snippet: KnowledgeSnippet) => {
                  return addDoc(collection(knowledgeDoc, 'removedSnippets'), snippet) as Promise<DocumentReference<KnowledgeSnippet>>;
                },
                snippetId: (snippetId: string) => {
                  let snippetDoc = doc(collection(knowledgeDoc, 'removedSnippets'), snippetId);
                  return {
                    get: async (): Promise<QueryDocumentSnapshot<KnowledgeSnippet>> => {
                      return getDoc(snippetDoc) as Promise<QueryDocumentSnapshot<KnowledgeSnippet>>;
                    },
                    update: async (snippet: Partial<KnowledgeSnippet>) => {
                      return setDoc(snippetDoc, snippet, { merge: true });
                    },
                    delete: async () => {
                      return deleteDoc(snippetDoc);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
export type Change<T> = { type: string, doc: QueryDocumentSnapshot<T> } | DocumentChange<T>;
export type HandlerType = "exchange" | "conversation" | "campaign" | "organization" | "systemMessage";
export type StreamEvent = {
  subscriptions: (Unsubscribe | null)[],
  change: Change<Organization>;
  children: Change<Organization>[];
  handlerType: "organization";
} | {
  subscriptions: (Unsubscribe | null)[],
  change: Change<Campaign>;
  children: Change<Campaign>[];
  handlerType: "campaign";
} | {
  subscriptions: (Unsubscribe | null)[],
  change: Change<Conversation>;
  children: Change<Conversation>[];
  handlerType: "conversation";
} | {
  subscriptions: (Unsubscribe | null)[],
  change: Change<ConversationExchange>;
  children: Change<ConversationExchange>[];
  handlerType: "exchange";
} | {
  subscriptions: (Unsubscribe | null)[],
  change: Change<SystemMessage>;
  children: Change<SystemMessage>[];
  handlerType: "systemMessage";
};
export class OrganizationListener {
  // handlers: { [handlerType: string]: { [id: string]: (change: Change<any>) => void } } = {
  //   "exchange": {},
  //   "conversation": {},
  //   "campaign": {},
  //   "organization": {},
  // };
  subscriptions: { [name: string]: (change: StreamEvent) => void } = {};
  // exchangeChangeHandlers: { [id: string]: (change: Change<ConversationExchange>) => void } = {};
  // conversationChangeHandlers: { [id: string]: (change: Change<Conversation>) => void } = {};
  // campaignChangeHandlers: { [id: string]: (change: Change<Campaign>) => void } = {};
  // organizationChangeHandlers: { [id: string]: (change: Change<Organization>) => void } = {};
  db: DB;
  history: {
    [id: string]: StreamEvent
  } = {};
  organizationSubscription: Unsubscribe | null = null;

  constructor(db: DB) {
    this.db = db;
    this.init();
  }
  // removeHandler(name: string, handlerType: HandlerType) {
  //   delete this.handlers[handlerType][name];
  // }
  // addHandler(name: string, handlerType: HandlerType, handler: (change: Change<any>) => void) {
  //   this.handlers[handlerType][name] = handler;
  //   for (let key of Object.keys(this.listeners)) {
  //     if (this.listeners[key].handlerType === handlerType) {
  //       handler(this.listeners[key].change);
  //     }
  //   }
  // }
  addSubscription(name: string, handler: (event: StreamEvent) => void) {
    this.subscriptions[name] = handler;
    for (let event of Object.values(this.history)) {
      handler(event);
    }
  }
  unsubscribeAll() {
    this.organizationSubscription && this.organizationSubscription();
    if (this.db.organizationId) {
      this.unsubscribe(this.db.organizationId);
    }
  }
  unsubscribe(id: string) {
    if (this.history[id]) {
      this.history[id].subscriptions.forEach(unsubscribe => unsubscribe && unsubscribe());
      this.history[id].change = { type: 'removed', doc: this.history[id].change.doc } as Change<any>;
      Object.values(this.subscriptions).forEach(handler => handler(this.history[id]));
      this.history[id].children.forEach(child => {
        this.unsubscribe(child.doc.id);
      });
      delete this.history[id];
    }
  }
  handleChange(handlerType: HandlerType, change: Change<any>, parentId?: string) {
    //console.log('handlerType: ' + handlerType, "changeType: " + change.type);
    // console.log("data: " + JSON.stringify(change.doc.data(), null, 2));
    if (change.type === 'added') {
      parentId && this.history[parentId].children.push(change);
      this.history[change.doc.id] = {
        subscriptions: [],
        children: [],
        change: change,
        handlerType: handlerType,
      }
      Object.values(this.subscriptions).forEach(handler => handler(this.history[change.doc.id]));
    } else if (change.type === 'removed') {
      this.unsubscribe(change.doc.id);
    } else if (change.type === 'modified') {
      this.history[change.doc.id].change = change;
      Object.values(this.subscriptions).forEach(handler => handler(this.history[change.doc.id]));
    } else {
      console.error('unknown change type', change);
    }
    // console.log("listeners: " + JSON.stringify(Object.values(this.history).map(value => value.change.doc.data()), null, 2))
  }
  init() {
    console.log("init listeners")
    this.organizationSubscription = this.db.organizations.organizationId()
      .onChange((organizationSnapshot: QueryDocumentSnapshot<Organization>) => {
        let organizationId = organizationSnapshot.id;
        let type = organizationSnapshot.exists()
          ? !!this.history[organizationId]
            ? 'modified'
            : 'added'
          : 'removed';
        this.handleChange(/*handlerType*/ 'organization', { type: type, doc: organizationSnapshot });
        if (type !== 'added') {
          return;
        }
        this.history[organizationId].subscriptions.unshift(null);
        this.history[organizationId].subscriptions[0] = this.db.organizations.organizationId().campaigns
          .onChange((campaignsSnapshot: QuerySnapshot<Campaign>) => {
            campaignsSnapshot.docChanges().forEach((campaignChange) => {
              this.handleChange(/*handlerType*/ 'campaign', campaignChange, organizationId);
              if (campaignChange.type !== 'added') {
                return;
              }
              let campaignId = campaignChange.doc.id;
              this.history[campaignId].subscriptions.unshift(null);
              this.history[campaignId].subscriptions[0] = this.db.organizations.organizationId().campaigns.campaignId(campaignChange.doc.id).conversations
                .onActiveChange((conversationsSnapshot: QuerySnapshot<Conversation>) => {
                  conversationsSnapshot.docChanges().forEach((conversationChange) => {
                    this.handleChange(/*handlerType*/ 'conversation', conversationChange, campaignId);
                    if (conversationChange.type !== 'added') {
                      return;
                    }
                    let conversationId = conversationChange.doc.id;
                    this.history[conversationChange.doc.id].subscriptions.unshift(null);
                    // 
                    this.history[conversationChange.doc.id].subscriptions[0] = this.db.organizations.organizationId().campaigns.campaignId(campaignChange.doc.id).conversations.conversationId(conversationChange.doc.id).exchanges
                      .onChange((exchangesSnapshot: QuerySnapshot<ConversationExchange>) => {
                        exchangesSnapshot.docChanges().forEach((exchangeChange) => {
                          this.handleChange(/*handlerType*/ 'exchange', exchangeChange, conversationId);
                        });
                      });

                    this.history[conversationChange.doc.id].subscriptions.unshift(null);
                    this.history[conversationChange.doc.id].subscriptions[0] = this.db.organizations.organizationId().campaigns.campaignId(campaignChange.doc.id).conversations.conversationId(conversationChange.doc.id).systemMessages
                      .onChange((systemMessagesSnapshot: QuerySnapshot<SystemMessage>) => {
                        systemMessagesSnapshot.docChanges().forEach((systemMessageChange) => {
                          this.handleChange(/*handlerType*/ 'systemMessage', systemMessageChange, conversationId);
                        });
                      });
                  });
                });
            });
          });
      });
  }
}


export const webDB = (organizationId: string, campaignId: string) => new DB().organizations.organizationId(organizationId).campaigns.campaignId(campaignId);