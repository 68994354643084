import React from 'react';
import { useState, useEffect, useRef, useCallback } from "react";
import { signInWithGoogle, signOutWithGoogle } from "../lib/firebase-config";
import { User } from "firebase/auth";
import ConversationManager from "./Conversations";
import AccountScreen from "./Account";
import { useAppContext } from "../lib/context-prod";
import { Context } from "../lib/context";
import { Loading } from "./common/Loading";
import CampaignsScreen from './Campaigns';
import BotTrainingScreen from './BotTraining';
import BotModulesScreen from './BotModules';

import { AiFillHome, AiTwotoneSetting } from "react-icons/ai";
import { MdCampaign } from "react-icons/md";
import { HiChatBubbleLeftRight } from "react-icons/hi2";
import { FaGraduationCap, FaSignOutAlt } from "react-icons/fa";
import { AiOutlineApartment } from "react-icons/ai";
import { SiProbot } from "react-icons/si";
import { Onboarding } from './Onboarding';
import { PrimaryButton } from './common/ComponentsLib';

export function ErrorLog(props: { errors: string[], style?: React.CSSProperties }) {
  const logRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const logElement = logRef.current;
    if (logElement) {
      logElement.scrollTop = logElement.scrollHeight;
    }
  }, [props.errors]);
  return (
    <div
      ref={logRef}
      style={
        { width: '400px', height: '200px', overflow: 'auto', ...props.style }
      }
    >
      {props.errors.map((error, index) => {
        return <p key={index}>{error}</p>
      })}
    </div>
  );
}

function Home(props: { organizationId: string }) {
  const [loading, setLoading] = useState(true);
  const [organizationId, setOrganizationId] = useState<string | null>(null);
  // const context = useAppContext();
  useEffect(() => {
    if (props.organizationId) {
      console.log("Organization id: " + props.organizationId);
      setOrganizationId(props.organizationId);
      setLoading(false);
    } else {
      console.log("No organization id.");
    }
  }, []);
  if (loading) {
    return <div style={{ padding: '40px', boxSizing: 'border-box' }}></div>
  }
  return <Onboarding organizationId={props.organizationId} />
}

export default function Main() {
  const [user, setUser] = useState<User | null>(null);
  const [organizationId, setOrganizationId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const context: Context = useAppContext();

  useEffect(() => {
    const unsubscribe = context.init(async (user_, organizationId_) => {
      setUser(user_);
      if (user_ && organizationId_) {
        setOrganizationId(organizationId_);
        setLoading(false);
      } else {
        console.log("No user or organization id.");
      }
    });
    return unsubscribe;
  }, []);

  if (!user) {
    return <SignInPage
      setUser={(user) => {
        setUser(user);
      }}
      setLoading={setLoading}
    />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, height: '100vh', boxSizing: 'border-box' }}>
      <Sidebar
        menuItems={[
          { label: <div><AiFillHome style={{ marginBottom: "-1.4px" }} /><span style={{ paddingLeft: '10px' }}>Home</span></div>, component: (<Home organizationId={organizationId} />) },
          { label: <div><MdCampaign style={{ marginBottom: "-1.4px" }} /><span style={{ paddingLeft: '10px' }}>Bots</span></div>, component: (<CampaignsScreen />) },
          { label: <div><HiChatBubbleLeftRight style={{ marginBottom: "-1.4px" }} /><span style={{ paddingLeft: '10px' }}>Conversations</span></div>, component: (<ConversationManager />) },
          { label: <div><AiOutlineApartment style={{ marginBottom: "-1.4px" }} /><span style={{ paddingLeft: '10px' }}>Knowledge</span></div>, component: (<BotModulesScreen />) },
          { label: <div><FaGraduationCap style={{ marginBottom: "-1.4px" }} /><span style={{ paddingLeft: '10px' }}>Bot Training</span></div>, component: (<BotTrainingScreen />) },
          { label: <div><AiTwotoneSetting style={{ marginBottom: "-1.4px" }} /><span style={{ paddingLeft: '10px' }}>Account</span></div>, component: (<AccountScreen />) },
          { label: <div><FaSignOutAlt style={{ marginBottom: "-1.4px" }} /><span style={{ paddingLeft: '10px' }}>Sign out</span></div>, component: (<div>Sign out</div>), onClick: () => signOutWithGoogle() }
        ].filter((_, index) => {
          return ((new URLSearchParams(window.location.search).has('dev')) || [0, 1, 2, 3, 6].includes(index));
        })}
        bottomAlignIndex={4} // indices equal to this or greater will be aligned to the bottom of the sidebar
      />
    </div>
  );
}

function SignInPage(props: { setUser: (user: User | null) => void, setLoading: (loading: boolean) => void }) {
  const [errors, setErrors] = useState<string[]>([]);
  const ref = useRef<HTMLDivElement>(null);
  const [narrow, setNarrow] = useState(false);
  const setLoading = useCallback((loading: boolean) => { props.setLoading && props.setLoading(loading); }, []);
  let timeout = useRef(null);
  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        if (entry.contentRect.width < 800) {
          setNarrow(true);
        } else {
          setNarrow(false);
        }
      }
    });
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, []);
  useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    if (errors.length === 0) {
      return;
    }
    timeout.current = setTimeout(() => {
      let errors_ = [...errors];
      setErrors(errors_.slice(1, errors_.length));
    }, 5000);
  }, [errors])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: narrow ? 'column' : 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: narrow ? 'auto' : '100vh',
        width: '100vw',
      }}
      ref={ref}
    >
      <div style={{
        width: narrow ? '100%' : '50%', height: narrow ? 'auto' : '100%', display: 'flex', justifyContent: narrow ? 'flex-start' : 'center', alignItems: 'center',
        backgroundColor: 'rgb(230, 230, 255)',
        flexDirection: 'column',
        padding: '40px',
        boxSizing: 'border-box',
        color: 'rgb(7, 13, 72)'
      }}>
        <img src="./fulllogo_transparent.png" style={{ width: '300px', margin: "0px 0px -20px -100px" }}></img>
        {/* <h1 style={{ margin: '40px' }}>Our Bot</h1> */}

        <h3 style={{ textAlign: 'left', width: '400px', margin: '10px' }}>Automate sales with our chatbot platform.</h3>
        <h3 style={{ textAlign: 'left', width: '400px', margin: '10px' }}>Give leads immediate attention.</h3>
        <h3 style={{ textAlign: 'left', width: '400px', margin: '10px 10px 30px 10px' }}>Captures your team's skills in an AI.</h3>

      </div>
      <div style={{
        width: narrow ? '100%' : '50%', height: narrow ? 'auto' : '100%',
        display: 'flex', justifyContent: 'center', alignItems: 'center',
        flexDirection: 'column',
        padding: '40px',
        boxSizing: 'border-box'
      }}>
        {/* <ErrorLog errors={errors} /> */}
        {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}> */}

        <div style={{ width: '100%', fontSize: '60px', display: 'flex', justifyContent: 'center', marginBottom: '50px' }} >
          {/* <SiProbot /> */}
          {/* <img src="./logo128.png" width="80px" height="80px" /> */}


          {/* <span style={{ marginLeft: '5px' }}>Our Bot Builder</span> */}
        </div>
        <h2 style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', color: 'rgb(7, 13, 72)' }}>
          {/* <img src="./logo128.png" width="3px" height="30px" style={{ margin: "-10px 8px 0px 0px" }} /> */}
          <span>Sign in to R Bot</span></h2>

        <PrimaryButton
          style={{ height: '50px', width: '200px', borderRadius: '0px' }}
          onClick={async () => {
            await signInWithGoogle()
              .then(user => {
                setLoading(true);
                props.setUser(user)
              })
              .catch(err => {
                setErrors([...errors, "There was an error signing in with Google."]);
                console.error(err);
              })
          }
          }>Continue with Google</PrimaryButton></div>
      {/* </div> */}


    </div>)
}

function MenuItem(props: { label: string | JSX.Element, selected: boolean, onClick: () => void }) {
  const styles: React.CSSProperties = {
    display: 'flex',
    // paddingTop: '8px',
    // paddingBottom: '8px',
    // paddingLeft: '16px',
    // paddingRight: '24px',
    padding: '10px 24px 10px 16px',
    color: props.selected ? 'white' : 'black',
    backgroundColor: props.selected ? '#5555ff' : 'transparent',
    cursor: 'pointer',
    // margin: '4px',
    boxShadow: props.selected ? '2px 2px 6px rgba(0, 0, 0, 0.2)' : 'none',
    alignItems: 'bottom',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  };

  return (
    <div style={styles} onClick={props.onClick}>
      {props.label}
    </div>
  );
}

function Sidebar(props: {
  menuItems: Array<{
    label: string | JSX.Element,
    component: JSX.Element,
    onClick?: () => void
  }>,
  bottomAlignIndex?: number
}) {
  const [selectedItem, setSelectedItem] = useState(props.menuItems[0].component);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const handleSelect = (index: number) => {
    setSelectedIndex(index);
    props.menuItems[index].onClick && props.menuItems[index].onClick();
    setSelectedItem(props.menuItems[index].component);
  };

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column' as 'column',
    backgroundColor: '#e6e6ff',
    paddingTop: '8px',
  };

  const bottomAlignIndex = props.bottomAlignIndex ? props.bottomAlignIndex : props.menuItems.length;

  return (
    <div style={{ display: 'flex', flexDirection: 'row' as 'row', height: '100%',/* margin: '10px',*/ borderRadius: '30px' }}>
      <div style={containerStyle}>
        <div style={{ width: '100%', fontSize: '50px', color: 'black', display: 'flex', justifyContent: 'center', paddingTop: '25px', paddingBottom: '40px' }} >
          {/* <SiProbot /> */}
          <img src="./textlogo_small.png" width="80px" />
          {/* <img src="./logo128.png" width="80px" height="80px" /> */}
          {/* <span style={{ marginLeft: '5px' }}>Our Bot Builder</span> */}
        </div>
        {/* <div style={{ marginLeft: '20px', marginRight: '20px', marginTop: '20px', marginBottom: '20px' }}>
          <img width={150} src='https://my.acornfinance.com/acorn-finance-logo-180x54.png' /> */}
        {/* <img src='https://my.acornfinance.com/app-logo-128x128.png' /> */}
        {/* <img src='https://my.acornfinance.com/icons/favicon-32x32.png' /> */}
        {/* </div> */}
        {props.menuItems.slice(0, bottomAlignIndex).map((item, index) => (
          <MenuItem
            key={index}
            label={item.label}
            selected={selectedIndex === index}
            onClick={() => handleSelect(index)}
          />
        ))}
        <div style={{ flexGrow: 1 }} />
        {props.menuItems.slice(bottomAlignIndex).map((item, index) => (
          <MenuItem
            key={index + bottomAlignIndex}
            label={item.label}
            selected={selectedIndex === index + bottomAlignIndex}
            onClick={() => handleSelect(index + bottomAlignIndex)}
          />
        ))}

        <div style={{ flexGrow: 0.1 }} />
      </div>
      <div style={{ display: 'flex', flex: 1, backgroundColor: 'white' }}>{selectedItem}</div>
    </div>
  );
}

