import React from 'react';
// import logo from './logo.svg';
// var logo = require('./public/logo32.png')
import Main from './components/Main';
import './App.css';

function App() {
  return (<Main />);
}

export default App;
