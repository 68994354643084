import { useState, useEffect, useRef, useCallback } from 'react';
import { useAppContext } from '../lib/context-prod';
import { Campaign, KnowledgeSnippet, OnboardingChecklist, Organization } from '../lib/common-types';
import { QueryDocumentSnapshot, QuerySnapshot } from 'firebase/firestore';
import { ExpandingTextArea, LabelInput, LabelTextArea, MultiInput, PrimaryButton, SecondaryButton } from './common/ComponentsLib';
import { Modal } from './common/Modal';
import { ChatCompletionMessage, createChatCompletion } from "../lib/firebase-config";
import { Loading } from './common/Loading';
import { MultiPageKnowledgeScraping } from './common/KnowledgeBaseLib';

const DEFAULT_ORGANIZATION_NAME = "not specified";
const DEFAULT_ORGANIZATION_DESCRIPTION = "not specified";

function NewOrganizationModal(props: { organization?: Organization, visible: boolean, organizationId: string, onClose: () => void }) {
  const [visible, setVisible] = useState<boolean>(props.visible);
  const [saving, setSaving] = useState<boolean>(false);
  const context = useAppContext();
  const [name, setName] = useState(props.organization ? props.organization.name : "");
  const [description, setDescription] = useState(props.organization ? props.organization.description : "");
  let onClose = useCallback(() => {
    props.onClose();
  }, [props.onClose]);
  useEffect(() => {
    console.log("NewOrganizationModal visible changed: ", props.visible);
    setVisible(props.visible);
  }, [props.visible])
  return <Modal
    onClose={onClose}
    visible={visible}
    innerStyle={{
      width: '90%',
      height: '90%',
      maxWidth: '550px',
      maxHeight: '430px',
      padding: '50px',
      borderRadius: '10px',
    }}
    hideCloseButton={true}
  >
    <div>
      <div style={{ padding: '10px', overflowY: 'scroll', height: '320px' }}>
        <div style={{ textAlign: 'center', fontSize: '1.7rem', fontWeight: 'bold' }}>Welcome!</div>
        <div style={{ fontSize: '1.3rem', textAlign: 'center', marginBottom: '40px' }}>Let's create your first bot</div>
        {/* <div style={{ color: "rgb(77, 77, 77)", paddingBottom: '30px', fontSize: '0.95rem' }}>
          To get your chatbot started, simply provide the name of your organization, and what it does.</div> */}

        <LabelInput
          label={"What organization is the bot representing?"}
          value={name}
          placeholder={"e.g. \"Our Bot Inc.\""}
          onChange={(e) => {
            if (e.target.value.length > 70) {
              setName(e.target.value.substring(0, 70));
              alert("You've gone over the character limit.")
            } else {
              setName(e.target.value);
            }
          }}
          inputStyle={{ width: '100%', padding: '5px', boxSizing: 'border-box' }}
        />

        <LabelTextArea
          style={{ marginTop: '30px' }}
          inputStyle={{ boxSizing: 'border-box', maxHeight: '150px', padding: '5px', }}

          label={"What does your organization do? "}
          value={description}
          placeholder={"e.g. \"We let sales teams make AI chatbots that talk like them. Their bots help them handle more leads and give leads immediate attention.\""}
          onChange={(e) => {
            if (e.target.value.length > 450) {
              setDescription(e.target.value.substring(450));
              alert("You've reached the character limit. ");
            } else {
              setDescription(e.target.value);
            }
          }}
        />
      </div>

      <div style={{ display: 'flex', flexDirection: "row", marginTop: '20px', justifyContent: 'center' }}>
        <PrimaryButton
          disabled={saving}
          style={{ width: '100px', margin: '10px' }}
          onClick={async () => {
            setSaving(true);
            if (name.length === 0 || description.length === 0) {
              alert("You must fill out this form in order to continue. The bot will only function well if it has the right information.")
              return;
            }
            await context.db.organizations.organizationId(props.organizationId).update({ name: name, description: description });
            await context.db.organizations.organizationId().info.onboarding.update({ basicInfo: true });
            setSaving(false);
            onClose();
          }}
        >Save</PrimaryButton>
        {/* <SecondaryButton
          style={{ margin: '10px' }}
          onClick={async () => {
            await context.db.organizations.organizationId().info.onboarding.update({ basicInfo: true });
            // await context.db.organizations.organizationId(props.organizationId).update({ name: DEFAULT_ORGANIZATION_NAME, description: DEFAULT_ORGANIZATION_DESCRIPTION });
            onClose();
          }}
        >I'm joining another organization</SecondaryButton> */}
      </div>
    </div>
  </Modal>;
}

function QuestionsPrompt(description: string, n: number = 10): ChatCompletionMessage[] {
  return [{
    role: 'system',
    content: 'What are some customer questions not answered by the passage below that are clarifying questions, or more details that could asked about the topic in the passage below? Separate them by newlines. Format the output as a JSON array of strings.',
  },
  {
    role: 'user',
    content: `3 questions.
    Sea turtles are endangered reptiles that play a crucial role in maintaining the health of marine ecosystems. They have unique features and are adapted to swim long distances in the ocean. It's important to work towards their conservation and protection.`
  },
  {
    role: 'assistant',
    content: `["What are the main threats to sea turtles that contribute to their endangered status?",
  "What are some conservation efforts currently being undertaken to protect sea turtles?",
  "How can individuals contribute to the conservation of sea turtles?"]`
  },
  {
    role: 'user',
    content: n + " questions.\n" + description
  }];
}

function NewOrganizationQuestionsModal(props: { visible: boolean, organizationId: string, onClose: () => void }) {
  const [visible, setVisible] = useState<boolean>(props.visible);
  const [questions, setQuestions] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [asked, setAsked] = useState<boolean>(false);
  const context = useAppContext();
  let onClose = useCallback(() => {
    props.onClose();
  }, [props.onClose]);
  useEffect(() => {
    console.log("NewOrganizationModal visible changed: ", props.visible);
    setVisible(props.visible);
    if (asked && props.visible && questions.length === 0) {

      (async () => {
        setLoading(true);
        let organization = await context.db.organizations.organizationId(props.organizationId).get();
        for (let tries = 1; tries <= 3; tries++) {
          try {
            let response = await createChatCompletion({
              model: 'gpt-3.5-turbo-0301',
              messages: QuestionsPrompt(organization.data().description, 10)
            });
            // setQuestions(response.data.content.split("\n"));
            let questions_ = JSON.parse(response.data.content);
            setQuestions(questions_);
            setLoading(false);
          } catch (e) {
            if (tries === 3) {
              alert("Failed to load questions. Refresh your browser to try again.");
            }
          }
        }
      })();
    }
  }, [props.visible, asked]);

  let innerStyle = {
    width: '90%',
    height: '90%',
    maxWidth: '550px',
    maxHeight: '430px',
    padding: '20px',
  };
  if (loading) {
    return <Modal
      onClose={onClose}
      visible={visible}
      innerStyle={innerStyle}
    ><Loading /></Modal>;
  }

  if (!asked) {
    return <Modal
      onClose={onClose}
      visible={visible}
      innerStyle={innerStyle}
    >
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>

        <p>You'll need to create a knowledge base of facts about your business. The bot will use these to answer questions from users chatting with your bot.</p>
        <p>Would you like us to generate some questions that might be frequently asked about your business, product or service?</p>

        <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          <PrimaryButton
            disabled={saving}
            style={{ margin: '10px', width: '120px' }}
            onClick={
              async () => {
                setAsked(true);
              }
            }
          >
            Yes
          </PrimaryButton>
          <SecondaryButton
            style={{ margin: '10px', width: '120px' }}
            onClick={() => {
              onClose()
            }}
          >
            No
          </SecondaryButton>
        </div>
      </div>
    </Modal>
  }

  return <Modal
    onClose={onClose}
    visible={visible}
    innerStyle={innerStyle}
  >
    <h2>Which questions are relevant to you?</h2>
    <p>These will go into your bot setup queue. Edit any mistakes out and remove unwanted questions</p>
    <div style={{ overflowY: 'scroll', height: '200px', padding: '15px', border: 'solid 1px lightgrey' }}>
      <MultiInput
        values={questions}
        onChange={(values) => { setQuestions(values) }}
      />
    </div>
    <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', marginTop: '25px' }}>
      <PrimaryButton
        disabled={saving}
        style={{ margin: '10px', width: '120px' }}
        onClick={
          async () => {
            setSaving(true);
            let promises = [];
            for (let question of questions) {
              promises.push(context.db.organizations.organizationId().knowledgeBases.knowledgeBaseId('default').snippets.push({
                questions: [question],
                answer: { text: '', created: new Date().getTime() },
                created: new Date().getTime(),
                status: 'needs-answer',
                sourceInfo: {
                  source: 'onboarding',
                }
              }));
            }
            await Promise.all(promises);
            await context.db.organizations.organizationId().info.onboarding.update({ knowledgeBaseQuestions: true });
            setSaving(false);
            onClose();
          }
        }
      >
        Add to Queue
      </PrimaryButton>
      <SecondaryButton
        style={{ margin: '10px', width: '120px' }}
        onClick={onClose}
      >
        Skip
      </SecondaryButton>
    </div>
  </Modal >
}

export function Onboarding(props: { organizationId: string }) {
  const [organization, setOrganization] = useState<QueryDocumentSnapshot<Organization>>(null);
  const [onboarding, setOnboarding] = useState<OnboardingChecklist>(null);
  // const [snippets, setSnippets] = useState<QuerySnapshot<KnowledgeSnippet>>(null);
  const [loading, setLoading] = useState(true);
  // const [newOrgModalVisible, setNewOrgModalVisible] = useState(false);
  // const [questionsModalVisible, setQuestionsModalVisible] = useState(false);
  // const [multipageKnowledgeScrapingVisible, setMultipageKnowledgeScrapingVisible] = useState(false);
  const [visible, setVisible] = useState<'none' | 'new-org' | 'questions' | 'multipage-scraping'>('none');

  const [dev, setDev] = useState<boolean>(new URLSearchParams(window.location.search).has('dev'));
  const context = useAppContext();

  let Load = async (orgId: string) => {
    setLoading(true);
    let onboarding_: QueryDocumentSnapshot<OnboardingChecklist> = (await context.db.organizations.organizationId(orgId).info.onboarding.get()) as QueryDocumentSnapshot<OnboardingChecklist>;

    let organization_ = await context.db.organizations.organizationId(orgId).get();

    // JSON.stringify("org: " + JSON.stringify(response.data()))
    if (organization_.exists()) {
      console.log("onboarding: ");
      if (onboarding_.exists()) {
        setOnboarding(onboarding_.data());
      } else {
        setOnboarding({});
      }
      setOrganization(organization_);
      setLoading(false);
      // setOrganization(response);
      // let snippets_ = await context.db.organizations.organizationId(orgId).knowledgeBases.knowledgeBaseId('default').snippets.get();
      // setSnippets(snippets_);
    } else {
      throw "Error, could not get organization.";
    }
  }
  useEffect(() => {
    if (props.organizationId) {
      Load(props.organizationId);
    } else {
      setOnboarding(null);
    }
  }, [props.organizationId]);

  useEffect(() => {
    if (onboarding) {
      if (!onboarding.multiPageKnowledgeScraping) {
        setVisible('multipage-scraping');
        // setMultipageKnowledgeScrapingVisible(true);
      } else if (!onboarding.basicInfo) {
        setVisible('new-org');
        // setNewOrgModalVisible(true);
        // setQuestionsModalVisible(false);
      } else if (!onboarding.knowledgeBaseQuestions && dev) {
        setVisible('questions');
        // setNewOrgModalVisible(false);
        // setQuestionsModalVisible(true && dev);
      } else {
        setVisible('none');
      }
    }
  }, [onboarding])

  if (loading) {
    return <></>;
  }


  return <>
    <NewOrganizationModal
      organization={organization.data()}
      visible={visible === 'new-org'}
      organizationId={props.organizationId}
      onClose={async () => {
        await Load(props.organizationId);
        if (organization.data().name === DEFAULT_ORGANIZATION_NAME) {
          return;
        }
        let campaign: Campaign = null;
        // it takes about 15 seconds for the default campaign to be created, so retry if it is not there yet.
        for (let i = 1; i <= 5; i++) {
          let response = await context.db.organizations.organizationId(props.organizationId).campaigns.campaignId('default').get();
          if (response.exists()) {
            campaign = response.data();
            break;
          } else {
            await new Promise(res => { setTimeout(() => { }, 5000) });
          }
        }
        if (!campaign) {
          throw "Error, could not get default campaign.";
        }
        campaign.bots[0].promptSettings.modules['static-knowledge'] = {
          staticKnowledge: `${organization.data().name} \n${organization.data().description} `
        };
        context.db.organizations.organizationId(props.organizationId).campaigns.campaignId('default').set(campaign)
      }} />
    <NewOrganizationQuestionsModal
      organizationId={props.organizationId}
      visible={visible === 'questions'}
      onClose={() => {
        setVisible('none');
      }}
    />
    <MultiPageKnowledgeScraping
      knowledgeBaseId={"default"}
      visible={visible === 'multipage-scraping' && dev}
      onClose={() => {
        setVisible('none');
      }}
    />
  </>;
}

