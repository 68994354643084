
import { User } from "firebase/auth";
import { firestore, FBAuth, DB, OrganizationListener } from "./firebase-config";
import { AppUser, ConversationExchange, Campaign, Conversation } from "./common-types";
import { DocumentChange, QueryDocumentSnapshot } from "firebase/firestore";
import { yearMonthDayPST } from "./datetime";

export class Context {
  db: DB = new DB(undefined, undefined);
  fb_auth: FBAuth;
  user: User;
  appUser: AppUser;
  organizationListener: OrganizationListener;
  constructor(fb_auth: FBAuth) {
    this.fb_auth = fb_auth;
  }
  init(callback: (user: User | null, organizationId: string | null) => void) {
    return this.fb_auth.initFirebaseAuth(async (user_: User | null) => {
      console.log("init firebase");
      let date = new Date();
      let dateString = yearMonthDayPST(date);
      if (this.user && !user_) {
        // user logged out.
        console.log('user logged out');
        callback(null, null);
        this.db.logs.console.yearMonthDay(dateString).push({ type: 'logged-out', created: date.getTime(), userId: this.user.uid });
        this.setUserId(undefined);
        this.setOrganizationId(undefined);
        this.appUser = null;
      } else if (user_) {
        // user logged in.
        console.log('user logged in');
        await this.db.users.userId(user_.uid).get().then(async (doc) => {
          if (doc.exists()) {
            // returning user
            console.log('returning user')
            this.db.logs.console.yearMonthDay(dateString).push({ type: 'logged-in', created: date.getTime(), userId: user_.uid });
            this.appUser = doc.data() as AppUser;
            this.setUserId(user_.uid);
            this.setOrganizationId(this.appUser.defaultOrganizationId);
            callback(user_, this.appUser.defaultOrganizationId);
            this.user = user_;
          } else {
            // new user.
            console.log('new user');
            await this.db.organizations.push({
              name: "", description: "", type: 'personal', plan: 'free', created: new Date().getTime(),
              owners: [user_.uid]
            })
              .then(async (orgRef) => {
                // there is a firebase function which adds the user as the organization owner.
                if (!orgRef) {
                  throw "orgDoc is null";
                }
                this.setUserId(user_.uid);
                this.setOrganizationId(orgRef.id);
                console.log("new organization created: ", orgRef.id);
                this.appUser = {
                  email: user_.email,
                  created: new Date().getTime(),
                  defaultOrganizationId: orgRef.id,
                  organizationIds: [orgRef.id]
                }
                await this.db.users.userId(user_.uid).set(this.appUser);
                callback(user_, orgRef.id);
                this.user = user_;
                console.log("new user created");
              }).catch((err) => {
                throw "error creating organization: " + JSON.stringify(err);
              });
            await this.db.logs.console.yearMonthDay(dateString).push({ type: 'account-created', created: date.getTime(), userId: user_.uid });
            console.log("logged account created");
          }

        }).catch((err) => {
          // TODO: improve error handling
          console.log(JSON.stringify(err));
        })
      }
    });
  }

  setUserId(userId: string) {
    this.db.userId = userId;
  }

  setOrganizationId(organizationId: string | null) {
    if (!organizationId) {
      this.organizationListener.unsubscribeAll();
      this.organizationListener = null;
      return;
    }
    if (!this.db) {
      throw "db is null";
    }
    if (!this.db.userId) {
      // TODO: should we log this?
      throw "userId is null";
    }
    if (this.organizationListener) {
      this.organizationListener.unsubscribeAll();
    }
    this.db.organizationId = organizationId;

    this.organizationListener = new OrganizationListener(this.db);

    this.organizationListener.init();
  }

}

