import React, { useCallback } from 'react';
import { useState, useEffect, useRef } from "react";
// import close button
import { AiOutlineClose } from "react-icons/ai";

export function Modal(props: {
  children?: any,
  onClose?: () => void,
  visible?: boolean,
  innerStyle?: React.CSSProperties,
  outerStyle?: React.CSSProperties,
  closedContent?: JSX.Element,
  buttonStyle?: React.CSSProperties,
  outerBackgroundColor?: string,
  hideCloseButton?: boolean
}) {
  const [visible, setVisible] = useState(props.visible ? true : false);
  // const [children, setChildren] = useState(props.children);
  const onClose = useCallback(() => {
    if (props.onClose) {
      props.onClose();
    } else {
      console.log("modal onclose called");
      setVisible(false);
    }
  }, []);
  const [buttonStyle, setButtonStyle] = useState(props.buttonStyle ? props.buttonStyle : {});
  const [closedContent, setClosedContent] = useState(props.closedContent ? props.closedContent : null);

  // useEffect(() => {
  //   console.log("Modal visibility changed: ", props.visible)

  //   setButtonStyle(props.buttonStyle);
  //   setClosedContent(props.closedContent);
  // }, [props.visible, props.children, props.onClose, props.closedContent]);


  useEffect(() => {
    console.log("Modal visibility changed: ", props.visible, visible)
    setVisible(props.visible);
  }, [props.visible]);

  useEffect(() => {
    console.log("modal visibility set", visible)
  }, [visible]);

  useEffect(() => {
    console.log("Modal children changed");
    // setChildren(props.children);
  }, [props.children]);

  useEffect(() => {
    console.log("Modal button changed");
    setButtonStyle(props.buttonStyle);
  }, [props.buttonStyle]);

  useEffect(() => {
    console.log("Modal closedContent changed")
    setClosedContent(props.closedContent);
  }, [props.closedContent]);


  if (!visible && closedContent) {
    return <div
      style={{
        display: 'inline-block',
        padding: '10px',
        cursor: 'pointer',
        borderRadius: '10px',
        ...(buttonStyle ? buttonStyle : {})
      }}
      onClick={() => setVisible(true)}>
      {closedContent}
    </div>;
  }


  if (!visible) {
    return <></>;
  }

  return (
    <div style={{
      display: 'flex',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: props.outerBackgroundColor ? props.outerBackgroundColor : 'rgba(0, 0, 0, 0.5)',
      ...props.outerStyle
    }}
      onClick={() => {
        onClose();
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '90%',
          height: '90%',
          backgroundColor: 'white',
          flexDirection: 'column',
          position: 'relative',
          ...props.innerStyle
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <span hidden={true}>visible: {visible}</span>
        {!props.hideCloseButton && <div style={{
          display: 'flex', position: 'absolute', top: 0, right: 0, padding: '10px'
        }}
        >
          <span style={{ cursor: 'pointer' }}
            onClick={() => {
              onClose();
              console.log("Modal closed.")
            }}>
            <AiOutlineClose />
          </span>
        </div>}
        {props.children}
      </div>
    </div >
  );
}