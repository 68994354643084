import { Context } from "./context";
import { createEmbedding } from "./firebase-config";
import { KnowledgeSnippet, TEXT_EMBEDDING_MODEL } from "./common-types";

export async function getSingleEmbedding(text, context) {
  let embeddings = await getManyEmbeddings([text], context);
  return embeddings[0];
}
export async function getManyEmbeddings(texts: string[], context: Context) {
  let data = await Promise.all(texts.map(async (text, textIndex) => {
    let response = await context.db.organizations.organizationId().embeddings.text(text).get();
    if (!response.exists() || response.data().model !== TEXT_EMBEDDING_MODEL) {
      return { textIndex: textIndex, text: text }
    } else {
      return { textIndex: textIndex, text: text, embedding: response.data().embedding }
    }
  }));
  let textsToEmbed = data.filter(d => !d.embedding);
  let embeddings = textsToEmbed.length === 0 ? { data: [] } : await createEmbedding({ model: TEXT_EMBEDDING_MODEL, input: textsToEmbed.map(d => d.text) });
  textsToEmbed = await Promise.all(textsToEmbed.map(async (d, index) => {
    await context.db.organizations.organizationId().embeddings.text(d.text).set({
      model: TEXT_EMBEDDING_MODEL,
      embedding: embeddings.data[index]
    });
    return { textIndex: d.textIndex, text: d.text, embedding: embeddings.data[index] };
  }));
  for (let d of textsToEmbed) {
    data[d.textIndex].embedding = d.embedding;
  }
  return data.map(d => d.embedding);
}

// retrieveSnippets

// finds the embeddings for each answer, cacches it, and annotates the snippet. Caches embeddings for each associated question.
export async function calculateSnippetsEmbeddings(snippets: KnowledgeSnippet[], context: Context): Promise<KnowledgeSnippet[]> {
  let result: KnowledgeSnippet[] = [...snippets];

  let embeddings = await getManyEmbeddings(result.map(r => r.answer.text), context);

  for (let i = 0; i < result.length; i++) {
    result[i].answer.embedding = embeddings[i];
    result[i].answer.embedding_model = TEXT_EMBEDDING_MODEL;
  }
  return result;
}