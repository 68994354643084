// import react
import { useState, useEffect, useRef, useCallback } from "react";
import { Modal } from "./Modal";
import { FAQ, KnowledgeSnippet, KnowledgeSourceInfo, MultiPageKnowledgeUploadProcess, MultiPageKnowledgeUploadProgress, ScrapedLink } from "../../lib/common-types";
import { useAppContext } from "../../lib/context-prod";
import { createChatCompletion, createEmbedding, retrieveSnippets, startMultiPageBulkUpload } from "../../lib/firebase-config";
import { ExpandingTextArea, LabelInput, LabelSelect, LabelTextArea, MultiInput, PrimaryButton, SecondaryButton, TextButton } from "./ComponentsLib";
import { Context } from "../../lib/context";
import { getSingleEmbedding, getManyEmbeddings, calculateSnippetsEmbeddings } from "../../lib/embeddings";
import { set } from "firebase/database";
import { QueryDocumentSnapshot } from "firebase/firestore";

// import BModal from "react-bootstrap/Modal";

const text2FAQsMessages = [{
  role: 'system' as 'system',
  content: 'You have a document that contains information on various topics, and you want to extract snippets of knowledge from it and format them as a JSON string. Each snippet should consist of an answer (less than 100 tokens) and one or more questions that lead to the answer. If multiple questions with distinct meanings are implied from the document, you should include all of them.',
}, {
  role: 'user' as 'user',
  content: 'Sea turtles can live for decades and grow to be quite large, with some species reaching over six feet in length and weighing up to 1,500 pounds. They have a streamlined body shape, flippers for swimming, and a tough, bony shell that helps protect them from predators.',
}, {
  role: 'assistant' as 'assistant',
  content: `[  
    {   "answer": "Sea turtles can live for decades and grow to be quite large, with some species reaching over six feet in length and weighing up to 1,500 pounds.",   "questions": ["How long can sea turtles live for and how big can they grow?", "What is the size and lifespan of sea turtles?"]
    },
    {
      "answer": "Sea turtles have a streamlined body shape, flippers for swimming, and a tough, bony shell that helps protect them from predators.",
      "questions": ["What features do sea turtles have to help them survive?", "What is the body structure of a sea turtle like?"]
    }
  ]`
}];


export function AddOrEditKnowledgeSnippet(props: {
  visible?: boolean,
  onClose?: () => void,
  knowledgeBaseId: string
  snippetId?: string, // determines edit or save mode
}) {
  const [answer, setAnswer] = useState("");
  const [topicQuestion, setTopicQuestion] = useState<string>();
  const [status, setStatus] = useState<'flagged' | 'pending' | 'removed' | 'approved' | 'needs-answer'>("approved"); // comma separated
  const [tags, setTags] = useState<string>(""); // comma separated
  const [visible, setVisible] = useState(props.visible ? props.visible : false);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const context = useAppContext();

  const onClose = useCallback(() => {
    if (props.onClose) {
      props.onClose()
    } else {
      setVisible(false);
    }
  }, [props.onClose]);

  let Load = async () => {
    setLoading(true);
    console.log("loading add or edit snippet: ", props.snippetId ? props.snippetId : "new")
    if (props.snippetId) {
      // load snippet
      await context.db.organizations.organizationId()
        .knowledgeBases.knowledgeBaseId(props.knowledgeBaseId)
        .snippets.snippetId(props.snippetId).get()
        .then((snippet) => {
          if (snippet.exists()) {
            setAnswer(snippet.data().answer.text);
            setTopicQuestion(snippet.data().topicQuestion ? snippet.data().topicQuestion : "");
            let tags_ = snippet.data().tags ? snippet.data().tags.join(", ") : '';
            setTags(tags_);
            setStatus(snippet.data().status);
            setLoading(false);
          } else {
            throw new Error("Snippet not found");
          }
        }).catch((error) => {
          console.error("error: " + JSON.stringify(error, null, 2));
          // setVisible(false);
          // props.onClose && props.onClose();
          alert("There was an error loading the snippet. Please try again. If the problem persists, please contact support.")
        });
    } else {
      setAnswer("");
      // questionsRef.current = [""];
      setTopicQuestion("");
      setLoading(false);
    }
  }

  // useEffect(() => {
  //   console.log("add or edit snippet id changed: ", props.snippetId);
  //   setSnippetId(props.snippetId ? props.snippetId : "");
  // }, [props.snippetId]);

  useEffect(() => {
    console.log("add or edit snippet visibility changed: ", props.visible);
    setVisible(props.visible);
    if (props.visible) {
      Load();
    }
  }, [props.visible]);

  useEffect(() => {
    console.log("add or edit state visibility changed: ", visible, props.visible);
  }, [visible]);

  if (loading) {
    console.log("loading add or edit snippet")
    return <></>;
  }

  return (
    <Modal
      visible={visible}
      onClose={() => {
        console.log("modal onclose called");
        onClose();
      }}
      innerStyle={{
        width: '95%', maxWidth: '600px', height: '95%', maxHeight: '500px',
        padding: '40px', boxSizing: 'border-box',
        display: 'flex', flexDirection: 'column',
        borderRadius: '10px'
      }}
    >
      <div style={{
        overflowY: 'scroll',
        width: '100%', height: '100%', display: 'flex', flexDirection: 'column', padding: '10px', boxSizing: 'border-box',

      }}>
        <div style={{ textAlign: 'center', fontSize: '1.6rem', padding: '10px 0 50px 0', fontWeight: 'bold' }}>
          {props.snippetId ? "Edit a snippet of knowledge" : "Write a snippet of knowledge"}
        </div>
        {/* <div style={{ color: "rgb(77, 77, 77)", paddingBottom: '30px', fontSize: '0.95rem' }}>
          Adding more questions aids the bot in identifying the correct quote when multiple distinct questions have the same answer.
        </div> */}
        <LabelInput
          label={"Question"}
          placeholder={"e.g. \"Why should I use your chatbot product instead of others?\""}
          value={topicQuestion}
          onChange={(e) => {
            setTopicQuestion(e.target.value);
          }}
        />
        {/* <MultiInput
          label={"Question(s)"}
          title={"Questions help the bot understand which pieces of knowledge to use when writing a message."} // Adding more questions aids the bot in identifying the correct knowledge when multiple distinct questions have the same answer.
          placeholder={"e.g. \"Why should I use your chatbot product instead of others?\""}
          values={questions}
          onChange={newValues => setQuestions(newValues)}
        /> */}
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '30px', width: '100%' }}>


          <LabelTextArea
            label={"Answer"}
            placeholder={"e.g. \"Unlike bots trained on boring documents, we let sales teams create a personalized experience for their leads.\""}
            style={{
              width: '100%',
              //  resize: 'none', height: 'auto', 
              boxSizing: 'border-box'
            }}
            value={answer}
            onChange={(e) => {
              if (e.target.value.length > 800) {
                alert("You've reached the maximum character limit. The knowledge snippet has been truncated.");
              }
              setAnswer(e.target.value.substring(0, 800));
              // answerRef.current.style.height = "auto";
              // answerRef.current.style.height = (answerRef.current.scrollHeight + 10) + "px";
            }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '30px', width: '100%' }}>
          <LabelSelect defaultValue={status} options={
            [{ label: 'approved', value: 'approved' },
            { label: 'pending approval', value: 'pending' },
            { label: 'flagged', value: 'flagged' }
            ]}
            onChange={(e) => {
              setStatus(e.target.value as 'approved' | 'pending' | 'flagged');
            }}
          />
        </div>
        {/* <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
         
          <LabelInput
            placeholder={"Comma separated, e.g. \"tag1, tag2, tag3\""}
            onChange={(e) => { setTags(e.target.value) }}
            style={{ width: '100%', boxSizing: 'border-box', }}
            value={tags}
            label={"Tags"}
          />
        </div> */}
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '20px' }}>
        <PrimaryButton
          disabled={saving}
          style={{
            width: '120px',
            margin: '10px'
          }}
          onClick={async () => {
            if (!answer) {
              alert("Please enter an answer");
              return;
            }
            setSaving(true);
            let input: KnowledgeSnippet = {
              topicQuestion: topicQuestion,
              answer: {
                text: answer,
                created: new Date().getTime()
              },
              tags: tags.split(", ").map(t => t.trim()).filter(t => t.length > 0), // tagsRef.current.value.split(",").map(t => t.trim()),
              sourceInfo: props.snippetId ? {
                source: "knowledge-base-edit" as "knowledge-base-edit",
                snippetId: props.snippetId
              } : {
                source: "knowledge-base-addition",
              },
              status: status,
              created: new Date().getTime()
            };
            let snippet = (await calculateSnippetsEmbeddings(
              [input],
              context)
            )[0];
            console.log("Snippet: ", JSON.stringify(snippet, null, 2));
            if (props.snippetId) {
              await context.db
                .organizations.organizationId()
                .knowledgeBases.knowledgeBaseId(props.knowledgeBaseId)
                .snippets.snippetId(props.snippetId).update({ status: 'removed' });
            }
            await context.db.organizations.organizationId().knowledgeBases.knowledgeBaseId(props.knowledgeBaseId).snippets.push(snippet);
            setSaving(false);
            onClose();
          }}>
          Save
        </PrimaryButton>
        <SecondaryButton
          style={{
            width: '120px',
            margin: '10px'
          }}
          onClick={() => {
            onClose();
          }}>Cancel</SecondaryButton>
      </div>

    </Modal >);
}

export function BulkUploadKnowledgeSnippets(props: {
  visible: boolean,
  onClose?: () => void,
  knowledgeBaseId: string
}) {
  // const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [bulktext, setBulktext] = useState("");
  const [url, setUrl] = useState("");
  const [stage, setStage] = useState<"upload" | "questions" | "snippets">("upload");
  const [questions, setQuestions] = useState<string[]>([]);
  const [snippets, setSnippets] = useState<KnowledgeSnippet[]>([]);
  const [originalSnippets, setOriginalSnippets] = useState<KnowledgeSnippet[]>([]); // for undo
  const [checkedSnippets, setCheckedSnippets] = useState<number[]>([]);
  const [visible, setVisible] = useState(props.visible);
  const [processing, setProcessing] = useState(false);
  const [saving, setSaving] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const context = useAppContext();
  let onClose = useCallback(() => {
    if (props.onClose) {
      props.onClose();
    } else {
      setVisible(false);
    }
  }, [props.onClose])

  useEffect(() => {
    if (!processing && scrollRef.current) {
      setTimeout(() => {
        scrollRef.current.scrollTo({
          top: Math.min(scrollRef.current.scrollTop + 400, scrollRef.current.scrollHeight),
          behavior: 'smooth'
        })
      }, 100);
    }
  }, [processing])

  useEffect(() => {
    setVisible(props.visible);
  }, [props.visible])

  let process = async (input: { url: string, text?: string } | { text: string, url?: string }) => {
    if (!input.url && !input.text) {
      console.log("url not valid: ", url);
      return;
    }
    setProcessing(true);
    try {
      let response;
      if (input.url) {
        response = await retrieveSnippets({ url: input.url });
      } else {
        response = await retrieveSnippets({ text: input.text });
      }
      if (!response.data.ok) {
        setProcessing(false);
        alert("Failed to process snippets.");
        return;
      }

      let faqs = response.data.snippets as Array<{ question: string, answer: string }>;

      let snippets_: KnowledgeSnippet[] = [];
      for (let row of faqs) {
        snippets_.push({
          topicQuestion: row.question,
          answer: {
            text: row.answer,
            created: new Date().getTime()
          },
          status: 'approved',
          created: new Date().getTime(),
          sourceInfo: {
            source: 'knowledge-base-addition'
          }
        })
      }
      setCheckedSnippets(snippets_.map((s, index) => index));
      setOriginalSnippets(snippets_);
      setQuestions(snippets_.map(s => s.topicQuestion));
      setProcessing(false);
      setStage("questions");
    } catch (e) {
      console.error(e);
      setProcessing(false);
      alert("Failed to process snippets.");
    }
  }

  return <Modal
    visible={visible}
    onClose={onClose}
    innerStyle={{ padding: '40px', maxWidth: '700px', maxHeight: '600px' }}>
    <div
      style={{ width: '100%', height: '100%', overflowY: 'scroll', boxSizing: 'border-box', padding: '10px' }}
      ref={scrollRef}>
      <h2 style={{ textAlign: 'center' }}>Bulk Upload Knowledge</h2>
      <h3>Step 1: Add knowledge</h3>
      <p style={{ color: '#333' }}>Share info about your company and products. We'll create bite-sized snippets for you to review. Upload from a url or put content in the text area below.</p>
      {(!processing || stage !== 'upload') && <div>
        <LabelInput
          label={"url"}
          value={url ? url : ""}
          placeholder={"e.g. https://www.yourwebsite.com/faq"}
          onChange={(e) => {
            setUrl(e.target.value);
          }}
        />
        <SecondaryButton
          disabled={processing}
          style={{
            // padding: '8px',
            // cursor: 'pointer',
            // border: 'solid 1px #5664F5',
            width: '120px',
            marginTop: '20px',
            // backgroundColor: processing ? 'lightgrey' : 'white',
            // color: '#5664F5', borderRadius: '5px', fontWeight: 'bold'
          }}
          onClick={async () => {
            process({ url: url });
          }}
        >Process</SecondaryButton>
        <div style={{ textAlign: 'center', margin: '20px' }}>-- OR --</div>
        <LabelTextArea
          value={bulktext}
          label={"text"}
          placeholder={"Paste articles, csv data or copied data from websites here"}
          onChange={(e) => {
            if (e.target.value.length > 10000) {
              setBulktext(e.target.value.substring(0, 10000));
              // e.target.value = e.target.value.substring(0, 10000);
              alert("You've reached the maximum character limit. Your document will be truncated.");
            } else {
              setBulktext(e.target.value);
            }
          }}
          style={{
            maxHeight: '300px',
          }}
        />
        <SecondaryButton
          disabled={processing}
          style={{
            // padding: '8px',
            // cursor: 'pointer',
            // border: 'solid 1px #5664F5',
            width: '120px',
            marginTop: '20px',
            // backgroundColor: processing ? 'lightgrey' : 'white',
            // color: '#5664F5', borderRadius: '5px', fontWeight: 'bold'
          }}
          onClick={async () => {
            process({ text: bulktext });
          }}
        >Process</SecondaryButton>
      </div>
      }
      {processing && stage === 'upload' && <div style={{ textAlign: 'center', margin: '80px' }}>Processing... This can take up to 2 minutes.</div>}
      {/* <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}> */}
      {/* <SecondaryButton
        disabled={processing}
        style={{
          // padding: '8px',
          // cursor: 'pointer',
          // border: 'solid 1px #5664F5',
          width: '120px',
          marginTop: '20px',
          // backgroundColor: processing ? 'lightgrey' : 'white',
          // color: '#5664F5', borderRadius: '5px', fontWeight: 'bold'
        }}
        onClick={async () => {
          if (!bulktext || bulktext.length >= 15000) {
            console.log("text not valid: ", bulktext);
            return;
          }
          let messages = [...text2FAQsMessages, {
            role: 'user' as 'user',
            content: bulktext // textareaRef.current.value
          }];
          let processResponse = (data: string) => {
            console.log("processResponse", data);
            let rawdata: Array<{ answer: string; questions: string[] }> = [];
            for (let tries = 1; tries <= 2; tries++) {
              try {
                rawdata = JSON.parse(data);
              } catch (e) {
                data = data.split("```")[1];
              }
            }
            if (rawdata.length === 0) {
              throw new Error("Invalid snippets");
            }
            console.log("rawdata", JSON.stringify(rawdata, null, 2));
            if (!rawdata || !rawdata.length || !rawdata[0] || !rawdata[0].answer || !rawdata[0].questions || !rawdata[0].questions.length) {
              throw new Error("Invalid snippets");
            }
            let snippets_: KnowledgeSnippet[] = [];
            for (let row of rawdata) {
              snippets_.push({
                questions: row.questions,
                answer: {
                  text: row.answer,
                  created: new Date().getTime()
                },
                status: 'approved',
                created: new Date().getTime(),
                sourceInfo: {
                  source: 'knowledge-base-addition'
                }
              })
            }
            setCheckedSnippets(snippets_.map((s, index) => index));
            setSnippets(snippets_);
          }
          let response;
          setProcessing(true);
          for (let tries = 1; tries <= 3; tries++) {
            console.log("chatCompletion try " + tries + "...");

            try {
              response = await createChatCompletion({ model: 'gpt-3.5-turbo-0301', messages: messages });
              processResponse(response.data.content);
              break;
            } catch (e) {
              if (tries === 3) {
                alert("Failed to process snippets.");
                throw JSON.stringify(e, null, 2);
              }
            }
          }
          setProcessing(false);
        }}
      >Process</SecondaryButton> */}
      {/* </div> */}
      {['questions', 'snippets'].includes(stage) && originalSnippets && originalSnippets.length > 0 && <div style={{ marginTop: '40px' }}>
        <h3>Step 2: Review Questions to Extract Answers For</h3>
        <p style={{ color: '#333' }}>The bot will look for answers for these questions from the text. Make sure that the question has an answer from the text or else the bot will make something up.</p>

        <MultiInput
          label={"What questions do you want the url or text to answer?"}
          values={questions}
          onChange={(newValues) => setQuestions(newValues)}
        />
        <SecondaryButton
          style={{
            width: '120px',
            marginTop: '20px',
          }}
          disabled={processing}
          onClick={async () => {
            setProcessing(true);
            // for each question, find the answer in the text
            let newQuestions = questions.filter(q => !originalSnippets.map(s => s.topicQuestion).includes(q));
            let snippets_ = await calculateSnippetsEmbeddings(originalSnippets, context);
            if (newQuestions.length > 0) {
              console.log("New questions: " + JSON.stringify(newQuestions, null, 2));
              let newQuestionEmbeddings = await getManyEmbeddings(newQuestions, context);
              console.log(1);
              let topicQuestionEmbeddings = await getManyEmbeddings(snippets_.map(s => s.topicQuestion), context);
              let x = snippets_.map((s, index) => { return { snippet: s, index: index } });
              // sort snippets by embedding match
              let newSnippets: KnowledgeSnippet[] = [];
              for (let newQuestionIndex in newQuestions) {
                let newQuestion = newQuestions[newQuestionIndex];
                // find top 4 snippets for each new questions
                // let newQuestion = newQuestions[i];
                let newQuestionEmbedding = newQuestionEmbeddings[newQuestionIndex];
                let topSnippets = x.map((s) => {
                  let topicMatch = newQuestionEmbedding.reduce((a, b, index) => a + b * topicQuestionEmbeddings[s.index][index], 0);
                  let answerMatch = newQuestionEmbedding.reduce((a, b, index) => a + b * s.snippet.answer.embedding[index], 0);
                  return {
                    snippet: s.snippet,
                    index: s.index,
                    score: Math.max(topicMatch, answerMatch)
                  }
                }).sort((a, b) => b.score - a.score).slice(0, 4);
                let messages = [];
                messages.push({
                  role: "system",
                  content: "Answer in under 400 characters."
                });
                for (let topSnippet of topSnippets) {
                  messages.push({
                    role: 'user' as 'user',
                    content: topSnippet.snippet.topicQuestion
                  })
                  messages.push({
                    role: 'assistant' as 'assistant',
                    content: topSnippet.snippet.answer.text
                  });
                }
                messages.push({
                  role: 'user' as 'user',
                  content: newQuestion
                })
                console.log(2);
                let answer = await createChatCompletion({ model: 'gpt-3.5-turbo-0301', messages: messages }).then((response) => {
                  return response.data.content;
                });
                newSnippets.push({
                  topicQuestion: newQuestion,
                  answer: {
                    text: answer,
                    created: new Date().getTime()
                  },
                  status: 'approved',
                  created: new Date().getTime(),
                  sourceInfo: {
                    source: 'knowledge-base-addition'
                  }
                });
              }
              console.log(3);
              snippets_ = await calculateSnippetsEmbeddings([...snippets_, ...newSnippets], context);

            }
            snippets_ = questions.map(q => {
              return snippets_.find(s => s.topicQuestion === q);
            });
            setCheckedSnippets(snippets_.map((s, index) => index));
            setSnippets(snippets_);
            setStage('snippets');
            setProcessing(false);
          }}
        >
          Get Answers
        </SecondaryButton>
      </div>}
      {
        stage === 'snippets' && snippets && snippets.length > 0 && <div style={{ marginTop: '40px' }}>
          <h3>Step 3: Review Knowledge Snippets</h3>
          <p style={{ color: '#333' }}>These are candidate knowledge snippets. The bot uses snippets to answer customer questions. Uncheck any unwanted ones, modify them to your liking, and add questions to guide the bot.</p>

          <table style={{ width: '100%', border: "solid 2px lightgray", borderRadius: '8px' }}>
            <thead>
              <tr>
                <th style={{ textAlign: 'left', width: '22px', padding: '10px' }}>
                  <input type='checkbox'
                    checked={checkedSnippets.length === snippets.length}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setCheckedSnippets(snippets.map((s, index) => index));
                      } else {
                        setCheckedSnippets([]);
                      }
                    }}
                  />
                </th>
                <th style={{ textAlign: 'left', padding: '10px' }}>Question</th>
                <th style={{ textAlign: 'left', padding: '10px' }}>Answer</th>
              </tr>
            </thead>
            <tbody>
              {snippets.map((snippet, index) => {
                return <tr style={{
                  backgroundColor: index % 2 === 1 ? 'white' : 'rgb(221, 221, 255)'
                }}>
                  <td style={{ padding: '10px' }}>
                    <input type='checkbox'
                      checked={checkedSnippets.includes(index)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setCheckedSnippets([...checkedSnippets, index]);
                        } else {
                          setCheckedSnippets(checkedSnippets.filter(i => i !== index));
                        }
                      }}
                    /></td>


                  <td style={{ padding: '10px' }}>
                    {/* <MultiInput
                      values={snippet.questions}
                      onChange={(questions) => {
                        let snippets_ = [...snippets];
                        snippets_[index].questions = questions;
                        setSnippets(snippets_);
                      }}
                      style={{
                        border: snippet.questions.filter(q => q.length === 0).length > 0 ? 'solid 1px red' : 'none',
                      }}
                      inputStyle={{ backgroundColor: 'rgba(255,255,255,0.5)', border: 'solid 1px lightgray' }}
                    /> */}
                    <LabelInput
                      value={snippet.topicQuestion}
                      onChange={(e) => {
                        let snippets_ = [...snippets];
                        snippets_[index].topicQuestion = e.target.value;
                        setSnippets(snippets_);
                      }}
                    />

                  </td>
                  <td style={{ padding: '10px' }}>
                    <ExpandingTextArea defaultValue={snippet.answer.text}
                      style={{
                        width: '100%',
                        boxSizing: 'border-box',
                        border: snippet.answer.text.length === 0 ? 'solid 1px red' : 'solid 1px lightgray',
                        backgroundColor: 'rgba(255,255,255,0.5)',
                        padding: '5px',
                        maxHeight: '150px', overflow: 'auto'
                      }}
                      onChange={(e) => {
                        if (e.target.value.length > 800) {
                          alert("You've reached the maximum character limit. The knowledge snippet has been truncated.");
                        }
                        let snippets_ = [...snippets];
                        snippets_[index].answer.text = e.target.value.substring(0, 800);
                        e.target.value = e.target.value.substring(0, 800);
                        setSnippets(snippets_);
                      }} />
                  </td>
                </tr>
              })}
            </tbody>
          </table>
          <div style={{ marginTop: '40px' }} />
          <h3>Step 4: Add to the knowledge base</h3>
          <p style={{ color: '#333' }}>The checked snippets will be added to the bot's knowledge once you save.</p>
          <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <PrimaryButton
              disabled={
                saving ||
                processing ||
                checkedSnippets.length === 0 ||
                snippets.filter((snippet, index) => checkedSnippets.includes(index)).filter((snippet) => { return snippet.answer.text.length === 0 }).length > 0 ||
                snippets.filter((snippet, index) => checkedSnippets.includes(index)).filter((snippet) => { return snippet.topicQuestion.length === 0 }).length > 0
                // !snippets.filter((snippet, index) => checkedSnippets.includes(index)).every((snippet) => { return snippet.questions.every((question) => { return question.length > 0 }) })
              }
              style={{
                margin: '10px',
                width: '150px'
              }}
              onClick={async () => {
                setSaving(true);
                try {
                  let snippets_ = snippets.filter((s, index) => checkedSnippets.includes(index));
                  snippets_ = await calculateSnippetsEmbeddings(snippets_, context);
                  await Promise.all(snippets_.map(async (snippet) => {
                    return context.db.organizations.organizationId().knowledgeBases.knowledgeBaseId(props.knowledgeBaseId).snippets.push(snippet);
                  }));
                  onClose();
                } catch (e) {
                  setSaving(false);
                }
              }}
            >
              Save
            </PrimaryButton>
            <SecondaryButton
              style={{
                margin: '10px',
                width: '150px'
              }}
              onClick={onClose}
            >
              Cancel
            </SecondaryButton>
          </div>
        </div>
      }
    </div>
  </Modal >

}

export function AddKnowledgeMenu(props: {
  knowledgeBaseId: string,
  onClose?: () => void,
}) {
  const [menuVisible, setMenuVisible] = useState(false);
  const [addEditKnowledgeModalVisible, setAddEditKnowledgeModalVisible] = useState(false);
  const [bulkUploadModalVisible, setBulkUploadModalVisible] = useState(false);
  let onClose = useCallback(() => {
    setMenuVisible(false);
    setAddEditKnowledgeModalVisible(false);
    setBulkUploadModalVisible(false);
    props.onClose && props.onClose();
  }, [props.onClose]);
  const width = 200;
  const optionStyle = {
    width: '100%',
    padding: '8px',
    backgroundColor: 'white',
    border: 'none',
    color: 'rgb(13, 32, 230)',
    display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    boxSizing: 'border-box' as 'border-box'
  }
  useEffect(() => {
    console.log("addEditKnowledgeModalVisible state changed: ", addEditKnowledgeModalVisible);
  }, [addEditKnowledgeModalVisible])
  return (
    <div style={{ display: 'inline-block' }}>
      <div style={{ position: 'relative', height: '41px', width: width + 'px', display: 'inline-block', cursor: 'pointer' }}>
        <div style={{
          position: 'absolute',
          width: width + 'px',
          top: 0, left: 0,
          boxShadow: menuVisible ? '0 0 5px rgba(0, 0, 0, 0.3)' : undefined,
          border: 'none',
        }}
          onMouseLeave={() => {
            setMenuVisible(false);
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: (width - 20) + 'px',
              padding: '10px',
              color: 'white',
              backgroundColor: '#5664F5',
              border: 'none',
              fontWeight: 'bold'
            }}
            onClick={() => {
              setMenuVisible(menuVisible ? false : true);
            }}>
            Add Knowledge
          </div>

          <div style={{ display: menuVisible ? "flex" : "none", flexDirection: 'column', width: '100%', padding: '8px', backgroundColor: 'white', boxSizing: 'border-box' }}>
            <div
              style={optionStyle}
              onClick={() => {
                setBulkUploadModalVisible(true);
              }}>Paste text in bulk
            </div>
            <div
              style={optionStyle}
              onClick={() => {
                setAddEditKnowledgeModalVisible(true);
                console.log("pressed add single knowledge quote")
              }}>Write a snippet
            </div>
          </div>
        </div>
      </div>

      <AddOrEditKnowledgeSnippet
        visible={addEditKnowledgeModalVisible}
        onClose={onClose}
        knowledgeBaseId={props.knowledgeBaseId}

      />
      <BulkUploadKnowledgeSnippets
        visible={bulkUploadModalVisible}
        onClose={onClose}
        knowledgeBaseId={props.knowledgeBaseId} />
    </div>
  );
}


export function MultiPageKnowledgeScraping(props: { knowledgeBaseId: string, onClose: () => void, visible?: boolean }) {
  const [process, setProcess] = useState<MultiPageKnowledgeUploadProcess | undefined>(undefined);
  const [progress, setProgress] = useState<MultiPageKnowledgeUploadProgress | undefined>(undefined);
  const [urls, setUrls] = useState<QueryDocumentSnapshot<ScrapedLink>[]>([]);
  const [urlSelections, setUrlSelections] = useState<boolean[]>([]);
  const [faqs, setFAQs] = useState<FAQ[]>([]);
  const [faqsSelections, setFAQsSelections] = useState<boolean[]>([]);
  const [domain, setDomain] = useState("");
  const [visible, setVisible] = useState(props.visible ? props.visible : false);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [saving, setSaving] = useState(false);
  const context = useAppContext();

  const onClose = useCallback(() => {
    if (props.onClose) {
      props.onClose()
    } else {
      setVisible(false);
    }
  }, [props.onClose]);

  let Load = async () => {
    setLoading(true);
    if (!props.knowledgeBaseId) {
      console.error("knowledge base id not provided");
      return;
    }
    // load snippet
    if (!loaded) {
      context.db.organizations.organizationId()
        .knowledgeBases.knowledgeBaseId(props.knowledgeBaseId)
        .info.multiPageUploadProcess.onChange((processSnapshot) => {
          if (!processSnapshot.exists) {
            console.log("processSnapshot doesn't exist");
            setProcess(undefined);
            return;
          }
          let data = processSnapshot.data();
          setProcess(data);
          console.log("processSnapshot: ", JSON.stringify(data, null, 2));
        });
      context.db.organizations.organizationId()
        .knowledgeBases.knowledgeBaseId(props.knowledgeBaseId)
        .info.multiPageUploadProgress.onChange((progressSnapshot) => {
          if (!progressSnapshot.exists) {
            console.log("progressSnapshot doesn't exist");
            setProgress(undefined);
            return;
          }
          let data = progressSnapshot.data();
          setProgress(data);
          console.log("progressSnapshot: ", JSON.stringify(data, null, 2));
        });
      context.db.organizations.organizationId()
        .knowledgeBases.knowledgeBaseId(props.knowledgeBaseId)
        .info.multiPageUploadProcess.urls.onChange((urlsSnapshot) => {
          setUrls((prevUrls) => {
            const newUrls = urlsSnapshot.docChanges()
              .filter((change) => change.type === 'added')
              .map((change) => change.doc);
            return newUrls.concat(prevUrls);
          });

          setUrlSelections((prevSelections) => {
            const newSelections = urlsSnapshot.docChanges()
              .filter((change) => change.type === 'added')
              .map(() => true);
            return newSelections.concat(prevSelections);
          });
        });
      context.db.organizations.organizationId()
        .knowledgeBases.knowledgeBaseId(props.knowledgeBaseId)
        .info.multiPageUploadProcess.faqs.onChange((snippetsSnapshot) => {
          setFAQs((prevFAQs) => {
            const newFAQs = snippetsSnapshot.docChanges()
              .filter((change) => change.type === 'added')
              .map((change) => change.doc.data());
            return newFAQs.concat(prevFAQs);
          });
          setFAQsSelections((prevSelections) => {
            const newSelections = snippetsSnapshot.docChanges()
              .filter((change) => change.type === 'added')
              .map(() => true);
            return newSelections.concat(prevSelections);
          });
        });
      setLoaded(true);
    }

    setLoading(false);
  }

  useEffect(() => {
    console.log("add or edit snippet urls changed: ", JSON.stringify(urls.map(u => u.data())));
  }, [urls]);

  useEffect(() => {
    console.log("add or edit snippet visibility changed: ", props.visible);
    setVisible(props.visible);
    if (props.visible) {
      Load();
    }
  }, [props.visible]);

  useEffect(() => {
    console.log("add or edit state visibility changed: ", visible, props.visible);
  }, [visible]);

  if (loading) {
    console.log("loading add or edit snippet")
    return <></>;
  }

  return (
    <Modal
      visible={visible}
      onClose={() => {
        console.log("modal onclose called");
        onClose();
      }}
      innerStyle={{
        width: '95%', maxWidth: '900px', height: '95%', maxHeight: '1000px',
        padding: '40px', boxSizing: 'border-box',
        display: 'flex', flexDirection: 'column',
        borderRadius: '10px'
      }}
    >
      <div style={{
        overflowY: 'scroll',
        width: '100%', height: '100%', display: 'flex', flexDirection: 'column', padding: '10px', boxSizing: 'border-box',
      }}>

        {!process &&
          <div>
            <div style={{ textAlign: 'center', fontSize: '1.6rem', padding: '10px 0 50px 0', fontWeight: 'bold' }}>
              {"Choose a website to train your bot from"}
            </div>
            <div style={{ color: "rgb(77, 77, 77)", paddingBottom: '30px', fontSize: '0.95rem' }}>
              {" Enter the website that you would like to train your bot on and we'll find a list of pages to choose from. This will extract a list of pages. The next step will ask you to select the pages you would like to use. If there is a robots.txt that points to a sitemap, the sitemap will be used to extract pages."}
            </div>
            <LabelInput
              label={"domain"}
              placeholder={"e.g. https://www.yourwebsite.com/"}
              value={domain}
              onChange={(e) => {
                setDomain(e.target.value)
              }}
            />
            <SecondaryButton
              style={{
                width: '120px',
                marginTop: '10px'
              }}
              onClick={async () => {
                if (!domain) {
                  alert("Please enter a domain");
                  return;
                }
                setSaving(true);
                await startMultiPageBulkUpload({ url: domain, knowledgeBaseId: props.knowledgeBaseId, organizationId: context.db.organizationId });
                setSaving(false);
              }}
              disabled={saving}
            >Start</SecondaryButton>
          </div>}
        {process && (process.state === 'created' || process.state === 'started-url-scraping' || process.state === 'finished-url-scraping') && <div>
          <div>Page extraction progress
            <div>{
              progress && progress.urlScrapingProgress && progress.urlScrapingProgress.pagesToVisitCount
                ? Math.round(100 * progress.urlScrapingProgress.pagesVisitedCount / progress.urlScrapingProgress.pagesToVisitCount) + '%'
                : "0%"
            }</div>
          </div>
          <div style={{ textAlign: 'center', fontSize: '1.6rem', padding: '10px 0 50px 0', fontWeight: 'bold' }}>
            {"Choose the pages to train on"}
          </div>
          <div style={{ color: "rgb(77, 77, 77)", paddingBottom: '30px', fontSize: '0.95rem' }}>
            {"Select the pages you would like to train your bot on. The next step will ask you to select the questions and answers from the pages you selected."}
          </div>
          <table style={{ width: '100%', border: "solid 2px lightgray", borderRadius: '8px', display: urls.length > 0 ? "block" : "none" }}>
            <thead>
              <tr>
                <th style={{ textAlign: 'left', width: '22px', padding: '10px' }}>
                  <input type='checkbox'
                    checked={urlSelections.filter(x => x).length === urls.length}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setUrlSelections(urls.map(u => true));
                      } else {
                        setUrlSelections(urls.map(u => false));
                      }
                    }}
                  />
                </th>
                <th style={{ textAlign: 'left', padding: '10px' }}>Page</th>
                <th style={{ textAlign: 'left', padding: '10px' }}>Character count</th>
              </tr>
            </thead>
            <tbody>
              {urls.map((url, index) => {
                return <tr style={{
                  backgroundColor: index % 2 === 1 ? 'white' : 'rgb(221, 221, 255)'
                }}>
                  <td style={{ padding: '10px' }}>
                    <input type='checkbox'
                      checked={urlSelections[index]}
                      onChange={(e) => {
                        let urlSelections_ = [...urlSelections];
                        urlSelections_[index] = e.target.checked;
                        setUrlSelections(urlSelections_);
                      }}
                    /></td>
                  <td style={{ padding: '10px' }}>
                    <a href={url.data().url} target="_blank" rel="noopener noreferrer">{url.data().url}</a>
                  </td>
                  <td style={{ padding: '10px' }}>
                    {url.data().characters}
                  </td>
                </tr>
              })}
            </tbody>
          </table>
          <SecondaryButton
            style={{
              width: '120px',
              marginTop: '10px'
            }}
            onClick={async () => {
              if (!urlSelections || urlSelections.filter(x => x).length === 0) {
                alert("Please choose at least one page to train on.");
                return;
              }
              setSaving(true);
              // bulk update urls
              await context.db.organizations.organizationId()
                .knowledgeBases.knowledgeBaseId(props.knowledgeBaseId)
                .info.multiPageUploadProcess.urls.bulkUpdate(urls.map((url, index) => {
                  return {
                    id: url.id,
                    link: { ...url.data(), rejected: !urlSelections[index] }
                  }
                }));
              // start scraping faqs
              await startMultiPageBulkUpload({ url: domain, knowledgeBaseId: props.knowledgeBaseId, organizationId: context.db.organizationId, startScrapingFAQs: true });
              setSaving(false);
            }}
            disabled={saving || process.state === 'created' || process.state === 'started-url-scraping'}
          >Start</SecondaryButton>

        </div>}
        {process && (process.state === 'started-snippet-scraping' || process.state === 'finished-snippet-scraping') && <div>
          <div>Knowledge extraction progress
            <div>{
              progress && progress.snippetScrapingProgress && progress.snippetScrapingProgress.pagesToVisitCount
                ? Math.round(100 * progress.snippetScrapingProgress.pagesVisitedCount / progress.snippetScrapingProgress.pagesToVisitCount) + '%'
                : "0%"
            }</div>
            <div>
              {progress && progress.snippetScrapingProgress && progress.snippetScrapingProgress.pagesToVisitCount && progress.snippetScrapingProgress.pagesVisitedCount
                ? progress.snippetScrapingProgress.pagesVisitedCount + " of " + progress.snippetScrapingProgress.pagesToVisitCount + " pages processed"
                : ""}
            </div>
          </div>
          <div style={{ textAlign: 'center', fontSize: '1.6rem', padding: '10px 0 50px 0', fontWeight: 'bold' }}>
            {"Choose the snippets to add the the knowledge base"}
          </div>
          <div style={{ color: "rgb(77, 77, 77)", paddingBottom: '30px', fontSize: '0.95rem' }}>
            {"Select the snippets you would like to add to the knowledge base."}
          </div>
          <table style={{ width: '100%', border: "solid 2px lightgray", borderRadius: '8px' }}>
            <thead>
              <tr>
                <th style={{ textAlign: 'left', width: '22px', padding: '10px' }}>
                  <input type='checkbox'
                    checked={faqsSelections.filter(x => x).length === urls.length}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setFAQsSelections(faqs.map(u => true));
                      } else {
                        setFAQsSelections(faqs.map(u => false));
                      }
                    }}
                  />
                </th>
                <th style={{ textAlign: 'left', padding: '10px' }}>Question</th>
                <th style={{ textAlign: 'left', padding: '10px' }}>Answer</th>
              </tr>
            </thead>
            <tbody>
              {faqs.map((faq, index) => {
                return <tr style={{
                  backgroundColor: index % 2 === 1 ? 'white' : 'rgb(221, 221, 255)'
                }}>
                  <td style={{ padding: '10px' }}>
                    <input type='checkbox'
                      checked={faqsSelections[index]}
                      onChange={(e) => {
                        let faqsSelections_ = [...faqsSelections];
                        faqsSelections_[index] = e.target.checked;
                        setFAQsSelections(faqsSelections_);
                      }}
                    /></td>
                  <td style={{ padding: '10px' }}>
                    {faq.question}
                  </td>
                  <td style={{ padding: '10px' }}>
                    {faq.answer}
                  </td>
                </tr>
              })}
            </tbody>
          </table>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
          }}>
            <PrimaryButton
              style={{
                width: '200px',
                marginTop: '40px'
              }}
              onClick={async () => {
                if (!faqsSelections || faqsSelections.filter(x => x).length === 0) {
                  alert("Please choose at least one question answer pair to add to the knowledge base.");
                  return;
                }
                setSaving(true);
                let snippets: KnowledgeSnippet[] = faqs.filter((faq_, index) => faqsSelections[index]).map((faq) => {
                  return {
                    topicQuestion: faq.question,
                    answer: {
                      text: faq.answer,
                      created: new Date().getTime()
                    },
                    status: 'approved',
                    created: new Date().getTime(),
                    sourceInfo: {
                      source: 'multipage-scraping-addition',
                      url: faq.url
                    }
                  };
                });
                console.log("finding embeddings");
                await calculateSnippetsEmbeddings(snippets, context).then(async (snippets_) => {
                  console.log("found embeddings")
                  await Promise.all(snippets_.map(async (snippet) => {
                    return context.db.organizations.organizationId().knowledgeBases.knowledgeBaseId(props.knowledgeBaseId).snippets.push(snippet);
                  }));
                  onClose();
                });
                await context.db.organizations.organizationId().info.onboarding.update({ multiPageKnowledgeScraping: true });

                setSaving(false);
              }}
              disabled={saving || process.state === 'started-snippet-scraping'}
            >Create Knowledge Base</PrimaryButton>
          </div>

        </div>}
      </div>
    </Modal >);
}