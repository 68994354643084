import React, { useCallback } from 'react';
import { useState, useEffect, useRef } from "react";
import { KnowledgeBase, KnowledgeSnippet } from "../lib/common-types";
import { useAppContext } from "../lib/context-prod";
import { QuerySnapshot, QueryDocumentSnapshot } from '@firebase/firestore';
import { Loading } from "./common/Loading";
import { Modal } from "./common/Modal";


import { FiSettings } from 'react-icons/fi';
import { IoMdArrowBack } from 'react-icons/io';
import { AddKnowledgeMenu, AddOrEditKnowledgeSnippet } from './common/KnowledgeBaseLib';
import { FaEdit } from 'react-icons/fa';
import { SecondaryButton } from './common/ComponentsLib';

// function BotModulesScreen() {
//   const [module, setModule] = useState<"dynamic-knowledge" | "behavior" | "static-knowledge" | "none">("none");
//   let arrowRef = useRef<HTMLDivElement>(null);
//   let cardStyle = {
//     display: 'flex', flexDirection: 'column' as 'column', justifyContent: 'center', alignItems: 'center',
//     height: '200px', width: '200px', margin: '10px', cursor: 'pointer', textAlign: 'center' as 'center', fontWeight: 'bold',
//     backgroundColor: 'darkcyan', color: 'white', borderRadius: '10px', borderWidth: '1px', borderStyle: 'solid', borderColor: '#000000'
//   };
//   if (module === "none") {
//     return (<div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', height: '100%', overflow: 'auto', justifyContent: 'center', alignItems: 'center' }}>
//       <div style={{
//         ...cardStyle
//       }}
//         onClick={() => setModule("dynamic-knowledge")}>
//         Dynamic Knowledge
//       </div>
//       <div style={{
//         ...cardStyle,
//         backgroundColor: 'darkorange'
//       }}
//         onClick={() => setModule("behavior")}>
//         Behavior
//       </div>
//       <div style={{
//         ...cardStyle,
//         backgroundColor: 'green'
//       }}
//         onClick={() => setModule("static-knowledge")}>
//         Static Knowledge
//       </div>

//     </div>);
//   } else if (module === "dynamic-knowledge") {
//     return (<div style={{ width: '100%', height: '100%', position: 'relative' }}>
//       <div
//         ref={arrowRef}
//         style={{ position: 'absolute', top: 0, left: 0, width: '25px', height: '25px', cursor: 'pointer' }}
//         onMouseOver={() => { arrowRef.current.style.backgroundColor = 'lightgrey' }}
//         onMouseOut={() => { arrowRef.current.style.backgroundColor = 'rgba(255, 255, 255, 0)' }}
//         onClick={() => { setModule("none") }}
//       >
//         <IoMdArrowBack />
//       </div>
//       <KnowledgeBaseMenu />
//     </div >);
//   } else {
//     return (<div>Unimplemented</div>);
//   }


// }

// function KnowledgeBaseMenu() {
//   const [loading, setLoading] = useState<boolean>(true);
//   const [knowledgeBases, setKnowledgeBases] = useState<QuerySnapshot<KnowledgeBase>>();
//   const [knowledgeBaseId, setKnowledgeBaseId] = useState<string>("");
//   const [knowledgeBase, setKnowledgeBase] = useState<KnowledgeBase>();
//   const context = useAppContext();
//   const Load = async (mode: 'no-loading-screen' | 'loading-screen' = 'loading-screen') => {
//     if (!context.db.organizationId) return;
//     if (mode === 'loading-screen') setLoading(true);
//     let knowledgeBases: QuerySnapshot<KnowledgeBase> = await context.db.organizations.organizationId().knowledgeBases.get();
//     // check that there is at least one knowledge base
//     if (knowledgeBases.docs.length === 0) {
//       // create a knowledge base
//       let time = new Date().getTime();
//       let ref = await context.db.organizations.organizationId().knowledgeBases.push({
//         name: 'Main',
//         created: time
//       });
//       setKnowledgeBaseId(ref.id);
//       setKnowledgeBase({
//         name: 'Main',
//         created: time
//       });
//       // context.db.organizations.organizationId().update()

//     } else {
//       setKnowledgeBases(knowledgeBases);
//       for (let i = 0; i < knowledgeBases.docs.length; i++) {
//         let doc = knowledgeBases.docs[i];
//         if (doc.data().name === "Main") {
//           setKnowledgeBaseId(doc.id);
//           setKnowledgeBase(doc.data());
//           break;
//         }
//       }
//     }
//     if (mode === 'loading-screen') setLoading(false);
//   };
//   useEffect(() => {
//     Load();
//   }, [context.db.organizationId]);
//   useEffect(() => {
//     Load('no-loading-screen')
//   }, [knowledgeBase])

//   if (!context.db.organizationId) {
//     return <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//       <div style={{ maxWidth: '500px', width: '100%', fontSize: '20px' }}>
//         Oops, it looks like you haven't selected an organization. Knowledge bases are
//         assigned to organizations, so you'll have to go to the Account page and select
//         an organization to use this page.
//       </div>
//     </div>;
//   }

//   if (loading) {
//     return <Loading />;
//   }

//   return (
//     <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%' }}>
{/* <div style={{
        display: 'flex',
        flexDirection: 'column',
        overflowY: "auto",
        width: '300px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#cccccc',
        borderRadius: '4px',
      }}>
        <div
          style={{
            width: '100%',
            padding: '10px',
            fontWeight: 'bold',
            textAlign: 'center',
            boxSizing: 'border-box',
            borderBottomWidth: '2px',
            borderBottomColor: 'darkgrey',
            borderBottomStyle: 'solid',
          }}
        >
          Select a Knowledge Base
        </div>
        <div
          style={{
            width: '100%',
            height: '100%',
            // display: 'flex',
            // flexDirection: 'column',
            overflowY: 'auto',
            // flexGrow: 1
          }}
        >
          {knowledgeBases.docs.map((doc, index) => {
            let knowledgeBase: KnowledgeBase = doc.data();
            return <div
              onClick={() => {
                setKnowledgeBaseId(doc.id);
                setKnowledgeBase(doc.data());
              }}
              style={{
                width: '100%', backgroundColor: knowledgeBaseId === doc.id ? '#aaaaff' : 'white', cursor: 'pointer',
                padding: '2px', color: knowledgeBaseId === doc.id ? 'white' : 'black',
                boxSizing: 'border-box'
              }}>
              {knowledgeBase.name}
            </div>
          })}
        </div>
        <div
          onClick={async () => {
            let ref = await context.db.organizations.organizationId().knowledgeBases.push({
              name: 'New Knowledge Base',
              created: new Date().getTime()
            });
            Load().then(() => {
              setKnowledgeBaseId(ref.id);
            });
          }}
          style={{
            width: '100%', backgroundColor: '#5555ff', cursor: 'pointer',
            padding: '10px', fontWeight: 'bold',
            // color: 'white', 
            color: 'darkgrey',
            textDecoration: 'underline',
            textAlign: 'center',
            // borderWidth: 1, borderStyle: 'solid', borderColor: '#5555ff',
            // borderRadius: '4px', 
            marginBottom: '10px',
            boxSizing: 'border-box'
          }}>
          New Knowledge Base
        </div>
      </div> */}
{/* <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
        {knowledgeBaseId && knowledgeBase && <KnowledgeBaseScreen knowledgeBaseId={'default'} knowledgeBase={knowledgeBase} setKnowledgeBase={setKnowledgeBase} />}
      </div>
    </div >
  );
}; */}

export function KnowledgeBaseScreen() {
  const [loading, setLoading] = useState<boolean>(true);
  // const [candidateSnippets, setCandidateSnippets] = useState<QuerySnapshot<KnowledgeSnippet>>();
  const [snippets, setSnippets] = useState<QuerySnapshot<KnowledgeSnippet>>();
  // const [removedSnippets, setRemovedSnippets] = useState<QuerySnapshot<KnowledgeSnippet>>();
  const [snippetTypeSelected, setSnippetTypeSelected] = useState<'all' | 'candidate' | 'live' | 'removed'>('candidate');
  const [newSnippetText, setNewSnippetText] = useState<string>("");
  const [knowledgeBaseId, setKnowledgeBaseId] = useState<string>("default");
  const [editSnippetId, setEditSnippetId] = useState<string>("");
  const [tags, setTags] = useState<string[]>([]);
  const [selectedSnippetIds, setSelectedSnippetIds] = useState<string[]>([]);

  const editKnowledgeCallback = useCallback(() => {
    console.log("recomputing rerender")
    setEditSnippetId("");
    Load();
  }, []);
  useEffect(() => {
    console.log("editSnippetId changed to " + editSnippetId);
  }, [editSnippetId])
  // const [name, setName] = useState<string>(props.knowledgeBase.name);
  const context = useAppContext();
  // TODO: this should be a stream instead of a one-time load
  let Load = async () => {
    setLoading(true);
    context.db.organizations.organizationId()
      .knowledgeBases.knowledgeBaseId(knowledgeBaseId)
      .snippets.getWhere('status', '!=', 'removed')
      .then((snippets) => {
        console.log('setting snippets')
        let newTags = [];
        for (let snippet of snippets.docs) {
          let snippetData = snippet.data();
          if (!snippetData.tags) {
            continue;
          }
          for (let tag of snippetData.tags) {
            if (!newTags.includes(tag)) {
              newTags.push(tag);
            }
          }
        }
        setTags(newTags);
        setSnippets(snippets);
        setLoading(false);
      })
      .catch((err) => {
        console.log("can't find snippets")
        console.error(JSON.stringify(err));
        setLoading(false);
      });
  }
  useEffect(() => {
    Load();
  }, []);
  if (loading) {
    return <Loading />;
  }
  let tabStyle = {
    display: 'flex',
    flex: 1,
    padding: '5px',
    cursor: 'pointer',
    margin: '5px',
    borderRadius: '4px',
  }
  return (<div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', padding: '40px', boxSizing: 'border-box' }}>
    <h1>Knowledge</h1>
    {snippets.docs.length === 0 && <div style={{ padding: '16px 0px', fontSize: '1.2rem' }}>Teach your bot by adding knowledge.</div>}
    <div style={{ width: '100%', display: 'flex', paddingRight: '5px', flexDirection: 'row', justifyContent: snippets.docs.length > 0 ? 'flex-end' : 'flex-start' }}>
      {/* <div style={{ display: 'flex' }}>
        <input type="text" placeholder={"search"} style={{ width: '250px' }} />
        <span style={{ padding: '3px', border: 'solid 1px black', borderRadius: '5px' }}><span>Filter By</span>
          <select
            value={snippetTypeSelected}
          >
            <option value="all">All</option>
            <option value="candidate">Candidate</option>
            <option value="live">Live</option>
            <option value="removed">Removed</option>
          </select>
        </span>

      </div> */}
      <AddKnowledgeMenu knowledgeBaseId={"default"} onClose={Load} />
    </div>

    <AddOrEditKnowledgeSnippet
      knowledgeBaseId={"default"}
      visible={!!editSnippetId}
      snippetId={editSnippetId}
      onClose={editKnowledgeCallback} />
    {selectedSnippetIds.length > 0 && <div style={{ position: 'relative' }}>
      <SecondaryButton
        style={{
          border: 'solid 1px red', backgroundColor: 'white', color: 'red',
          //  padding: '10px 20px', borderRadius: '4px',
          position: 'absolute', left: '0px', bottom: '8px', cursor: 'pointer'
        }}
        onClick={async () => {
          if (window.confirm(`Are you sure you want to remove ${selectedSnippetIds.length === 1 ? "this quote" : "these quotes"}?`)) {
            for (let snippetId of selectedSnippetIds) {
              await context.db.organizations.organizationId().knowledgeBases.knowledgeBaseId(knowledgeBaseId).snippets.snippetId(snippetId).update({ status: 'removed' });
            }
            setSelectedSnippetIds([]);
            await Load();
          }
        }}
      >Remove</SecondaryButton></div>}
    <div style={{ width: '100%', overflowY: 'auto', paddingRight: '5px', boxSizing: 'border-box' }}>

      {snippets.docs.length > 0 && <table style={{ width: '100%', border: "solid 2px lightgray", borderRadius: '8px', marginTop: '30px' }}>
        <thead>
          <tr>
            <th style={{ textAlign: 'left', padding: "8px", width: '22px' }}>
              <input type='checkbox'
                onClick={() => {
                  if (snippets.docs.length === 0) {
                    return;
                  }
                  if (selectedSnippetIds.length === snippets.docs.length) {
                    setSelectedSnippetIds([]);
                  } else {
                    setSelectedSnippetIds(snippets.docs.map((doc) => doc.id));
                  }
                }}
                checked={selectedSnippetIds.length === snippets.docs.length}
              /></th>
            <th style={{ textAlign: 'left', padding: "8px" }}>Question</th>
            <th style={{ textAlign: 'left', padding: "8px" }}>Answer</th>
            <th style={{ textAlign: 'left', padding: "8px" }}>Status</th>
            <th style={{ textAlign: 'left', padding: "8px" }}>Tags</th>
            <th style={{ textAlign: 'left', padding: "8px" }}></th>
          </tr>
        </thead>
        <tbody>
          {snippets.docs.map((doc: QueryDocumentSnapshot<KnowledgeSnippet>, index) => {
            let snippet: KnowledgeSnippet = doc.data();
            return <tr style={{ backgroundColor: index % 2 === 0 ? 'rgb(221, 221, 255)' : 'white' }}>
              <td style={{ padding: '8px' }}>
                <input type='checkbox'
                  checked={selectedSnippetIds.includes(doc.id)}
                  onClick={() => {
                    if (selectedSnippetIds.includes(doc.id)) {
                      setSelectedSnippetIds(selectedSnippetIds.filter(id => id !== doc.id));
                    } else {
                      setSelectedSnippetIds([...selectedSnippetIds, doc.id]);
                    }
                  }}
                />
              </td>
              <td style={{ padding: '8px' }}>
                {snippet.topicQuestion ? snippet.topicQuestion : ""}
              </td>
              <td style={{ padding: '8px' }}>{snippet.answer && snippet.answer.text ? snippet.answer.text : ""}</td>
              <td style={{ padding: '8px' }}>{
                !snippet.status
                  ? 'live'
                  : snippet.status === 'pending'
                    ? 'needs approval'
                    : snippet.status === 'flagged'
                      ? 'flagged'
                      : snippet.status === 'approved'
                        ? 'approved'
                        : snippet.status === 'needs-answer'
                          ? 'needs answer'
                          : 'removed'}</td>
              <td style={{ padding: '8px' }}>{snippet.tags ? snippet.tags.join(", ") : ""}</td>
              <td style={{ padding: '8px', color: '#555' }}> <span style={{ cursor: 'pointer' }}
                onClick={() => {
                  console.log("selected " + doc.id);
                  setEditSnippetId(doc.id);
                }}
              ><FaEdit /></span></td></tr>
          })}
        </tbody>
      </table>}
    </div>
  </div >);
}
export default KnowledgeBaseScreen;