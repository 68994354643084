export interface AppUser {
  email: string;
  // timestamp in msec from unix epoch
  created: number;
  // which organization to choose by default when signing in.
  defaultOrganizationId: string;
  organizationIds: string[];
}

type OrganizationInviteRole = "owner" | "super" | "admin" | "user" | "none";
export interface OrganizationUser {
  // what user information should be kept at org level?
  userId: string;
  role: OrganizationInviteRole;
  // timestamp in msec from unix epoch
  created: number;
};

export interface OrganizationInvite {
  userEmail: string;
  // timestamp in msec from unix epoch
  created: number;
  organizationId: string;
  organizationName: string;
  role: OrganizationInviteRole;
}
export interface OrganizationInviteResponse {
  status: "accepted" | "declined";
  userId: string;
}

export interface Organization {
  name: string;
  description: string;
  type: "personal" | "business";
  members?: string[];
  owners: string[];
  plan: "free" | "starter" | "growth" | "premium" | "enterprise";
  // timestamp in msec from unix epoch
  created: number;
}

export interface OnboardingChecklist {
  basicInfo?: boolean;
  knowledgeBaseQuestions?: boolean;
  multiPageKnowledgeScraping?: boolean;
}


// export interface Message {
//   text: string;
//   embeddingModel?: string;
//   embedding?: number[];
//   source: 'human' | 'bot' | 'instruction' | 'knowledge-base' | 'system';
//   // timestamp in milliseconds since the unix epoch
//   created: number;
// }

// // used in the web embed chat
// export interface ConversationExchange {
//   guestMetadata?: {
//     avatarSrc?: string;
//     name?: string;
//   };
//   guestMessageIndex?: number;
//   guestMessage?: Message[];

//   hostMetadata?: {
//     avatarSrc?: string;
//     name?: string;
//   };
//   hostMessageIndex?: number;
//   hostMessage?: Message[];
//   // timestamp in milliseconds since the unix epoch
//   created: number;
//   conversationMode: ConversationMode;
// }

// export type SystemMessageType = 'contact-asked-for-assistance' | 'host-took-over' | 'host-activated-bot';
// export interface SystemMessage {
//   type: SystemMessageType;
//   text?: string;
//   // timestamp in milliseconds since the unix epoch
//   created: number;

// }

export type KnowledgeBase = {
  name: string;
  // milliseconds since the unix epoch
  created: number;
  defaultStaticKnowledge?: string;
}

export type KnowledgeSourceInfo = {
  source: 'exchange';
  exchangeId: string;
  conversationId: string;
  campaignId: string;
} | {
  source: 'knowledge-base-addition';
} | {
  source: 'knowledge-base-edit';
  snippetId: string;
} | {
  source: 'onboarding';
} | {
  source: 'multipage-scraping-addition',
  url: string;
}

export type KnowledgeSnippet = {
  keywords?: string[];
  questions?: string[];
  answer?: BaseMessage;
  topicQuestion?: string;
  tags?: string[];
  status: 'flagged' | 'pending' | 'removed' | 'approved' | 'needs-answer';
  sourceInfo: KnowledgeSourceInfo;
  // milliseconds since the unix epoch
  created: number;
};

/*
1. Add new knowledge snippet from conversation
2. Add new knowledge snippet from knowledge base
3. Flag existing knowledge snippet as incorrect
4. Flag conversation exchange as incorrect
*/
export type BotIssue = {
  // milliseconds since the unix epoch
  created: number;
  type: 'conversation';
  action: 'flag';
  reason: string;
  source: {
    exchangeId: string;
    conversationId: string;
    campaignId: string;
    exchange: ConversationExchange;
  }

  resolution: {
    // todo
  }

} | {
  // milliseconds since the unix epoch
  created: number;
  type: 'knowledge-snippet';
  action: 'add';
  source: {
    type: 'conversation';
    exchangeId: string;
    conversationId: string;
    campaignId: string;
  } | {
    type: 'knowledge-base';
    knowledgeBaseId: string;
    snippetId: string;
  };
  snippet: KnowledgeSnippet;
  proposedResolution: Array<{
    action: 'approve';
  } | {
    action: 'change';
    snippet: KnowledgeSnippet;
  } | {
    action: 'dismiss';
  }>;
  resolution: {
    action: 'approve';
  } | {
    action: 'change';
    snippet: KnowledgeSnippet;
  } | {
    action: 'dismiss';
  }
} | {
  // milliseconds since the unix epoch
  created: number;
  type: 'knowledge-snippet';
  action: 'flag';
  knowledgeBaseId: string;
  snippetId: string;
  snippet: KnowledgeSnippet;
  proposedResolution: Array<{
    action: 'change';
    snippet: KnowledgeSnippet;
  } | {
    action: 'remove';
  } | {
    action: 'dismiss';
  }>;
  resolution: {
    action: 'change';
    snippet: KnowledgeSnippet;
  } | {
    action: 'remove';
  } | {
    action: 'dismiss';
  };
} | {
  // milliseconds since the unix epoch
  created: number;
  type: 'query-classifier';
  action: 'add';
  query: string;
  conversationContext: ConversationExchange;

};

export type Modules = {
  "knowledge-base"?: {
    knowledgeBaseId: string;
  },
  "conversation-memory"?: {
    mostRecentExchangesCount: number;
    extraExchangesCount: number;
    header?: string; // e.g. PAST CONVERSATION\n
    footer?: string;
  },
  "static-knowledge"?: {
    staticKnowledge: string;
  },
  "system-message"?: {
    instructions: string;
  }
};

export type KnowledgeBaseModule = {
  knowledgeBaseId: string;
  snippets: { snippetId: string, snippet: KnowledgeSnippet }[];
}

export type ModuleData = {
  'knowledge-base'?: KnowledgeBaseModule
  'conversation-memory'?: { conversationHistory: { exchangeId: string, exchange: ConversationExchange }[]; }
  'static-knowledge'?: { staticKnowledge: string; }
  'system-message'?: { instructions: string; }
}

export type BotContext = {
  // which bot in the campaign, 0-indexed
  botIndex: number;
  moduleData: ModuleData;
  // conversationHistory: ConversationExchange[];
  // dynamicKnowledge: ConversationExchange[];
  // staticKnowledge: string;
  // systemPrompt: string;
  promptTemplate: string;
  query: string; // what the user typed
  prompt: string;
};

export type BaseMessage = {
  text: string;
  embedding_model?: string;
  embedding?: number[];
  created: number;
}

export type GuestMessage = BaseMessage & ({
  agent: 'human';
  action: 'create';
  sent: boolean;
});

export type HumanMessage = BaseMessage & ({
  agent: 'human';
  action: 'create';
  sent: boolean;
} | {
  agent: 'human';
  action: 'select-knowledge';
  fromKnowledgeId?: string;
  sent: boolean;
} | {
  agent: 'human';
  action: 'select-suggestion';
  fromMessageId?: string;
  sent: boolean;
} | {
  agent: 'human';
  action: 'flag';
  sent: boolean;
});
// export type BotSemanticSearchMessage = BaseMessage & {
//   agent: 'bot';
//   action: 'create';
//   mode: 'semantic-search';
//   sent: boolean;
//   text?: string;
//   dynamicKnowledge: Array<{ guestMessages: BaseMessage[], hostMessage: BaseMessage }>;
// };
export type BotAutopilotMessage = BaseMessage & {
  agent: 'bot';
  action: 'create';
  mode: 'autopilot';
  sent: true;
  botContext?: BotContext;
};
export type BotCopilotMessage = BaseMessage & {
  agent: 'bot';
  action: 'create';
  mode: 'copilot';
  sent: false;
  botContext?: BotContext;
};

export type SystemMessageAction = 'contact-asked-for-assistance' | 'host-took-over' | 'host-activated-bot';
export type SystemMessage = {
  agent: 'system';
  action: SystemMessageAction;
  text: string;
  created: number;
};
export type BotMessage = BotAutopilotMessage | BotCopilotMessage /*| BotSemanticSearchMessage*/;
export type Message = HumanMessage | BotMessage;

export type ConversationExchange = {
  guestMessage: GuestMessage;
  hostMessage?: Message;
  botSuggestions?: BotMessage[];
  hostMessageVersionHistory?: Message[];
  hostMetadata?: {
    avatarSrc?: string;
    name?: string;
  }
  // milliseconds since the unix epoch
  created: number;

  addedToKnowledgeBase?: boolean;
};

export type ConversationMode = 'autopilot' | 'copilot' | 'disabled' | 'semantic-search';
export interface Conversation {
  userName: string; // e.g. fluffy-ocean-panda
  // timestamp in milliseconds since the unix epoch
  created: number;
  lastMessageTime?: number;
  mode: ConversationMode;
  // whether or not this should show up in the conversation monitor
  active: boolean;
  // 0-indexed, which bot in the list of bots in the campaign is being used.
  botIndex: number;

  lastSystemMessageAction: SystemMessageAction;
  // // TODO: what if some other seller is typing?

  // flagged?: 'none' | 'alerted' | 'resolved';
  // bookmarked: boolean;

  // disclaimer: {
  //   text: string;
  //   agreed: boolean;
  // }
  // systemMessages: {
  //   [timestamp: number]: {
  //     text: string;
  //   }
  // };
  exchanges?: {
    [exchangeId: string]: ConversationExchange;
  }
  systemMessages?: {
    [messageId: string]: SystemMessage;
  }

  // the email address of the contact. This is used to send a follow-up email.
  contactEmail?: string;
}


// interface ModelFinetuneStatus {
//   never: never;
// }

/*
Example of a prompt template:
```
BOT INDENTITY
I am a friendly bot. I never make stuff up.
 
{0}
 
{1}
 
USER: How does it work?
 
BOT:
```
Here {0} is replace with the knowledge section and {1} is replaced
with the history section.
*/
export interface BotConfig {
  name: string;
  inactiveMessage: string;

  // maximum number of exchanges in a conversation
  maxExchangesCount: number; // e.g. 10

  promptSettings: {
    template: string;
    modules: Modules;
  };

  // This appears on the public chat in the bar at the top.
  chatHeader: string;
  // The first thing that appears in the chat window.
  introMessage: string;
  // The avatar of the person introducing the bot.
  introAvatarSrc?: string;
  // Common questions to serve to the user as suggestions.
  commonQuestions: string[];

  chatStyle?: {
    // The color of the chat header.
    headerColor?: string;
    // The color of the chat header text.
    headerTextColor?: string;
    // The color of the contact chat bubble background.
    contactBubbleBackgroundColor?: string;
    // The color of the contact chat text.
    contactBubbleTextColor?: string;
    // The color of the host chat bubble background.
    hostBubbleBackgroundColor?: string;
    // The color of the host chat text.
    hostBubbleTextColor?: string;
    // Action button color
    actionButtonColor?: string;
    // Whether or not to show the button to collapse the chat
    hideCollapseButton?: boolean;
    // Whether by default it is expanded
    defaultMinimized?: boolean;
    // height of the chat window
    height?: 'auto' | '100%';
    // knowledge quotes view off
    knowledgeViewOff?: boolean;
  }
}

export interface Campaign {
  // timestamp in milliseconds since the unix epoch
  created: number;
  // timestamp in milliseconds since the unix epoch
  launched?: number;
  // the internal name of the campaign
  name: string;
  // after being published, a way to deactivate the campaign
  state: 'active' | 'no-new-users' | 'inactive' | 'draft';
  defaultMode: ConversationMode;

  // the number of milliseconds since the unix epoch when the campaign got a message
  lastMessageTime?: number;

  maxConcurrentConversations: number; // e.g. 10

  // if null, then bots have equal probability
  botProbabilities?: number[];

  // the bots that are available in this campaign
  // null means the bot is not shown, it is the null hypothesis
  bots: Array<BotConfig | null>;
}


export type LogEvent = {
  type: 'account-created';
  userId: string;
  created: number;
} | {
  type: 'logged-in';
  userId: string;
  created: number;
} | {
  type: 'logged-out';
  userId: string;
  created: number;
}

export interface StatEvent {
  name: string;
  created: number;
  keys: { [key: string]: any };
  values: { [key: string]: number };
  data?: any;
}
export interface StatsTableSchema {
  name: string;
  bucketSizes: Array<'hour' | 'day' | 'month' | 'year'>;
  keys: string[];
  valueLabels: string[];
}
export interface StatsRow {
  key: {
    name: string;
    bucketSize: 'hour' | 'day' | 'month' | 'year';
    dateMillis: number; // modulo bucket size
    keys: { [key: string]: any };
  }
  values?: { [key: string]: number };
}

export const STATS_SCHEMAS: Array<StatsTableSchema> = [{
  name: 'conversations',
  bucketSizes: ['day', 'month'],
  keys: ['organizationId', 'campaignId'],
  valueLabels: ['count']
}, {
  name: 'exchanges',
  bucketSizes: ['day', 'month'],
  keys: ['organizationId', 'campaignId'],
  valueLabels: ['count', 'characters']
}];


export interface MultiPageKnowledgeUploadProcess {
  url: string,
  state: 'created' | 'started-url-scraping' | 'finished-url-scraping' | 'started-snippet-scraping' | 'finished-snippet-scraping' | 'url-scraping-error' | 'snippet-scraping-error',
  autoAddToKnowledge?: boolean,
}

export interface MultiPageKnowledgeUploadProgress {
  urlScrapingProgress?: { pagesVisitedCount: number, pagesToVisitCount: number },
  snippetScrapingProgress?: { pagesVisitedCount: number, pagesToVisitCount: number, snippetCount: number }
}

export type ScrapedLink = { url: string, characters: number, rejected: boolean };
export interface ScrapedContent {
  content: string
}

export type FAQ = { question: string, answer: string, url: string, addedToKnowledge?: boolean };

export type StartMultiPageBulkUploadInput = {
  // the domain to scrape pages and snippets from
  url: string,
  organizationId: string,
  knowledgeBaseId: string,
  startScrapingFAQs?: boolean,
  autoAddToKnowledge?: boolean,
};
export type StartMultiPageBulkUploadOutput = {
  ok: true,
} | {
  ok: false,
  message: string
}

export const TEXT_EMBEDDING_MODEL = 'text-embedding-ada-002';
export type TextEmbeddingModel = 'text-embedding-ada-002';