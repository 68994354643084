import { createContext, useContext } from "react";
import { Context } from "./context";
import { signInWithGoogle, signOutWithGoogle, initFirebaseAuth } from "./firebase-config";
import { FBAuth, DB } from "./firebase-config";

export const fb_auth: FBAuth = {
  initFirebaseAuth: initFirebaseAuth,
  signInWithGoogle: signInWithGoogle,
  signOutWithGoogle: signOutWithGoogle
}

// export const fb_io: FBIO = {
//   getItem: <T>(key: string, obj?: any) => { throw "Unimplemented"; },
//   setItem: <T>(key: string, value: T) => { throw "Unimplemented"; },
//   pushItem: <T>(key: string, value: T) => { throw "Unimplemented"; },
//   deleteItem: (key: string) => { throw "Unimplemented"; }
// }


export const UserContext = createContext(new Context(fb_auth));
export const useAppContext = () => useContext(UserContext);